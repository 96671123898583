import React from 'react'
import { GestureResponderEvent, Text, TouchableOpacity } from 'react-native'
import globalStyles from '../../styles/styles'
import styles from './styles'

interface Props {
    primary?: boolean
    secondary?: boolean
    lightGrey?: boolean
    grey?: boolean
    darkBlue?: boolean
    complementary?: boolean
    label: string
    style?: any
    styleText?: any
    onPress?: (event: GestureResponderEvent) => void
    disabled?: boolean
}

const Button: React.FC<Props> = ({
    secondary,
    lightGrey,
    grey,
    darkBlue,
    complementary,
    label,
    style,
    styleText,
    onPress,
    disabled,
}) => {
    return (
        <TouchableOpacity
            style={[
                styles.container,
                globalStyles.buttonMaxWidthWeb,
                secondary ? styles.secondady : {},
                lightGrey ? styles.lightGrey : {},
                grey ? styles.grey : {},
                darkBlue ? styles.darkBlue : {},
                complementary ? styles.complementary : {},
                style,
                disabled ? { opacity: 0.4 } : {},
            ]}
            onPress={e => {
                if (disabled !== true && onPress) {
                    onPress(e)
                }
            }}
        >
            <Text style={[styles.text, styleText]}>{label}</Text>
        </TouchableOpacity>
    )
}

export default Button
