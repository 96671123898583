import React from 'react'
import {
    GestureResponderEvent,
    Image,
    ImageSourcePropType,
    TouchableOpacity,
    View,
} from 'react-native'
import iconButtonStyles from './styles'

interface Props {
    styles?: any
    source: ImageSourcePropType
    onPress?: (event: GestureResponderEvent) => void
    showBadge?: boolean
}

const IconButton = ({ styles, source, onPress, showBadge }: Props) => {
    return (
        <TouchableOpacity style={styles} onPress={onPress}>
            <View style={iconButtonStyles.iconContainer}>
                <Image style={iconButtonStyles.icon} source={source} />
                {showBadge ? <View style={iconButtonStyles.iconBadge} /> : null}
            </View>
        </TouchableOpacity>
    )
}

export default IconButton
