import React, { useContext, useRef, useState } from 'react'
import {
    Image,
    Platform,
    ScrollView,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'

import Constants from 'expo-constants'
import getEnvVars from '../../../../environments'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import Modal from '../../../components/Modal'
import Spinner from '../../../components/Spinner'
import { AuthContext } from '../../../contexts/auth-context'
import User from '../../../models/user'
import { signIn } from '../../../services/api'
import { isEmpty } from '../../../shared/utils'
import styles from './styles'

const DEBUG = getEnvVars().channelName === 'dev'
const DEBUG_ACCOUNTS = {
    admin: {
        user: 'rafael@dalcon.cnt.br',
        pass: 'abcd1234',
        cpf: '882.212.290-91',
    },
    fiscal: {
        user: 'fiscal@dalcon.cnt.br',
        pass: 'abcd1234',
        cpf: '089.736.819-31',
    },
    commonDalcon: {
        user: 'pessoal@dalcon.cnt.br',
        pass: 'pT*=3k1kaq~tA_3..W]z12ergB',
        cpf: '016.622.740-41',
    },
    bergmann: {
        user: 'fabiocberg@gmail.com',
        pass: 'pT*=3k1kaq~tA_3..W]z12ergB',
        cpf: '027.878.479-86',
    },
}
// master pT*=3k1kaq~tA_3..W]z12ergB
// financeiro@linsecostaimoveis.com.br
// 090.990.229-13
const DEBUG_ACCOUNT_DEVICE = DEBUG_ACCOUNTS.bergmann
const DEBUG_ACCOUNT_WEB = DEBUG_ACCOUNTS.admin

const SignInScreen: React.FC = () => {
    // const [username, setUsername] = useState('rafael@dalcon.cnt.br')
    // const [password, setPassword] = useState('abcd1234')

    // const [username, setUsername] = useState('fiscal@dalcon.com')
    // const [password, setPassword] = useState('abcd1234')

    const { onSignInSuccess } = useContext(AuthContext)

    const isWeb = Platform.OS === 'web'

    const [username, setUsername] = useState(
        DEBUG
            ? isWeb
                ? DEBUG_ACCOUNT_WEB.user
                : DEBUG_ACCOUNT_DEVICE.user
            : '',
    )
    const [cpf, setCpf] = useState(
        DEBUG ? (isWeb ? DEBUG_ACCOUNT_WEB.cpf : DEBUG_ACCOUNT_DEVICE.cpf) : '',
    )
    const [password, setPassword] = useState(
        DEBUG
            ? isWeb
                ? DEBUG_ACCOUNT_WEB.pass
                : DEBUG_ACCOUNT_DEVICE.pass
            : '',
    )

    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const [accountsModalShow, setAccountsModalShow] = useState(false)
    const [accounts, setAccounts] = useState<User[]>([])

    // const navigation = useNavigation()

    const inputCPF = useRef()
    const inputPassword = useRef()

    const _authenticate = async (
        _email: string,
        _password: string,
        _cpf: string,
        _id: number,
        _selectAccount: boolean,
    ) => {
        const data: any = {
            email: _email,
            password: _password,
            cpf: _cpf,
        }
        if (_selectAccount) {
            data.selectAccount = _selectAccount
            data.id = _id
        }
        // fetch(getEnvVars().apiUrl, {
        //     method: 'OPTIONS',
        // }).then(async response => {
        //     console.log('OPTIONS res: ', response)
        // })
        try {
            setLoading(true)
            const result = await signIn(data)
            if (result.accounts) {
                setAccounts(result.accounts)
                setAccountsModalShow(true)
            } else if (result.token && result.user) {
                // await Settings.setToken(result.token)
                // await Settings.setCompanyMasterId(result.companyMasterId)
                // await Settings.setUser(new User(result.user))
                // navigation.navigate('Main')
                onSignInSuccess(result)
            } else {
                setErrorMessage(
                    'Erro ao fazer login. Verifique sua conexão com a internet e tente novamente.',
                )
            }
        } catch (e) {
            console.log('_signin exception: ', e)
            setErrorMessage(
                'Erro inesperado. Verifique sua conexão com a internet e tente novamente.',
            )
        } finally {
            setLoading(false)
        }
    }

    const _onAccountSelected = (account: User) => {
        setErrorMessage('')
        _authenticate('', '', '', account.id, true)
    }

    const _signIn = async () => {
        if (isEmpty(username)) {
            setErrorMessage('Por favor, informa seu e-mail.')
        } else if (isEmpty(password)) {
            setErrorMessage('Por favor, informa sua senha.')
        } else {
            setErrorMessage('')
            await _authenticate(username, password, cpf, 0, false)
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.loginBox}>
                <View style={styles.logoContainer}>
                    <Image
                        style={styles.logo}
                        source={require('../../../../assets/images/logo-dalcon.png')}
                    />
                </View>
                <View style={styles.line} />
                {errorMessage.length > 0 ? (
                    <Text style={styles.errorMessage}>{errorMessage}</Text>
                ) : null}
                <Input
                    nextRef={inputCPF}
                    keyboardType="email-address"
                    returnKeyType="next"
                    autoCapitalize="none"
                    style={{ marginBottom: 12 }}
                    icon={require('../../../../assets/images/ic_user.png')}
                    label="E-mail"
                    value={username}
                    onChangeText={text => setUsername(text)}
                />
                <Input
                    ref={inputCPF}
                    nextRef={inputPassword}
                    keyboardType="numeric"
                    maskType="cpf"
                    returnKeyType="next"
                    autoCapitalize="none"
                    style={{ marginBottom: 12 }}
                    icon={require('../../../../assets/images/ic_identity.png')}
                    label="CPF"
                    value={cpf}
                    onChangeText={text => setCpf(text)}
                />
                <Input
                    ref={inputPassword}
                    secureTextEntry={true}
                    returnKeyType="go"
                    autoCapitalize="none"
                    style={{ marginBottom: 36 }}
                    icon={require('../../../../assets/images/ic_fingerprint.png')}
                    label="Senha"
                    onSubmitEditing={_signIn}
                    value={password}
                    onChangeText={text => setPassword(text)}
                />
                <View style={isWeb ? styles.buttonContainer : null}>
                    <Button
                        style={isWeb ? styles.button : null}
                        primary
                        label="Entrar"
                        onPress={_signIn}
                    />
                </View>
                <Text style={styles.version}>
                    Versão:{' '}
                    {Constants.manifest?.version ||
                        Constants.nativeAppVersion ||
                        '-'}
                </Text>
            </View>
            {loading ? (
                <Spinner
                    visible={loading}
                    overlayColor={'rgba(0, 0, 0, 0.5)'}
                />
            ) : null}
            <Modal
                isVisible={accountsModalShow}
                title="Selecione uma conta"
                onDismiss={() => setAccountsModalShow(false)}
                showCloseButton
            >
                <View style={styles.modalContainer}>
                    <>
                        <ScrollView>
                            <View>
                                {accounts.map((account, index) => (
                                    <TouchableOpacity
                                        key={`account_item_${index}`}
                                        style={styles.modalItemButtom}
                                        onPress={() =>
                                            _onAccountSelected(account)
                                        }
                                    >
                                        <Text style={styles.modalItemLabel}>
                                            {account.name}
                                        </Text>
                                        <Text style={styles.modalItemLabel}>
                                            E-mail: {account.email}
                                        </Text>
                                        <Text style={styles.modalItemLabel}>
                                            CPF: {account.cpf}
                                        </Text>
                                        <Text style={styles.modalItemLabel}>
                                            Empresa: {account.company?.name}
                                        </Text>
                                        <Text style={styles.modalItemLabel}>
                                            CNPJ: {account.company?.cnpj}
                                        </Text>
                                    </TouchableOpacity>
                                ))}
                            </View>
                        </ScrollView>
                        <Button
                            primary
                            style={{ marginTop: 12, marginBottom: 10 }}
                            label="Cancelar"
                            onPress={() => setAccountsModalShow(false)}
                        />
                    </>
                </View>
            </Modal>
        </View>
    )
}

export default SignInScreen
