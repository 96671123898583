import React, { useEffect, useState } from 'react'
import { ScrollView, Text, TouchableOpacity, View } from 'react-native'

import Button from '../Button'
import CheckBox from '../CheckBox'
import Input from '../Input'
import Modal from '../Modal'
import styles from './styles'

export class DropdownItem {
    id: number
    idString: string
    label: string
    checked: boolean
    extra1?: any

    constructor(id: number, label: string, checked = false, idString = '') {
        this.id = id
        this.label = label
        this.checked = checked
        this.idString = idString
    }
}

interface Props {
    label?: string
    modalTitle: string
    items: DropdownItem[]
    placeholder?: string
    onItemSelected?: (item: DropdownItem) => void
    onMultiSelected?: (items: DropdownItem[]) => void
    canCreate?: boolean
    selectedLabel?: string
    selectedItems?: DropdownItem[]
    style?: any
    multi?: boolean
}

const Dropdown: React.FC<Props> = ({
    label,
    modalTitle,
    items,
    placeholder,
    onItemSelected,
    onMultiSelected,
    selectedLabel,
    selectedItems,
    canCreate,
    style,
    multi,
}) => {
    const [value, setValue] = useState('')
    const [search, setSearch] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>([])

    useEffect(() => {
        if (dropdownItems.length === 0 && items.length > 0) {
            const itemsCopy = [...items]
            let newItems = itemsCopy
            if (selectedItems) {
                newItems = []
                for (const i of itemsCopy) {
                    i.checked =
                        selectedItems.find(si => si.id === i.id) !== undefined
                    newItems.push(i)
                }
            }
            setDropdownItems(newItems)
        }
    }, [dropdownItems, items, selectedItems])

    useEffect(() => {
        if (selectedLabel !== undefined && selectedLabel !== null) {
            setValue(selectedLabel)
        }
    }, [selectedLabel])

    const _onItemSelected = (item: DropdownItem) => {
        if (multi) {
            item.checked = !item.checked
            const newDropdownItems: DropdownItem[] = []
            for (let i of dropdownItems) {
                if (i.id === item.id) {
                    newDropdownItems.push(item)
                } else {
                    newDropdownItems.push(i)
                }
            }
            setDropdownItems(newDropdownItems)
        } else {
            setValue(item.label)
            if (onItemSelected) {
                onItemSelected(item)
            }
            setShowModal(false)
        }
    }

    const _onCreate = () => {
        _onItemSelected(new DropdownItem(0, search.trim()))
    }

    const _onMultiOK = () => {
        if (onMultiSelected) {
            const checked = dropdownItems.filter(i => i.checked === true)
            onMultiSelected(checked)
            setTimeout(() => {
                setShowModal(false)
            }, 600)
        }
    }

    const itemsToShow =
        search.trim().length > 0
            ? dropdownItems.filter(
                  i =>
                      i.label
                          .toLocaleLowerCase()
                          .indexOf(search.toLocaleLowerCase()) >= 0,
              )
            : dropdownItems

    return (
        <>
            <View style={[styles.container, style]}>
                <Input
                    style={[{ marginBottom: 12 }, styles.input]}
                    label={label}
                    value={value}
                    placeholder={placeholder}
                />
                <TouchableOpacity
                    style={styles.button}
                    onPress={() => setShowModal(true)}
                />
            </View>
            <Modal
                isVisible={showModal}
                title={modalTitle}
                onDismiss={() => setShowModal(false)}
                showCloseButton
            >
                <View style={styles.modalContainer}>
                    <>
                        <Input
                            style={[{ marginBottom: 12 }, styles.input]}
                            label="Busca"
                            value={search}
                            onChangeText={setSearch}
                            placeholder={placeholder}
                        />
                        <ScrollView>
                            <View>
                                {itemsToShow.map((item, index) => (
                                    <TouchableOpacity
                                        key={`dropdown_item_${index}`}
                                        style={[
                                            styles.modalItemButtom,
                                            multi
                                                ? styles.modalItemButtomCheckBox
                                                : null,
                                        ]}
                                        onPress={() => _onItemSelected(item)}
                                    >
                                        {multi && (
                                            <CheckBox checked={item.checked} />
                                        )}
                                        <Text
                                            style={[
                                                styles.modalItemLabel,
                                                item.id === 0
                                                    ? styles.modalItemLabelNone
                                                    : null,
                                            ]}
                                        >
                                            {item.label}
                                        </Text>
                                    </TouchableOpacity>
                                ))}
                            </View>
                        </ScrollView>
                        {canCreate &&
                        search.trim().length > 0 &&
                        itemsToShow.length === 0 ? (
                            <Button
                                primary
                                style={{ marginTop: 12, marginBottom: 10 }}
                                label="Criar"
                                onPress={_onCreate}
                            />
                        ) : null}
                        {multi ? (
                            <Button
                                primary
                                style={{ marginTop: 12, marginBottom: 10 }}
                                label="OK"
                                onPress={_onMultiOK}
                            />
                        ) : null}
                    </>
                </View>
            </Modal>
        </>
    )
}

export default Dropdown
