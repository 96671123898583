import * as DocumentPicker from 'expo-document-picker'
import * as FileSystem from 'expo-file-system'
import React, { useEffect, useState } from 'react'
import { Image, Platform, Text, TouchableOpacity, View } from 'react-native'
// @ts-ignore
import iconExpandLess from '../../../../../../../../assets/images/ic_expand_less.png'
// @ts-ignore
import iconExpandMore from '../../../../../../../../assets/images/ic_expand_more.png'
import AlertInline from '../../../../../../../components/AlertInline'
import Button from '../../../../../../../components/Button'
import ContentHeader from '../../../../../../../components/ContentHeader'
import Dropdown, {
    DropdownItem,
} from '../../../../../../../components/Dropdown'
import Input from '../../../../../../../components/Input'
import LabelText from '../../../../../../../components/LabelText'
import { useTimer } from '../../../../../../../contexts/timer-context'
import Activity from '../../../../../../../models/activity'
import Document from '../../../../../../../models/document'
import Pendency from '../../../../../../../models/pendency'
import PendencyComment from '../../../../../../../models/pendency-comment'
import Phone from '../../../../../../../models/phone'
import User from '../../../../../../../models/user'
import {
    getActivities,
    getPendencyComments,
    getRoles,
    getUsersByRoleId,
    saveActivity,
    savePendency,
    savePendencyComment,
    uploadDocument,
} from '../../../../../../../services/api'
import Settings from '../../../../../../../services/settings'
import { datetimeToString } from '../../../../../../../shared/date'
import {
    getMimeType,
    getSizeHumanReadable,
} from '../../../../../../../shared/file-utils'
import globalStyles from '../../../../../../../styles/styles'
// local imports
import styles from './styles'

interface Props {
    request: Pendency
    onLoading: (loading: boolean) => void
    onError: (message: string) => void
    onCancel: () => void
    setRequestEditing: (request?: Pendency) => void
}

const RequestFormEdit: React.FC<Props> = ({
    request,
    onLoading,
    onError,
    onCancel,
    setRequestEditing,
}) => {
    const [editingRequest, setEditingRequest] = useState<Pendency | undefined>(
        undefined,
    )
    const [description, setDescription] = useState('')
    const [documents, setDocuments] = useState<Document[]>([])
    const [roleId, setRoleId] = useState(0)
    const [rolesDropdownItems, setRolesDropdownItems] = useState<
        DropdownItem[]
    >([])
    const [isCompanyMaster, setIsCompanyMaster] = useState(false)
    const [companyId, setCompanyId] = useState(0)
    const [newComment, setNewComment] = useState('')
    const [alertInlineMessage, setAlertInlineMessage] = useState('')
    const [alertInlineShow, setAlertInlineShow] = useState(false)
    const [alertInlineSuccess, setAlertInlineSuccess] = useState(false)
    const [alertInlineError, setAlertInlineError] = useState(false)

    const [showMoreData, setShowMoreData] = useState(false)

    const [comments, setComments] = useState<PendencyComment[]>([])

    const [activities, setActivities] = useState<Activity[]>([])

    const [users, setUsers] = useState<User[]>([])
    const [userId, setUserId] = useState(0)

    const { startTimer, stopTimer, clearTimer } = useTimer()

    // const [currentComment, setCurrentComment] = useState<
    //     PendencyComment | undefined
    // >(undefined)

    const _updateRequest = () => {
        if (editingRequest) {
            setDescription(editingRequest.description)
            const _roleId = editingRequest.role?.id || 0
            setRoleId(_roleId)
            if (_roleId > 0) {
                _onRoleSelected(_roleId)
            }
            // setCurrentComment(PendencyComment.createEmpty())
            getPendencyComments(editingRequest.id).then<PendencyComment[]>(
                pendencyComments => {
                    setComments(pendencyComments)
                    return pendencyComments
                },
            )
        }
    }

    const _onRoleSelected = async (_roleId: number) => {
        setRoleId(_roleId)
        onLoading(true)
        try {
            const companyMasterId = await Settings.getCompanyMasterId()
            if (companyMasterId) {
                const _users = await getUsersByRoleId(companyMasterId, _roleId)
                setUsers(_users)
                if (request.userIdExecutor && request.userIdExecutor > 0) {
                    setUserId(request.userIdExecutor)
                }
            }
        } catch (e) {
            console.log(e)
        } finally {
            onLoading(false)
        }
    }

    useEffect(() => {
        if (editingRequest) {
            _updateRequest()
            getActivities(request.id).then(data => {
                setActivities(data)
            })
            saveActivity(request.id, {
                activity: {
                    pendencyId: request.id,
                    type: 'pendency_view',
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editingRequest])

    useEffect(() => {
        ;(async () => {
            try {
                // Verifica se é a empresa master
                const companyMasterId = await Settings.getCompanyMasterId()
                const user = await Settings.getUser()
                if (user && companyMasterId) {
                    setCompanyId(user.companyId)
                    setIsCompanyMaster(user.companyId === companyMasterId)
                }
                // Obtem os perfis
                const response = await getRoles(companyMasterId || 0)
                if (response) {
                    const items: DropdownItem[] = response.map(
                        r => new DropdownItem(r.id, r.name),
                    )
                    const none = new DropdownItem(0, 'Nenhum')
                    if (items.length > 0) {
                        items.splice(0, 0, none)
                    } else {
                        items.push(none)
                    }
                    setRolesDropdownItems(items)
                }
                // Seta o request (pendency) object
                setEditingRequest(request)
                _updateRequest()
            } catch (e: any) {
                console.log('RequestFormFragment init exception: ', e)
                onError(e.message)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Saves / Updates a pendency
     * @param _pendency Pendency to save / update
     */
    const _savePendency = async (_pendency: Pendency) => {
        onLoading(true)
        try {
            const p = { ..._pendency }
            p.userIdExecutor = userId
            const data = {
                pendency: p,
            }
            const result = await savePendency(data)
            setRequestEditing(result.pendency)
            return result
        } catch (e) {
            console.log('_startPendency exception: ', e)
        } finally {
            onLoading(false)
        }
        return undefined
    }

    const _addDocument = async () => {
        try {
            const result = await DocumentPicker.getDocumentAsync()
            // ('DocumentPicker.getDocumentAsync result: ', result)
            if (result.type === 'success') {
                // result.name
                // result.size
                // result.uri
                if (Platform.OS !== 'web') {
                    const base64 = await FileSystem.readAsStringAsync(
                        result.uri,
                        {
                            encoding: FileSystem.EncodingType.Base64,
                        },
                    )
                    const doc = new Document({
                        name: result.name,
                        size: result.size,
                        uri: result.uri,
                        base64: base64,
                    })
                    setDocuments(prevState => {
                        return [...prevState, doc]
                    })
                } else {
                    const doc = new Document({
                        name: result.name,
                        size: result.size,
                        uri: result.uri,
                        base64: result.uri,
                    })
                    setDocuments(prevState => {
                        return [...prevState, doc]
                    })
                }

                // test
                // const mimetype = getMimeType(doc.name)
                // const data = {
                //     document: {
                //         base64: `data:${mimetype};base64,${doc.base64}` || '',
                //         filename: doc.name,
                //         mimetype,
                //     },
                //     companyId,
                // }
                // await uploadDocument(data, 1, 0)
            }
        } catch (e) {
            console.log('DocumentPicker.getDocumentAsync exception: ', e)
        }
    }

    const _removeDocument = async (doc: Document) => {
        let docs = [...documents]
        if (doc.id && doc.id > 0) {
            docs = docs.filter(d => d.id !== doc.id)
        } else {
            docs = docs.filter(d => d.uri !== doc.uri)
        }
        setDocuments(docs)
    }

    /**
     * Save files
     * @param index File index
     * @param pendencyId Pendency ID [acredito que esse ID nunca será utilizado, passar zero por enquanto]
     * @param pendencyCommentId Pendency Comment ID
     */
    const _saveFiles = async (
        index: number,
        pendencyId: number,
        pendencyCommentId: number,
    ) => {
        if (index >= documents.length) {
            return
        }
        const doc = documents[index]
        if (doc.uri) {
            try {
                const mimetype = getMimeType(doc.name)
                const data = {
                    document: {
                        base64: `data:${mimetype};base64,${doc.base64}` || '',
                        filename: doc.name,
                        mimetype,
                        size: doc.size,
                    },
                    companyId,
                }
                await uploadDocument(data, pendencyId, pendencyCommentId)
            } catch (e) {
                console.log('_saveFiles exception: ', e)
            }
        }

        await _saveFiles(index + 1, pendencyId, pendencyCommentId)
    }

    /**
     * Starts or completes a pendency.
     * @param _pendency Pendency to start or complete.
     */
    const _startCompletePendency = async (_pendency: Pendency) => {
        // dateStarted
        try {
            const __pendency = { ..._pendency } as Pendency
            if (!__pendency.dateStarted) {
                __pendency.dateStarted = new Date()
                await startTimer(__pendency.id)
            } else {
                __pendency.dateCompleted = new Date()
                await stopTimer(true)
                clearTimer()
            }
            const user = await Settings.getUser()
            __pendency.userIdExecutor = user?.id || 0
            await _savePendency(__pendency)
        } catch (e) {
            console.log('_startPendency exception: ', e)
        }
    }

    const _saveComment = async () => {
        onLoading(true)

        try {
            if (!isCompanyMaster || request.dateStarted) {
                let pendencyComment = PendencyComment.createEmpty()
                pendencyComment.description = newComment
                const data = {
                    pendencyComment,
                }
                pendencyComment = (await savePendencyComment(request.id, data))
                    .pendencyComment
                if (pendencyComment && pendencyComment.id) {
                    await _saveFiles(0, request.id, pendencyComment.id)
                }
                setNewComment('')
                setDocuments([])
                _updateRequest()
            }
        } catch (e: any) {
            console.log('_saveComment exception: ', e)
            onError(e.message)
        } finally {
            onLoading(false)
        }
    }

    const _changeRole = async (id: number) => {
        setUsers([])
        _onRoleSelected(id)
        setRoleId(id)
        const _pendency = { ...request } as Pendency
        _pendency.roleId = id
        const result = await _savePendency(_pendency)
        const success = result && result.success
        setAlertInlineMessage(
            success ? 'Setor salvo com sucesso!' : 'Erro salvando setor.',
        )
        setAlertInlineSuccess(success)
        setAlertInlineError(!success)
        setAlertInlineShow(true)
    }

    // const _save = async () => {
    //     onLoading(true)

    //     try {
    //         if (!isCompanyMaster || request.dateStarted) {
    //             const _pendency = { ...request } as Pendency
    //             _pendency.description = description
    //             // salvar
    //             const result = await _savePendency(_pendency)
    //             if (result.success) {
    //                 await _saveFiles(0, result.pendency.id, 0)
    //             } else {
    //                 onError('Ocorreu um erro salvando.')
    //             }
    //         }
    //     } catch (e) {
    //         onError(e.message)
    //     }
    // }

    const dropdownSelectedLabel =
        roleId === 0
            ? ''
            : rolesDropdownItems.find(i => i.id === roleId)?.label || ''

    const phones: Phone[] = request.company?.phones || []

    // const roleIdChanged = roleId !== request.role?.id

    const usersDropdownItems = users.map(i => new DropdownItem(i.id, i.name))

    const dropdownSelectedUserLabel =
        userId === 0
            ? ''
            : usersDropdownItems.find(i => i.id === userId)?.label || ''

    return (
        <>
            <LabelText
                label="Empresa"
                text={`${request.company?.name || ''} - ${
                    request.company?.cnpj
                }`}
                styles={[
                    globalStyles.marginBottomNormal,
                    globalStyles.marginTopLarge,
                ]}
            />
            {/* DADOS ADICIONAIS DA EMPRESA */}
            {showMoreData ? (
                <View>
                    <LabelText
                        label="Responsável"
                        text={`${request.company?.admin.name || ''}`}
                        styles={globalStyles.marginBottomNormal}
                    />
                    <LabelText
                        label="E-mail"
                        text={`${request.company?.admin.email || ''}`}
                        styles={globalStyles.marginBottomNormal}
                    />
                    <LabelText label="Telefone(s)" />
                    {phones.map(phone => (
                        <Text
                            key={`phone_${phone.id}`}
                            style={[globalStyles.formTextValue]}
                        >
                            {phone.number}
                        </Text>
                    ))}
                </View>
            ) : null}
            <View style={styles.moreData}>
                <View style={[styles.line, styles.moreDataLine]} />
                <TouchableOpacity
                    style={styles.moreDataButton}
                    onPress={() => setShowMoreData(!showMoreData)}
                >
                    <Text style={styles.moreDataText}>
                        VER {showMoreData ? 'MENOS' : 'MAIS'} DADOS{' '}
                    </Text>
                    <Image
                        style={styles.moreDataIcon}
                        source={showMoreData ? iconExpandLess : iconExpandMore}
                    />
                </TouchableOpacity>

                <View style={[styles.line, styles.moreDataLine]} />
            </View>

            <View style={globalStyles.marginBottomNormal} />
            <LabelText
                label="Descrição"
                text={description}
                styles={globalStyles.marginBottomNormal}
            />
            <Dropdown
                style={globalStyles.marginBottomLargest}
                label={`Escolha o Setor ${isCompanyMaster ? '' : '(opcional)'}`}
                selectedLabel={dropdownSelectedLabel}
                modalTitle="Escolha a área de atuação"
                items={rolesDropdownItems}
                placeholder="Escolha área"
                onItemSelected={item => _changeRole(item.id)}
            />
            {users.length > 0 ? (
                <Dropdown
                    style={globalStyles.marginBottomLarge}
                    label={`Escolha a pessoa ${
                        isCompanyMaster ? '' : '(opcional)'
                    }`}
                    selectedLabel={dropdownSelectedUserLabel}
                    modalTitle="Escolha a pessoa"
                    items={usersDropdownItems}
                    placeholder="Escolha a pessoa"
                    onItemSelected={item => setUserId(item.id)}
                />
            ) : null}
            <AlertInline
                message={alertInlineMessage}
                show={alertInlineShow}
                success={alertInlineSuccess}
                error={alertInlineError}
                style={globalStyles.marginBottomLarge}
                onTimeout={() => setAlertInlineShow(false)}
            />

            {/* ADICIONAR COMENTÁRIO */}
            {/* Se não iniciado, não pode adicionar comentários */}

            <ContentHeader
                title="Comentários"
                style={globalStyles.marginBottomLarge}
            />
            {comments.length === 0 ? (
                <Text style={styles.noComments}>Não há comentários</Text>
            ) : (
                <View>
                    {comments.map((comment, index) => (
                        <View key={`comment_${index}`} style={styles.comment}>
                            <View>
                                <Text>
                                    <Text style={styles.commentUserName}>
                                        {comment.user?.name || '-'}
                                    </Text>{' '}
                                    - {datetimeToString(comment.dateCreate)}
                                </Text>
                                <Text style={styles.commentDescription}>
                                    {comment.description}
                                </Text>
                            </View>
                            {comment.documents &&
                            comment.documents.length > 0 ? (
                                <View style={styles.commentDocuments}>
                                    <Text style={styles.commentDocumentsTitle}>
                                        Documento(s):
                                    </Text>
                                    {comment.documents.map((doc, docIndex) => (
                                        <View
                                            key={`comment_doc_${docIndex}`}
                                            style={styles.commentDocument}
                                        >
                                            <Text>Nome: {doc.filename}</Text>
                                            <Text>
                                                Tamanho:{' '}
                                                {getSizeHumanReadable(doc.size)}
                                            </Text>
                                        </View>
                                    ))}
                                </View>
                            ) : null}
                        </View>
                    ))}
                </View>
            )}
            {isCompanyMaster && !request.dateStarted ? (
                <Text style={styles.addCommentWarning}>
                    Para adicionar comentários e documentos, você precisa{' '}
                    <Text style={styles.addCommentWarningAccent}>
                        Iniciar Atendimento
                    </Text>{' '}
                    a solicitação clicando no botão abaixo.
                </Text>
            ) : null}
            {!isCompanyMaster || request.dateStarted ? (
                <>
                    {/* NOVO COMENTÁRIO */}
                    <Input
                        returnKeyType="next"
                        autoCapitalize="sentences"
                        multiline
                        textAlignVertical="top"
                        inputStyle={styles.inputTextArea}
                        style={globalStyles.marginBottomLarge}
                        label="Adicionar Comentário"
                        value={newComment}
                        placeholder="Escreva um comentário aqui..."
                        onChangeText={text => setNewComment(text)}
                    />

                    {/* DOCUMENTOS ADICIONADOS NO NOVO COMENTÁRIO */}
                    {documents.map((doc, index) => (
                        <View
                            key={`doc_item_${index}`}
                            style={[
                                styles.listItem,
                                globalStyles.marginBottomNormal,
                            ]}
                        >
                            <Text style={{ flex: 0.9 }}>{doc.name}</Text>
                            <TouchableOpacity
                                onPress={() => _removeDocument(doc)}
                            >
                                <Image
                                    style={styles.iconRemove}
                                    source={require('../../../../../../../../assets/images/ic_remove.png')}
                                />
                            </TouchableOpacity>
                        </View>
                    ))}
                    {/* BOTAO DE ADICONAR DOCUMENTO */}
                    <Button
                        style={globalStyles.marginBottomLarge}
                        darkBlue
                        label="Adicionar Documento"
                        onPress={_addDocument}
                    />
                    {!isCompanyMaster || request.dateStarted ? (
                        <Button
                            style={globalStyles.marginBottomLarge}
                            primary
                            label={'Salvar Comentário'}
                            onPress={() => _saveComment()}
                        />
                    ) : null}
                </>
            ) : null}
            <View style={[styles.line, globalStyles.marginBottomLarge]} />

            {isCompanyMaster ? (
                <Button
                    style={globalStyles.marginBottomLarge}
                    primary
                    label={
                        request.dateStarted
                            ? 'Finalizar Atendimento'
                            : 'Iniciar Atendimento'
                    }
                    onPress={() => _startCompletePendency(request)}
                />
            ) : null}
            <Button
                style={globalStyles.marginBottomLargest}
                secondary
                label="Voltar"
                onPress={onCancel}
            />
            {/* ATIVIDADES */}
            <ContentHeader
                title="Atividades"
                style={globalStyles.marginBottomLarge}
            />
            {activities.map((activity, idx) => (
                <Text key={`activity_${idx}`} style={styles.activity}>
                    {activity.user?.name} visualizou em{' '}
                    {datetimeToString(activity.dateWhen)}
                </Text>
            ))}
        </>
    )
}

export default RequestFormEdit
