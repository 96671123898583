import { StyleSheet } from 'react-native'
import colors from '../../../../../../styles/colors'
import metrics from '../../../../../../styles/metrics'

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: metrics.padding,
    },
    name: {
        color: colors.secondary,
        fontSize: 18,
        fontWeight: 'bold',
    },
    value: {
        color: '#333',
        marginBottom: 10,
    },
    label: {
        marginTop: 20,
        color: '#999',
    },
})

export default styles
