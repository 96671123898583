import React from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import CompanyType from '../../../../models/company-type'
import colors from '../../../../styles/colors'
import { iconSizeNormal } from '../../../../styles/metrics'

export interface CompanyTypeFormPropsItemProps {
    companyType: CompanyType
    onSelected: (companyType: CompanyType) => void
    onRemove: (companyType: CompanyType) => void
}

const CompanyTypeItem = ({
    companyType,
    onSelected,
    onRemove,
}: CompanyTypeFormPropsItemProps) => {
    return (
        <TouchableOpacity
            style={styles.container}
            onPress={() => onSelected(companyType)}
        >
            <View>
                <Text style={styles.name}>{companyType.name}</Text>
            </View>
            <TouchableOpacity onPress={() => onRemove(companyType)}>
                <Image
                    style={styles.iconRemove}
                    source={require('../../../../../assets/images/ic_remove.png')}
                />
            </TouchableOpacity>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingTop: 16,
        paddingBottom: 16,
        borderBottomColor: '#bbb',
        borderBottomWidth: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    name: {
        color: colors.secondary,
        fontSize: 18,
        fontWeight: 'bold',
    },
    recurrence: {
        color: colors.secondary,
        fontSize: 14,
        fontWeight: 'normal',
        marginTop: 8,
    },
    iconRemove: {
        width: iconSizeNormal,
        height: iconSizeNormal,
        tintColor: '#f55',
    },
})

export default CompanyTypeItem
