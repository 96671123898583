import { StyleSheet, Text, View } from 'react-native'
import { datetimeToString, duration } from '../../../../shared/date'

import React from 'react'
import User from '../../../../models/user'
import metrics from '../../../../styles/metrics'

type ActivityItemProps = {
    user: User
    allItems?: boolean
}

export default function ActivityItem({ user, allItems }: ActivityItemProps) {
    const pendencies = allItems
        ? user.pendencies
        : user.pendencies && user.pendencies.length
        ? [user.pendencies[0]]
        : []
    return (
        <View style={styles.container}>
            <Text style={styles.name}>{user.name}</Text>
            <Text style={styles.subtitle}>Atividades</Text>
            {pendencies && pendencies.length ? (
                pendencies.map(pendency => (
                    <View key={`pendency-activity-${pendency.id}`}>
                        {pendency.description &&
                        pendency.description.trim().length > 0 ? (
                            <Text style={styles.description}>
                                {pendency.description}
                            </Text>
                        ) : (
                            <Text style={styles.description}>
                                Sem descrição
                            </Text>
                        )}
                        <Text>
                            Iniciado: {datetimeToString(pendency.dateStarted)}
                        </Text>
                        <Text>
                            Finalizado:{' '}
                            {datetimeToString(pendency.dateCompleted)}
                        </Text>
                        <Text>
                            Duração:{' '}
                            {duration(
                                pendency.dateStarted,
                                pendency.dateCompleted,
                            )}
                        </Text>
                    </View>
                ))
            ) : (
                <Text style={styles.noActivity}>
                    Nenhuma atividade registrada.
                </Text>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        marginBottom: metrics.margin,
        borderBottomColor: '#333',
        borderBottomWidth: 1,
        paddingBottom: metrics.padding,
    },
    name: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8,
        color: '#000',
    },
    subtitle: {
        fontSize: 18,
        color: '#000',
        marginBottom: 8,
    },
    description: {
        color: '#000',
        fontSize: 14,
    },
    noActivity: {
        color: '#888',
        fontSize: 14,
    },
})
