import React, { useEffect, useState } from 'react'
import { ScrollView } from 'react-native'
import Spinner from '../../../../../components/Spinner'
import User from '../../../../../models/user'
import { getUser } from '../../../../../services/api'
import Settings from '../../../../../services/settings'
import ProfileFormDetail from './ProfileFormDetail'
import ProfileFormEditing from './ProfileFormEditing'
import styles from './styles'

interface Props {}

const ProfileForm: React.FC<Props> = () => {
    const [editing, setEditing] = useState(false)

    const [user, setUser] = useState<User | undefined>(undefined)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        ;(async () => {
            setLoading(true)
            const u = await Settings.getUser()
            if (u) {
                try {
                    const response = await getUser(u.id)
                    if (response) {
                        const _user = new User(response)
                        setUser(_user)
                    }
                } finally {
                    setLoading(false)
                }
            }
        })()
    }, [])

    const _setLoading = (val: boolean) => {
        setLoading(val)
    }

    const _toggleEdit = () => {
        if (user) {
            setEditing(!editing)
        }
    }

    const _onUserUpdated = async (u: User) => {
        setUser(undefined)
        setUser(u)
    }

    const _companyContent = () => {
        if (editing) {
            return (
                <ProfileFormEditing
                    user={user}
                    onUserUpdated={_onUserUpdated}
                    onCancel={() => setEditing(false)}
                    setLoading={_setLoading}
                    setEditing={val => setEditing(val)}
                />
            )
        } else {
            return <ProfileFormDetail user={user} toggleEdit={_toggleEdit} />
        }
    }

    if (!user) {
        return null
    }

    return (
        <>
            <ScrollView style={styles.container}>
                <>{_companyContent()}</>
            </ScrollView>

            <Spinner visible={loading} overlayColor={'rgba(0, 0, 0, 0.5)'} />
        </>
    )
}

export default ProfileForm
