import React, { useEffect, useState } from 'react'
import { Text } from 'react-native'
import Button from '../../../../../../components/Button'
import Company from '../../../../../../models/company'
import User from '../../../../../../models/user'
import { getCompany } from '../../../../../../services/api'
import Settings from '../../../../../../services/settings'
import { SystemRole } from '../../../../../../shared/consts'
import styles from './styles'

interface Props {
    user?: User
    onBack: () => void
    toggleEdit: () => void
}

const UserFormDetail: React.FC<Props> = ({ user, onBack, toggleEdit }) => {
    const [name] = useState(user?.name || '')
    const [email] = useState(user?.email || '')
    const [cpf] = useState(user?.cpf || '')
    const [company, setCompany] = useState<Company | null>()
    const [userSystem, setUserSystem] = useState<User | null>(null)

    useEffect(() => {
        if (user) {
            getCompany(user.companyId).then(setCompany)
        }
    }, [user])

    useEffect(() => {
        if (!userSystem) {
            ;(async () => {
                const u = await Settings.getUser()
                setUserSystem(u)
            })()
        }
    }, [userSystem])

    return (
        <>
            <Text style={styles.label}>Nome</Text>
            <Text style={styles.name}>{name}</Text>
            <Text style={styles.label}>Empresa</Text>
            <Text style={styles.value}>{company?.name}</Text>
            <Text style={styles.label}>E-mail</Text>
            <Text style={styles.value}>{email || '-'}</Text>
            <Text style={styles.label}>CPF</Text>
            <Text style={styles.value}>{cpf || '-'}</Text>
            <Text style={styles.label}>Área</Text>
            <Text style={styles.value}>
                {user?.role?.name || user?.roleText || '-'}
            </Text>
            {userSystem &&
            !SystemRole.isDalconEmployee(userSystem.systemRole || 0) &&
            !SystemRole.isCustomerEmployee(userSystem.systemRole || 0) ? (
                <Button
                    primary
                    style={{ marginTop: 12, marginBottom: 10 }}
                    label="Editar"
                    onPress={toggleEdit}
                />
            ) : null}
            <Button secondary label="Voltar" onPress={onBack} />
        </>
    )
}

export default UserFormDetail
