import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import AppLoading from 'expo-app-loading'
import { StatusBar } from 'expo-status-bar'
import React, { useContext } from 'react'
import { AuthContext } from '../contexts/auth-context'
import SignInScreen from '../screens/Auth/SignInScreen'
import MainScreen from '../screens/Home/MainScreen'
import colors from '../styles/colors'

const Stack = createStackNavigator()

const Navigation = () => {
    const { token, checkingToken } = useContext(AuthContext)

    if (checkingToken) {
        return <AppLoading />
    }

    const isAuthenticated = token !== null

    return (
        <NavigationContainer>
            <StatusBar style="light" backgroundColor={colors.primary} />
            <Stack.Navigator headerMode="none">
                {!checkingToken && !isAuthenticated ? (
                    <Stack.Screen
                        name="SignIn"
                        options={{ title: 'Entrar' }}
                        component={SignInScreen}
                    />
                ) : (
                    <Stack.Screen
                        name="Main"
                        options={{ title: 'Dalcon' }}
                        component={MainScreen}
                    />
                )}
            </Stack.Navigator>
        </NavigationContainer>
    )
}

export default Navigation
