import React from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import ImpostoRenda from '../../../../models/imposto-renda'
import User from '../../../../models/user'
import { SystemRole } from '../../../../shared/consts'
import { dateToString } from '../../../../shared/date'
import colors from '../../../../styles/colors'
import { iconSizeNormal } from '../../../../styles/metrics'

export interface ImpostoRendaItemProps {
    impostoRenda: ImpostoRenda
    onSelected: (impostoRenda: ImpostoRenda) => void
    onRemove: (impostoRenda: ImpostoRenda) => void
    user: User | null | undefined
}

const ImpostoRendaItem = ({
    impostoRenda,
    onSelected,
    onRemove,
    user,
}: ImpostoRendaItemProps) => {
    return (
        <TouchableOpacity
            style={styles.container}
            onPress={() => onSelected(impostoRenda)}
        >
            <View>
                <Text style={styles.label}>Empresa / Indivíduo</Text>
                <Text style={[styles.value, { marginBottom: 16 }]}>
                    {impostoRenda.company?.name || '-'}
                </Text>
                <Text style={styles.label}>Status</Text>
                <Text style={[styles.value, { marginBottom: 16 }]}>
                    {impostoRenda.status?.name || '-'}
                </Text>
                <Text style={styles.label}>Login</Text>
                <Text style={[styles.value, { marginBottom: 16 }]}>
                    {impostoRenda.login}
                </Text>
                <Text style={styles.label}>Senha</Text>
                <Text style={[styles.value, { marginBottom: 16 }]}>
                    {impostoRenda.senha}
                </Text>
                <Text style={styles.label}>Código de Acesso</Text>
                <Text style={[styles.value, { marginBottom: 16 }]}>
                    {impostoRenda.codigoAcesso}
                </Text>
                <Text style={styles.label}>Data de Nascimento</Text>
                <Text style={[styles.value, { marginBottom: 16 }]}>
                    {dateToString(impostoRenda.dataNascimento as any)}
                </Text>
                <Text style={styles.label}>CEP</Text>
                <Text style={[styles.value, { marginBottom: 16 }]}>
                    {impostoRenda.cep}
                </Text>
                <Text style={styles.label}>Nome Completo</Text>
                <Text style={[styles.value, { marginBottom: 16 }]}>
                    {impostoRenda.nomeCompleto}
                </Text>
            </View>
            {SystemRole.isSuperAdmin(user?.systemRole || 0) && (
                <TouchableOpacity onPress={() => onRemove(impostoRenda)}>
                    <Image
                        style={styles.iconRemove}
                        source={require('../../../../../assets/images/ic_remove.png')}
                    />
                </TouchableOpacity>
            )}
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingTop: 16,
        paddingBottom: 16,
        borderBottomColor: '#bbb',
        borderBottomWidth: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    label: {
        color: colors.secondary,
        fontSize: 18,
        fontWeight: 'bold',
    },
    value: {
        color: colors.secondary,
        fontSize: 14,
        fontWeight: 'normal',
    },
    recurrence: {
        color: colors.secondary,
        fontSize: 14,
        fontWeight: 'normal',
        marginTop: 8,
    },
    iconRemove: {
        width: iconSizeNormal,
        height: iconSizeNormal,
        tintColor: '#f55',
    },
})

export default ImpostoRendaItem
