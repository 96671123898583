import React, { useEffect, useState } from 'react'
import {
    NativeScrollEvent,
    NativeSyntheticEvent,
    RefreshControl,
    ScrollView,
    TouchableOpacity,
    View,
} from 'react-native'
import Button from '../../../../components/Button'
import ContentHeader from '../../../../components/ContentHeader'
import Modal from '../../../../components/Modal'
import Spinner from '../../../../components/Spinner'
import User from '../../../../models/user'
import { getUsersActivities } from '../../../../services/api'
import ActivityItem from './ActivityItem'
import styles from './styles'

export default function ActivitiesFragment() {
    const [refreshing, setRefreshing] = useState(false)
    const [activities, setActivities] = useState<User[]>([])
    const [userSelected, setUserSelected] = useState<User | null>(null)
    const [page, setPage] = useState(0)
    const [initted, setInitted] = useState(false)

    useEffect(() => {
        if (refreshing || initted) {
            return
        }
        setRefreshing(true)
        setInitted(true)
        getUsersActivities(page).then(response => {
            setActivities(response.users)
            setRefreshing(false)
        })
    }, [refreshing, initted])

    const _fetchUsersActivities = (nextPage: number) => {
        getUsersActivities(nextPage).then(response => {
            setActivities(response.users)
            setRefreshing(false)
        })
    }

    const _isCloseToBottom = (
        event: NativeSyntheticEvent<NativeScrollEvent>,
    ) => {
        const { layoutMeasurement, contentOffset, contentSize } =
            event.nativeEvent

        const paddingToBottom = 20
        const close =
            layoutMeasurement.height + contentOffset.y >=
            contentSize.height - paddingToBottom
        if (close) {
            if (refreshing) {
                return
            }
            const nextPage = page + 1
            setPage(nextPage)
            _fetchUsersActivities(nextPage)
            //     if (!updating && hasMoreItems) {
            //         dealsGet(false, false)
            //     }
        }
    }

    const setSearchTerm = (term: string) => {
        console.log(term)
    }

    const _onRefresh = () => {
        setRefreshing(true)
        setPage(0)
        _fetchUsersActivities(0)
    }

    return (
        <>
            <ScrollView
                onScroll={_isCloseToBottom}
                scrollEventThrottle={400}
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={_onRefresh}
                    />
                }
            >
                <View style={styles.container}>
                    <View style={styles.header}>
                        <ContentHeader
                            title="Atividades"
                            searchBar
                            onSearch={term => setSearchTerm(term)}
                        />
                    </View>
                    {activities.map(user => (
                        <TouchableOpacity
                            key={`activity-item-button-${user.id}`}
                            onPress={() => setUserSelected(user)}
                        >
                            <ActivityItem
                                key={`activity-item-${user.id}`}
                                user={user}
                            />
                        </TouchableOpacity>
                    ))}
                </View>
            </ScrollView>
            <Modal
                isVisible={userSelected != null}
                title={`Atividades de ${userSelected?.name || ''}`}
            >
                <View style={{ height: '80%', padding: 8 }}>
                    <View style={{ height: '100%' }}>
                        <ScrollView>
                            <View>
                                {userSelected
                                    ? [userSelected!].map(user => (
                                          <ActivityItem
                                              key={`activity-item-modal-${user.id}`}
                                              user={user}
                                              allItems
                                          />
                                      ))
                                    : null}
                            </View>
                        </ScrollView>
                    </View>
                    <Button
                        primary
                        style={{ marginTop: 12, marginBottom: 10 }}
                        label="OK"
                        onPress={() => setUserSelected(null)}
                    />
                </View>
            </Modal>
            <Spinner visible={refreshing} overlayColor={'rgba(0, 0, 0, 0.5)'} />
        </>
    )
}
