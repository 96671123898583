import { StyleSheet } from 'react-native'

// const windowHeight = Dimensions.get('window').height

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
    },
    containerContent: {
        flex: 1,
        flexDirection: 'row',
    },
    content: {
        flex: 1,
        // height: windowHeight,
    },
})

export default styles
