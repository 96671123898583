import React, { useState } from 'react'
import { Text } from 'react-native'
import Button from '../../../../../../components/Button'
import Role from '../../../../../../models/role'

import styles from './styles'

interface Props {
    role?: Role
    onBack: () => void
    toggleEdit: () => void
}

const RoleFormDetail: React.FC<Props> = ({ role, onBack, toggleEdit }) => {
    const [name] = useState(role?.name || '')

    return (
        <>
            <Text style={styles.label}>Nome</Text>
            <Text style={styles.name}>{name}</Text>
            <Button primary style={{ marginTop: 12, marginBottom: 10 }} label='Editar' onPress={toggleEdit} />
            <Button secondary label='Voltar' onPress={onBack} />
        </>
    )
}

export default RoleFormDetail
