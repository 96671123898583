import React from 'react'
import { ActivityIndicator, Platform, View } from 'react-native'
import styles from './styles'

interface Props {
    visible?: boolean
    overlayColor?: string
}

const SpinnerWeb = ({ visible, overlayColor }: Props) => {
    if (!visible) {
        return null
    }
    return (
        <View style={[styles.container, { backgroundColor: overlayColor }]}>
            <ActivityIndicator size="large" color="#fff" />
        </View>
    )
}

const SpinnerComponent =
    Platform.OS === 'web'
        ? SpinnerWeb
        : require('react-native-loading-spinner-overlay').default

const Spinner = ({ visible, overlayColor }: Props) => {
    return (
        <SpinnerComponent
            visible={visible}
            overlayColor={overlayColor || 'rgba(0, 0, 0, 0.5)'}
        />
    )
}

export default Spinner
