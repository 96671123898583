export const Fragment = {
    Main: 0,
    Companies: 1,
    Employees: 2,
    Roles: 3,
    Profile: 4,
    Statements: 5, // Declarações
    RegimeTypes: 6, // tipo de empresa (Regime tributário)
    ServiceTypes: 7, // tipo de serviço (Abertura, Alteração, Constituição, Baixa, Transformação)
    CompanyTypes: 8, // tipo de empresa (Indústria, Serviço, Comércio)
    ImpostoRenda: 9,
    ActivityReport: 10,
    Documents: 11,
    Responsibles: 12,
}
