import {
    StyleProp,
    StyleSheet,
    Text,
    TextStyle,
    TouchableOpacity,
    View,
} from 'react-native'

import React from 'react'
import colors from '../../../../../styles/colors'
import { isSmallScreen } from '../../../../../styles/metrics'

type TabItemProps = {
    setTab: (tab: string) => void
    label: string
    style?: StyleProp<TextStyle> | undefined
    active: boolean
    tab: string
}

export default function TabItem({
    setTab,
    tab,
    label,
    style,
    active,
}: TabItemProps) {
    return (
        <TouchableOpacity style={styles.tab} onPress={() => setTab(tab)}>
            <Text style={style}>{label}</Text>
            <View style={styles.lineContainer}>
                {active ? <View style={styles.lineDetail} /> : null}
                <View style={styles.line} />
            </View>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    tab: {
        position: 'relative',
        flex: 1,
    },
    lineContainer: {
        position: 'relative',
        justifyContent: 'center',
    },
    lineDetail: {
        position: 'absolute',
        width: isSmallScreen ? '20%' : '10%',
        height: 3,
        backgroundColor: colors.accent,
    },
    line: {
        height: 1,
        backgroundColor: '#bbb',
    },
})
