import React, { useEffect, useRef, useState } from 'react'
import { Keyboard, Text, TouchableOpacity } from 'react-native'
import Dropdown, { DropdownItem } from '../../../../../../components/Dropdown'
import { getRoles, saveUser } from '../../../../../../services/api'

import Button from '../../../../../../components/Button'
import CheckBox from '../../../../../../components/CheckBox'
import Input from '../../../../../../components/Input'
import User from '../../../../../../models/user'
import Settings from '../../../../../../services/settings'
import { SystemRole } from '../../../../../../shared/consts'
import { isEmpty } from '../../../../../../shared/utils'
import styles from './styles'

interface Props {
    user?: User
    onBack: () => void
    setLoading: (val: boolean) => void
    setEditing: (val: boolean) => void
    onUserUpdated: (u: User) => void
}

const UserFormEditing: React.FC<Props> = ({
    user,
    onBack,
    setLoading,
    setEditing,
    onUserUpdated,
}) => {
    const [errorMessage, setErrorMessage] = useState('')

    const [name, setName] = useState(user?.name || '')
    const [email, setEmail] = useState(user?.email || '')
    const [cpf, setCpf] = useState(user?.cpf || '')
    const [roleId, setRoleId] = useState(user?.roleId || 0)
    const [removed, setRemoved] = useState(user?.removed || false)
    const [isTheResponsible, setIsTheResponsible] = useState(
        user?.responsibleForArea || false,
    )
    const [password, setPassword] = useState('')
    const [admin, setAdmin] = useState(
        user?.systemRole === SystemRole.dalconEmployeeSuperAdmin,
    )
    const [roleText, setRoleText] = useState(user?.roleText || '')
    const [loggedUserId, setLoggedUserId] = useState(0)

    const [loggedUserRole, setLoggedUserRole] = useState(0)

    const [rolesDropdownItems, setRolesDropdownItems] = useState<
        DropdownItem[]
    >([])

    const [masterCompanyId, setMasterCompanyId] = useState(0)

    const inputEmail = useRef()
    const inputCpf = useRef()
    const inputPassword = useRef()

    useEffect(() => {
        if (masterCompanyId === 0) {
            ;(async () => {
                const companyMasterId = await Settings.getCompanyMasterId()
                setMasterCompanyId(Number(companyMasterId))
            })()
        }
    }, [masterCompanyId])

    useEffect(() => {
        ;(async () => {
            const loggerUser = await Settings.getUser()
            setLoggedUserId(loggerUser?.id || 0)
            setLoggedUserRole(loggerUser?.systemRole || 0)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            try {
                const loggedUser = await Settings.getUser()
                const response = await getRoles(
                    user?.companyId || loggedUser?.companyId || 0,
                )
                if (response) {
                    const items: DropdownItem[] = response.map(
                        role => new DropdownItem(role.id, role.name),
                    )
                    setRolesDropdownItems(items)
                }
            } catch (e) {
                console.log('CompanyFormPendencies init exception: ', e)
            }
        })()
    }, [user])

    const _toggleUserActive = async (item: any) => {
        const data = await _getData()
        if (data) {
            data.user.removed = item.id === 2
            setRemoved(data.user.removed)
            await saveUser(data)
        }
    }

    const _getData = async () => {
        const loggedUser = await Settings.getUser()
        const companyMasterId = await Settings.getCompanyMasterId()
        if (!loggedUser || !companyMasterId) {
            return
        }
        const data = {
            user: {
                id: 0,
                name: name.trim(),
                cpf,
                email: email.toLowerCase(),
                systemRole: admin
                    ? SystemRole.dalconEmployeeSuperAdmin
                    : loggedUser.companyId === companyMasterId
                    ? SystemRole.dalconEmployee
                    : SystemRole.employee,
                password,
                companyId: loggedUser.companyId,
                roleId,
                removed: false,
                responsibleForArea: isTheResponsible,
                roleText: roleText,
            },
        }
        if (user) {
            data.user.id = user.id
            data.user.removed = removed
        }
        return data
    }

    const _save = async () => {
        if (isEmpty(name)) {
            setErrorMessage('Por favor, informe o nome do funcionário.')
        } else if (isEmpty(cpf)) {
            setErrorMessage('Por favor, informe o CPF do funcionário.')
        } else if (isEmpty(email)) {
            setErrorMessage('Por favor, informe o e-mail do funcionário.')
        } else {
            setErrorMessage('')
            setLoading(true)
            try {
                const data = await _getData()

                const result = await saveUser(data)
                if (user && result) {
                    setEditing(false)
                    const u = new User(result.user)
                    onUserUpdated(u)
                } else {
                    onBack()
                }

                // const result = await saveCompany(data)
                // if (company) {
                //     setEditing(false)
                //     const c = result.company ? new Company(result.company) : company
                //     onCompanyUpdated(c)
                // } else {
                //     onBack()
                // }
            } catch (e) {
                console.log('CompanyFormEditing exception: ', e)
                setErrorMessage(
                    'Erro inesperado. Verifique sua conexão com a internet e tente novamente.',
                )
            } finally {
                setLoading(false)
            }
        }
    }

    // console.log('admin: ', admin)

    return (
        <>
            {errorMessage.length > 0 ? (
                <Text style={styles.errorMessage}>{errorMessage}</Text>
            ) : null}
            <Input
                nextRef={inputEmail}
                returnKeyType="next"
                autoCapitalize="words"
                style={{ marginBottom: 12 }}
                label="Nome"
                value={name}
                onChangeText={text => setName(text)}
            />
            <Input
                ref={inputEmail}
                nextRef={inputCpf}
                returnKeyType="next"
                keyboardType="email-address"
                autoCapitalize="none"
                style={{ marginBottom: 12 }}
                label="E-mail"
                value={email}
                onChangeText={text => setEmail(text)}
            />
            <Input
                ref={inputCpf}
                returnKeyType="done"
                keyboardType="numeric"
                maskType="cpf"
                style={{ marginBottom: 12 }}
                label="CPF"
                value={cpf}
                onSubmitEditing={() => Keyboard.dismiss()}
                onChangeText={text => setCpf(text)}
            />
            {loggedUserId > 0 && loggedUserId === masterCompanyId ? (
                <Dropdown
                    label="Área"
                    selectedLabel={user?.role?.name}
                    modalTitle="Escolha a área de atuação"
                    items={rolesDropdownItems}
                    placeholder="Escolha área"
                    onItemSelected={item => setRoleId(item.id)}
                />
            ) : (
                <Input
                    keyboardType="default"
                    style={{ marginBottom: 12 }}
                    label="Área de atuação"
                    value={roleText}
                    onSubmitEditing={() => Keyboard.dismiss()}
                    onChangeText={text => setRoleText(text)}
                />
            )}

            <Dropdown
                label="Responsável da área?"
                selectedLabel={isTheResponsible ? 'Sim' : 'Não'}
                modalTitle="Escolha a área de atuação"
                items={['Sim', 'Não'].map(
                    (i, index) => new DropdownItem(index + 1, i),
                )}
                placeholder="Selecione se o usuário é o responsável da área."
                onItemSelected={item => setIsTheResponsible(item.id === 1)}
            />

            {loggedUserId !== user?.id ? (
                <Dropdown
                    label="Ativo?"
                    selectedLabel={removed ? 'Não' : 'Sim'}
                    modalTitle="Escolha a área de atuação"
                    items={['Sim', 'Não'].map(
                        (i, index) => new DropdownItem(index + 1, i),
                    )}
                    placeholder="Selecione se o usuário está ativo ou removido"
                    onItemSelected={item => _toggleUserActive(item)}
                />
            ) : null}

            <Input
                ref={inputPassword}
                returnKeyType="go"
                autoCapitalize="none"
                secureTextEntry={true}
                style={{ marginBottom: 12 }}
                label="Senha"
                value={password}
                onSubmitEditing={_save}
                onChangeText={text => setPassword(text)}
            />

            {loggedUserRole === SystemRole.superadmin ? (
                <TouchableOpacity
                    style={styles.containerAdmin}
                    onPress={() => setAdmin(!admin)}
                >
                    <CheckBox checked={admin} />
                    <Text>Administrador</Text>
                </TouchableOpacity>
            ) : null}

            <Button
                primary
                style={{ marginTop: 12, marginBottom: 10 }}
                label="Salvar"
                onPress={_save}
            />
            <Button
                secondary
                style={{ marginBottom: 50 }}
                label="Cancelar"
                onPress={() => (user ? setEditing(false) : onBack())}
            />
        </>
    )
}

export default UserFormEditing
