class Phone {
    public id: number
    public number: string
    public companyId: number

    constructor(json: any) {
        this.id = json.id || 0
        this.number = json.number || ''
        this.companyId = json.companyId || 0
    }
}

export default Phone
