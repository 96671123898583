import { StyleSheet } from 'react-native'
import colors from '../../styles/colors'
import { isSmallScreen } from '../../styles/metrics'

const styles = StyleSheet.create({
    container: {},
    title: {
        fontWeight: 'bold',
        fontSize: 16,
        color: colors.secondary,
        marginBottom: 8,
    },
    lineContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 8,
    },
    lineDetail: {
        width: isSmallScreen ? '10%' : '5%',
        height: 3,
        backgroundColor: colors.accent,
    },
    line: {
        width: isSmallScreen ? '90%' : '95%',
        height: 1,
        backgroundColor: '#bbb',
    },
})

export default styles
