import React, { useEffect, useState } from 'react'
import { ScrollView, Text } from 'react-native'
import Company, {
    companyServiceTypesString,
    companyStatementsString,
    companyTypesString,
} from '../../../../../../models/company'
import {
    getCity,
    getCompany,
    getOwner,
    getPhones,
    getState,
} from '../../../../../../services/api'

import Button from '../../../../../../components/Button'
import Phone from '../../../../../../models/phone'
import User from '../../../../../../models/user'
import { isEmpty } from '../../../../../../shared/utils'
import styles from './styles'

interface Props {
    companyId: number
    onBack: () => void
    toggleEdit: () => void
}

const CompanyFormDetail: React.FC<Props> = ({
    companyId,
    onBack,
    toggleEdit,
}) => {
    const [company, setCompany] = useState<Company | undefined>(undefined)
    const [city, setCity] = useState('')
    const [state, setState] = useState('')

    const [ownerName, setOwnerName] = useState('')
    const [ownerEmail, setOwnerEmail] = useState('')

    const [phones, setPhones] = useState<Phone[]>([])

    useEffect(() => {
        ;(async () => {
            try {
                // update company
                const _company = await getCompany(companyId || 0)
                setCompany(_company)
                // city
                const cityResponse = await getCity(_company.cityId || 0)
                if (cityResponse.id) {
                    setCity(cityResponse.name || '')
                }
                // state
                const stateResponse = await getState(_company.stateId || 0)
                if (stateResponse.id) {
                    setState(stateResponse.name || '')
                }
                try {
                    // owner
                    const owner = await getOwner(_company.id || 0)
                    if (owner) {
                        const user = new User(owner)
                        setOwnerName(user.name || '')
                        setOwnerEmail(user.email || '')
                    }
                } catch (e) {
                    console.log('CompanyFormDetail useEffect ERROR: ', e)
                }

                // phones
                const phonesResponse = await getPhones(_company.id || 0)
                if (phonesResponse) {
                    const _phones: Phone[] = []
                    for (const phone of phonesResponse) {
                        _phones.push(new Phone(phone))
                    }
                    if (_phones.length === 0) {
                        _phones.push(new Phone({ companyId: _company.id || 0 }))
                    }
                    setPhones(_phones)
                }
            } catch (e) {
                console.log('CompanyFormDetails init exception: ', e)
            }
        })()
    }, [companyId])

    let _address = company?.address
    if (!isEmpty(company?.addressNumber)) {
        _address = `${_address}, ${company?.addressNumber}`
    }

    return (
        <ScrollView>
            <Text style={styles.label}>Nome</Text>
            <Text style={styles.name}>{company?.name}</Text>

            <Text style={styles.label}>
                {company?.cnpj ? 'CNPJ' : company?.cpf ? 'CPF' : ''}
            </Text>
            <Text style={styles.value}>
                {company?.cnpj || company?.cpf || ''}
            </Text>

            <Text style={styles.label}>Situação</Text>
            <Text style={styles.value}>{company?.companySituation?.name}</Text>

            <Text style={styles.label}>COMPLEMENTO</Text>
            <Text style={styles.value}>
                {company?.additionalInformation || '-'}
            </Text>

            <Text style={styles.label}>CNAEs</Text>
            <Text style={styles.value}>{company?.cnaes || '-'}</Text>

            <Text style={styles.label}>Endereço</Text>
            <Text style={styles.value}>{_address || '-'}</Text>

            <Text style={styles.label}>CEP</Text>
            <Text style={styles.value}>{company?.cep || '-'}</Text>

            <Text style={styles.label}>Cidade / Estado</Text>
            <Text style={styles.value}>
                {city || '-'} / {state || '-'}
            </Text>
            <Text style={styles.label}>Responsável</Text>
            <Text style={styles.value}>{ownerName || '-'}</Text>
            <Text style={styles.value}>{ownerEmail || '-'}</Text>
            <Text style={styles.label}>Telefone(s)</Text>
            {phones && phones.length > 0 ? (
                phones.map((phone, index) => (
                    <Text key={`phone_list_${index}`} style={styles.value}>
                        {phone.number || '-'}
                    </Text>
                ))
            ) : (
                <Text style={styles.value}>'-'</Text>
            )}
            <Text style={styles.label}>Regime</Text>
            <Text style={styles.value}>{company?.regime?.name || '-'}</Text>
            <Text style={styles.label}>Tipo de Empresa</Text>
            <Text style={styles.value}>
                {companyTypesString(company) || '-'}
            </Text>
            <Text style={styles.label}>Tipo de Serviço</Text>
            <Text style={styles.value}>
                {companyServiceTypesString(company) || '-'}
            </Text>
            <Text style={styles.label}>Declarações</Text>
            <Text style={styles.value}>
                {companyStatementsString(company) || '-'}
            </Text>
            {/* Buttons */}
            <Button
                primary
                style={{ marginTop: 12, marginBottom: 10 }}
                label="Editar"
                onPress={toggleEdit}
            />
            <Button secondary label="Voltar" onPress={onBack} />
        </ScrollView>
    )
}

export default CompanyFormDetail
