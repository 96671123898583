import { PendencyContextProvider } from '../../../../contexts/pendency-context'
import React from 'react'
import RequestList from './RequestList'

// import styles from './styles'

const MainFragment: React.FC = () => {
    // const alert = useAlert()
    // useEffect(() => {
    //     console.log('MainFragment')
    //     alert.show('Testing', 'Meu alerta de teste', 'OK')
    // }, [alert])
    return (
        <PendencyContextProvider>
            <RequestList />
        </PendencyContextProvider>
    )
}

export default MainFragment
