import * as FileSystem from 'expo-file-system'

import React, { useCallback, useEffect, useState } from 'react'
import {
    Image,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'
import Dropdown, { DropdownItem } from '../../../../../../components/Dropdown'
import {
    getDocFormularioConstituicao,
    getDocumentsByCompany,
    getPortesEmpresa,
    getTiposSociedade,
    removeDocumentsByCompany,
    saveDocumentForCompany,
    saveDocumentForCompanyTest,
} from '../../../../../../services/api'
import { datetimeToString, formatDate } from '../../../../../../shared/date'
import { iconSizeNormal, iconSizeSmall } from '../../../../../../styles/metrics'
import Tabs, { tabStyles } from '../Tabs'

import { A } from '@expo/html-elements'
import getEnvVars from '../../../../../../../environments'
import Button from '../../../../../../components/Button'
import FabButton from '../../../../../../components/FabButton'
import Input from '../../../../../../components/Input'
import Modal from '../../../../../../components/Modal'
import Spinner from '../../../../../../components/Spinner'
import { useAlert } from '../../../../../../hooks/use-alert'
import DocFormularioConstituicao from '../../../../../../models/doc-formulario-constituicao'
import Document from '../../../../../../models/document'
import PorteEmpresa from '../../../../../../models/porte-empresa'
import TipoSociedade from '../../../../../../models/tipo-sociedade'
import User from '../../../../../../models/user'
import Settings from '../../../../../../services/settings'
import { getDocument } from '../../../../../../shared/file-utils'
import colors from '../../../../../../styles/colors'
import TabItem from '../TabItem'

type CompanyFormDocumentsProps = {
    companyId: number
    scrollEnabled?: boolean
}

const TAB_TAGS = {
    formularioDeConstituicao: 'formulario_de_constituicao',
    contratoSocial: 'contrato_social',
    impostoRenda: 'imposto_renda',
    alvaras: 'alvaras',
    extratos: 'extratos',
    notasFiscais: 'notas_fiscais',
    dptoPessoal: 'dpto_pessoal',
    dptoContabil: 'dpto_contabil',
    dptoFiscal: 'dpto_fiscal',
    dptoFinanceiro: 'dpto_financeiro',
    dptoProcessos: 'dpto_processos',
    reunioes: 'reunioes',
    inss: 'inss',
}

export default function CompanyFormDocuments({
    companyId,
    scrollEnabled,
}: CompanyFormDocumentsProps) {
    const [companyMasterId, setCompanyMasterId] = useState<number | null>(null)
    const [user, setUser] = useState<User | null>(null)

    const [folderTag, setFolderTag] = useState('')
    const [documents, setDocuments] = useState<Document[]>([])
    const [tab, setTab] = useState(TAB_TAGS.formularioDeConstituicao)
    const [documentToRemove, setDocumentToRemove] = useState<Document | null>(
        null,
    )
    const [editing, setEditing] = useState<Document | null>(null)
    const [editingName, setEditingName] = useState('')
    const [refreshing, setRefreshing] = useState(false)

    const [nfZip, setNfZip] = useState<Document | null>(null)
    const [nfXml, setNfXml] = useState<Document | null>(null)
    const [showNfModal, setShowNfModal] = useState(false)
    const [showFormConstituicaoModal, setShowFormConstituicaoModal] =
        useState(false)

    const [tiposSociedade, setTiposSociedade] = useState<TipoSociedade[]>([])
    const [portesEmpresa, setPortesEmpresa] = useState<PorteEmpresa[]>([])

    const [tipoSociedade, setTipoSociedade] = useState<TipoSociedade | null>()
    const [porteEmpresa, setPorteEmpresa] = useState<PorteEmpresa | null>()

    const alert = useAlert()

    const [nomeFantasia, setNomeFantasia] = useState('')
    const [nomeEmpresarial1, setNomeEmpresarial1] = useState('')
    const [nomeEmpresarial2, setNomeEmpresarial2] = useState('')
    const [nomeEmpresarial3, setNomeEmpresarial3] = useState('')
    const [sociosAssinam, setSociosAssinam] = useState('')
    const [capitalSocial, setCapitalSocial] = useState('')
    const [socioAdministrador, setSocioAdministrador] = useState('')
    // const [nomeSocio1, setNomeSocio1] = useState('')
    // const [nomeSocio2, setNomeSocio2] = useState('')
    // const [nomeSocio3, setNomeSocio3] = useState('')
    const [socios, setSocios] = useState<any[]>([])
    // const [atividadePretendida1, setAtividadePretendida1] = useState('')
    // const [atividadePretendida2, setAtividadePretendida2] = useState('')
    // const [atividadePretendida3, setAtividadePretendida3] = useState('')
    // const [atividadePretendida4, setAtividadePretendida4] = useState('')
    // const [atividadePretendidaCNAE1, setAtividadePretendidaCNAE1] = useState('')
    // const [atividadePretendidaCNAE2, setAtividadePretendidaCNAE2] = useState('')
    // const [atividadePretendidaCNAE3, setAtividadePretendidaCNAE3] = useState('')
    // const [atividadePretendidaCNAE4, setAtividadePretendidaCNAE4] = useState('')
    const [atividadesPretendidas, setAtividadesPretendidas] = useState<any[]>(
        [],
    )
    const [menorDe18Anos, setMenorDe18Anos] = useState(false)
    const [
        participaComoSocioEmOutraEmpresa,
        setParticipaComoSocioEmOutraEmpresa,
    ] = useState(false)
    const [extrangeiro, setExtrangeiro] = useState(false)
    const [inscricaoEstadualCancelada, setInscricaoEstadualCancelada] =
        useState(false)
    const [debitosEstaduais, setDebitosEstaduais] = useState(false)
    const [aposentadoPorInvalidez, setAposentadoPorInvalidez] = useState(false)
    // const [percCapicalSocialSocio1, setPercCapicalSocialSocio1] = useState('')
    // const [percCapicalSocialSocio2, setPercCapicalSocialSocio2] = useState('')
    // const [percCapicalSocialSocio3, setPercCapicalSocialSocio3] = useState('')
    // const [emailSocio1, setEmailSocio1] = useState('')
    // const [emailSocio2, setEmailSocio2] = useState('')
    // const [emailSocio3, setEmailSocio3] = useState('')
    const [telefoneEmpresa1, setTelefoneEmpresa1] = useState('')
    const [telefoneEmpresa2, setTelefoneEmpresa2] = useState('')
    const [telefoneEmpresa3, setTelefoneEmpresa3] = useState('')
    const [valorHonorarios, setValorHonorarios] = useState('')
    const [valorConstituicao, setValorConstituicao] = useState('')
    const [quantidadeFuncionarios, setQuantidadeFuncionarios] = useState('')

    // const inputNomeEmpresarial1 = useRef()
    // const inputNomeEmpresarial2 = useRef()
    // const inputNomeEmpresarial3 = useRef()
    // const inputCapitalSocial = useRef()
    // const inputSocioAdministrador = useRef()
    // const inputNomeSocio1 = useRef()
    // const inputNomeSocio2 = useRef()
    // const inputNomeSocio3 = useRef()
    // const inputPercCapitalSocialSocio1 = useRef()
    // const inputPercCapitalSocialSocio2 = useRef()
    // const inputPercCapitalSocialSocio3 = useRef()
    // const inputEmailSocio1 = useRef()
    // const inputEmailSocio2 = useRef()
    // const inputEmailSocio3 = useRef()
    // const inputTelefoneEmpresa1 = useRef()
    // const inputTelefoneEmpresa2 = useRef()
    // const inputTelefoneEmpresa3 = useRef()
    // const inputValorQuantidadeFuncionarios = useRef()

    const updateTiposFormConstituicao = useCallback(async () => {
        let resp = await getTiposSociedade()
        setTiposSociedade(resp)
        resp = await getPortesEmpresa()
        setPortesEmpresa(resp)
    }, [])

    const updateDocuments = useCallback(() => {
        setRefreshing(true)
        getDocumentsByCompany(companyId, tab)
            .then(docs => {
                const _docs: Document[] = []
                for (const doc of docs) {
                    _docs.push(new Document(doc))
                }
                setDocuments(_docs)
            })
            .finally(() => setRefreshing(false))
    }, [companyId, tab])

    useEffect(() => {
        updateDocuments()
        updateTiposFormConstituicao()
    }, [updateDocuments, updateTiposFormConstituicao])

    useEffect(() => {
        ;(async () => {
            const id = await Settings.getCompanyMasterId()
            setCompanyMasterId(id)
            const u = await Settings.getUser()
            setUser(u)
        })()
    }, [])

    const _addNf = async (
        setFunc: React.Dispatch<React.SetStateAction<Document | null>>,
    ) => {
        let error = ''
        try {
            setRefreshing(true)
            const doc = await getDocument()
            if (doc) {
                doc.companyId = companyId
                doc.type = tab
                setFunc(doc)
            } else {
                error = 'Ocorreu um erro ao tentar adicionar um documento'
            }
        } catch (e: any) {
            console.log(e)
            error =
                e.message ||
                'Ocorreu uma excessão ao tentar adicionar um documento'
        } finally {
            setRefreshing(false)
        }
        if (error) {
            alert.show('Erro', error, 'OK')
        }
    }

    const _addNfZip = async () => {
        _addNf(setNfZip)
    }

    const _addNfXml = async () => {
        _addNf(setNfXml)
    }

    const _saveNf = async () => {
        if (nfZip) {
            const _nfZip = { ...nfZip }
            _nfZip.groupId = -1
            const result = await _save(_nfZip)
            if (result.document && nfXml) {
                const _nfXml = { ...nfXml }
                _nfXml.groupId = result.document.id
                await _save(_nfXml)
            }
        }
        setShowNfModal(false)
    }

    const _saveFormConstituicao = async () => {
        let error = ''
        try {
            if (!tipoSociedade) {
                error = 'Selecione o tipo de sociedade'
            } else if (!porteEmpresa) {
                error = 'Selecione o porte da empresa'
            } else {
                const _formConstituicao: DocFormularioConstituicao =
                    editing && editing.formConstituicao
                        ? { ...editing.formConstituicao }
                        : new DocFormularioConstituicao()

                _formConstituicao.companyId = companyId
                _formConstituicao.tipoSociedadeId = tipoSociedade.id
                _formConstituicao.porteEmpresaId = porteEmpresa.id
                _formConstituicao.tipoSociedade = tipoSociedade
                _formConstituicao.porteEmpresa = porteEmpresa
                _formConstituicao.nomeFantasia = nomeFantasia
                _formConstituicao.nomeEmpresarial1 = nomeEmpresarial1
                _formConstituicao.nomeEmpresarial2 = nomeEmpresarial2
                _formConstituicao.nomeEmpresarial3 = nomeEmpresarial3
                _formConstituicao.sociosAssinam = sociosAssinam
                _formConstituicao.capitalSocial = capitalSocial
                _formConstituicao.socioAdministrador = socioAdministrador
                // _formConstituicao.nomeSocio1 = nomeSocio1
                // _formConstituicao.nomeSocio2 = nomeSocio2
                // _formConstituicao.nomeSocio3 = nomeSocio3
                _formConstituicao.socios = socios
                _formConstituicao.situacaoSocios = {
                    menorDe18Anos,
                    participaComoSocioEmOutraEmpresa,
                    extrangeiro,
                    inscricaoEstadualCancelada,
                    debitosEstaduais,
                    aposentadoPorInvalidez,
                }
                ///
                _formConstituicao.atividadesPretendidas = atividadesPretendidas
                // _formConstituicao.menorDe18Anos = menorDe18Anos
                // _formConstituicao.participaComoSocioEmOutraEmpresa =
                //     participaComoSocioEmOutraEmpresa
                // _formConstituicao.extrangeiro = extrangeiro
                // _formConstituicao.inscricaoEstadualCancelada =
                //     inscricaoEstadualCancelada
                // _formConstituicao.debitosEstaduais = debitosEstaduais
                // _formConstituicao.aposentadoPorInvalidez =
                //     aposentadoPorInvalidez

                ///
                _formConstituicao.telefonesEmpresa = [
                    telefoneEmpresa1,
                    telefoneEmpresa2,
                    telefoneEmpresa3,
                ]
                // _formConstituicao.telefoneEmpresa1 = telefoneEmpresa1
                // _formConstituicao.telefoneEmpresa2 = telefoneEmpresa2
                // _formConstituicao.telefoneEmpresa3 = telefoneEmpresa3
                _formConstituicao.valorHonorarios = valorHonorarios
                _formConstituicao.valorConstituicao = valorConstituicao
                _formConstituicao.quantidadeFuncionarios =
                    quantidadeFuncionarios

                const data = editing
                    ? { ...editing }
                    : new Document({
                          name: `Formulário de Constituição - ${
                              documents.length + 1
                          }`,
                      })
                data.type = TAB_TAGS.formularioDeConstituicao
                if (editing && editing.formConstituicao) {
                    const formConstId = Number(editing.formConstituicao.id)
                    if (formConstId > 0) {
                        _formConstituicao.id = formConstId
                    }
                }
                data.formConstituicao = _formConstituicao
                setShowFormConstituicaoModal(false)
                await _save(data)
            }
        } catch (e: any) {
            console.log(e)
            error =
                e.message ||
                'Ocorreu uma excessão ao tentar adicionar um documento'
        }
        if (error) {
            alert.show('Erro', error, 'OK')
        }
    }

    const _save = async (doc: Document | null) => {
        let error = ''
        if (doc) {
            try {
                setRefreshing(true)
                doc.companyId = companyId
                doc.type = tab
                // setDocuments(state => [...state, doc])
                doc.created = !doc.id || doc.id === 0
                let updatedDocc: any = { document: doc }
                if (!doc.formConstituicao && (!doc.id || doc.id === 0)) {
                    updatedDocc = await saveDocumentForCompanyTest(doc)
                }
                // documento muito grande não passa do OPTIONS
                const _doc: Document = { ...doc }
                _doc.id = updatedDocc.document.id || 0
                _doc.url = updatedDocc.document.url || ''
                if (!doc.formConstituicao) {
                    delete _doc.uri
                    delete _doc.base64
                    delete _doc.docResult
                    delete _doc.result
                }
                const updatedDoc = await saveDocumentForCompany(_doc)
                updateDocuments()
                return updatedDoc
            } catch (e: any) {
                console.log(e)
                error =
                    e.message ||
                    'Ocorreu uma excessão ao tentar adicionar um documento'
            } finally {
                setRefreshing(false)
            }
            if (error) {
                alert.show('Erro', error, 'OK')
            }
        } else {
            console.log('Ocorreu um erro ao tentar adicionar um documento')
        }
        return null
    }

    // const _save2 = async (doc: Document | null) => {
    //     let error = ''
    //     console.log('save doc size: ', (doc?.base64?.length || 0) / 1024 + 'kb')
    //     try {
    //         setRefreshing(true)

    //         if (doc) {
    //             doc.companyId = companyId
    //             doc.type = tab
    //             // setDocuments(state => [...state, doc])
    //             console.log('doc go save')
    //             const updatedDoc = await saveDocumentForCompany(doc)
    //             console.log('doc saved')
    //             updateDocuments()
    //             return updatedDoc
    //         } else {
    //             error = 'Ocorreu um erro ao tentar adicionar um documento'
    //         }
    //     } catch (e: any) {
    //         console.log(e)
    //         error =
    //             e.message ||
    //             'Ocorreu uma excessão ao tentar adicionar um documento'
    //     } finally {
    //         setRefreshing(false)
    //     }
    //     if (error) {
    //         alert.show('Erro', error, 'OK')
    //     }
    //     return null
    // }

    const _add = async () => {
        if (tab === TAB_TAGS.formularioDeConstituicao) {
            setSocios([{ nome: '', percCapical: '', email: '' }])
            setAtividadesPretendidas([{ nome: '', cnae: '' }])
            setShowFormConstituicaoModal(true)
        } else if (tab === TAB_TAGS.notasFiscais) {
            setShowNfModal(true)
        } else {
            const doc = await getDocument()
            _save(doc)
        }
    }

    const _remove = (doc: Document) => {
        setDocumentToRemove(doc)
    }

    const _doRemove = async () => {
        if (!documentToRemove) {
            return
        }
        let error = ''
        try {
            setRefreshing(true)
            const res = await removeDocumentsByCompany(documentToRemove)
            if (res.success) {
                updateDocuments()
            } else {
                error =
                    res.message ||
                    `Ocorreu um erro removendo o documento '${documentToRemove.name}'.`
            }
            setDocumentToRemove(null)
            setRefreshing(false)
        } catch (e: any) {
            error =
                e.message ||
                `Ocorreu uma excessão removendo o documento '${documentToRemove.name}'.`
            setRefreshing(false)
        }
        if (error) {
            alert.show('Erro', error, 'OK')
        }
    }

    const _editFormularioDeConstituicao = async (doc: Document) => {
        setRefreshing(true)
        let error = ''
        try {
            const formConstituicao = await getDocFormularioConstituicao(doc.id)
            doc.formConstituicao = formConstituicao
            setEditing(doc)
            setTipoSociedade(
                tiposSociedade.find(
                    i => i.id === formConstituicao.tipoSociedadeId,
                ),
            )
            setPorteEmpresa(
                portesEmpresa.find(
                    i => i.id === formConstituicao.porteEmpresaId,
                ),
            )
            setNomeFantasia(formConstituicao.nomeFantasia)
            setNomeEmpresarial1(formConstituicao.nomeEmpresarial1)
            setNomeEmpresarial2(formConstituicao.nomeEmpresarial2)
            setNomeEmpresarial3(formConstituicao.nomeEmpresarial3)
            setSociosAssinam(formConstituicao.sociosAssinam)
            setCapitalSocial(formConstituicao.capitalSocial)
            setSocioAdministrador(formConstituicao.socioAdministrador)
            setSocios(
                formConstituicao.socios || [
                    { nome: '', percCapical: '', email: '', phone: '' },
                ],
            )
            setAtividadesPretendidas(
                formConstituicao.atividadesPretendidas || [
                    { nome: '', cnae: '' },
                ],
            )

            // setNomeSocio1(formConstituicao.nomeSocio1)
            // setNomeSocio2(formConstituicao.nomeSocio2)
            // setNomeSocio3(formConstituicao.nomeSocio3)
            // setAtividadePretendida1(formConstituicao.atividadePretendida1)
            // setAtividadePretendida2(formConstituicao.atividadePretendida2)
            // setAtividadePretendida3(formConstituicao.atividadePretendida3)
            // setAtividadePretendida4(formConstituicao.atividadePretendida4)
            // setAtividadePretendidaCNAE1(
            //     formConstituicao.atividadePretendidaCNAE1,
            // )
            // setAtividadePretendidaCNAE2(
            //     formConstituicao.atividadePretendidaCNAE2,
            // )
            // setAtividadePretendidaCNAE3(
            //     formConstituicao.atividadePretendidaCNAE3,
            // )
            // setAtividadePretendidaCNAE4(
            //     formConstituicao.atividadePretendidaCNAE4,
            // )
            const situacaoSocios: any = formConstituicao.situacaoSocios || {}
            setMenorDe18Anos(situacaoSocios.menorDe18Anos)
            setParticipaComoSocioEmOutraEmpresa(
                situacaoSocios.participaComoSocioEmOutraEmpresa,
            )
            setExtrangeiro(situacaoSocios.extrangeiro)
            setInscricaoEstadualCancelada(
                situacaoSocios.inscricaoEstadualCancelada,
            )
            setDebitosEstaduais(situacaoSocios.debitosEstaduais)
            setAposentadoPorInvalidez(situacaoSocios.aposentadoPorInvalidez)
            // setPercCapicalSocialSocio1(formConstituicao.percCapicalSocialSocio1)
            // setPercCapicalSocialSocio2(formConstituicao.percCapicalSocialSocio2)
            // setPercCapicalSocialSocio3(formConstituicao.percCapicalSocialSocio3)
            // setEmailSocio1(formConstituicao.emailSocio1)
            // setEmailSocio2(formConstituicao.emailSocio2)
            // setEmailSocio3(formConstituicao.emailSocio3)
            const telefonesEmpresa = formConstituicao.telefonesEmpresa || []
            setTelefoneEmpresa1(telefonesEmpresa[0])
            setTelefoneEmpresa2(telefonesEmpresa[1])
            setTelefoneEmpresa3(telefonesEmpresa[2])
            setValorHonorarios(formConstituicao.valorHonorarios)
            setValorConstituicao(formConstituicao.valorConstituicao)
            setQuantidadeFuncionarios(formConstituicao.quantidadeFuncionarios)
            setShowFormConstituicaoModal(true)
        } catch (e: any) {
        } finally {
            setRefreshing(false)
        }
        if (error) {
            alert.show('Erro', error, 'OK')
        }
    }

    const _viewDoc = (doc: Document) => {
        // Linking.openURL('https://expo.dev');
        FileSystem.downloadAsync(
            `${getEnvVars().filesUrl}${doc.url || ''}`,
            FileSystem.documentDirectory +
                (doc.filename && doc.filename.trim().length
                    ? doc.filename
                    : doc.name),
        )
            .then(({ uri }) => {
                console.log('Finished downloading to ', uri)
            })
            .catch(error => {
                console.error(error)
            })
    }

    const _edit = (doc: Document) => {
        setEditingName(doc.name)
        if (doc.type === TAB_TAGS.formularioDeConstituicao) {
            _editFormularioDeConstituicao(doc)
        } else {
            setEditing(doc)
        }
    }

    const _saveEditing = async () => {
        if (!editing) {
            return
        }
        if (editingName.trim().length === 0) {
            setEditingName('')
            setEditing(null)
            return
        }
        let error = ''
        try {
            const doc = { ...editing }
            doc.name = editingName
            setRefreshing(true)
            const res = await saveDocumentForCompany(doc)
            if (res.success) {
                updateDocuments()
            } else {
                error =
                    res.message ||
                    `Ocorreu um erro atualizando o documento '${editing.name}'.`
            }
            setEditing(null)
            setEditingName('')
            setRefreshing(false)
        } catch (e: any) {
            error =
                e.message ||
                `Ocorreu uma excessão atualizando o documento '${editing.name}'.`
            setRefreshing(false)
        }
        if (error) {
            alert.show('Erro', error, 'OK')
        }
    }

    const _cancelEditing = () => {
        setEditing(null)
        setEditingName('')
    }

    const _findNfXml = (doc: Document) => {
        if (doc.groupId) {
            return documents.find(
                d => d.groupId === doc.groupId && d.id !== doc.id,
            )
        }
    }

    let documentsToShow = [...documents]
    const documentsNFs: Document[] = []
    if (tab === TAB_TAGS.notasFiscais) {
        const docsIdsSort = documentsToShow.sort((a, b) => {
            return a.id - b.id
        })
        for (const doc of docsIdsSort) {
            const _doc = documentsNFs.find(d => {
                return d.groupId === doc.groupId ? d : null
            })
            if (documentsNFs.find(d => d.groupId === doc.groupId)) {
                continue
            }
            if (_doc) {
                documentsNFs.push(_doc)
            } else {
                documentsNFs.push(doc)
            }
        }
        documentsToShow = documentsNFs
    }

    const openFolder = (tag: string) => {
        setFolderTag(tag)
    }

    const closeFolder = () => {
        setFolderTag('')
    }

    if (!companyMasterId) {
        return null
    }

    const tags: string[] =
        !folderTag || !folderTag.length
            ? [...new Set(documentsToShow.map(doc => doc.folderTag))]
            : []

    const folders: string[] =
        !folderTag || !folderTag.length
            ? [
                  ...new Set(
                      documentsToShow.map(doc =>
                          formatDate(doc.dateUpdate, 'MMM yyyy'),
                      ),
                  ),
              ]
            : []
    // const folders: string[] = []
    // if (_folders.length) {
    //     for (let i = 0; i < 50; i++) {
    //         folders.push(_folders[0])
    //     }
    // }

    return (
        <>
            <View style={[styles.container, { marginTop: 40 }]}>
                <Tabs>
                    <TabItem
                        setTab={setTab}
                        tab={TAB_TAGS.formularioDeConstituicao}
                        label="Form. de Constituição"
                        style={[
                            tabStyles.title,
                            tab === TAB_TAGS.formularioDeConstituicao
                                ? null
                                : tabStyles.tabInactive,
                        ]}
                        active={tab === TAB_TAGS.formularioDeConstituicao}
                    />
                    <TabItem
                        setTab={setTab}
                        tab={TAB_TAGS.contratoSocial}
                        label="Contrato Social"
                        style={[
                            tabStyles.title,
                            tab === TAB_TAGS.contratoSocial
                                ? null
                                : tabStyles.tabInactive,
                        ]}
                        active={tab === TAB_TAGS.contratoSocial}
                    />
                    <TabItem
                        setTab={setTab}
                        tab={TAB_TAGS.impostoRenda}
                        label="Imposto de Renda"
                        style={[
                            tabStyles.title,
                            tab === TAB_TAGS.impostoRenda
                                ? null
                                : tabStyles.tabInactive,
                        ]}
                        active={tab === TAB_TAGS.impostoRenda}
                    />
                    <TabItem
                        setTab={setTab}
                        tab={TAB_TAGS.alvaras}
                        label="Alvarás"
                        style={[
                            tabStyles.title,
                            tab === TAB_TAGS.alvaras
                                ? null
                                : tabStyles.tabInactive,
                        ]}
                        active={tab === TAB_TAGS.alvaras}
                    />
                    <TabItem
                        setTab={setTab}
                        tab={TAB_TAGS.extratos}
                        label="Extratos"
                        style={[
                            tabStyles.title,
                            tab === TAB_TAGS.extratos
                                ? null
                                : tabStyles.tabInactive,
                        ]}
                        active={tab === TAB_TAGS.extratos}
                    />
                    <TabItem
                        setTab={setTab}
                        tab={TAB_TAGS.notasFiscais}
                        label="Notas Fiscais"
                        style={[
                            tabStyles.title,
                            tab === TAB_TAGS.notasFiscais
                                ? null
                                : tabStyles.tabInactive,
                        ]}
                        active={tab === TAB_TAGS.notasFiscais}
                    />
                </Tabs>
            </View>
            <View style={[styles.container, { marginTop: 40 }]}>
                <Tabs>
                    <TabItem
                        setTab={setTab}
                        tab={TAB_TAGS.dptoPessoal}
                        label="Dpto Pessoal"
                        style={[
                            tabStyles.title,
                            tab === TAB_TAGS.dptoPessoal
                                ? null
                                : tabStyles.tabInactive,
                        ]}
                        active={tab === TAB_TAGS.dptoPessoal}
                    />
                    <TabItem
                        setTab={setTab}
                        tab={TAB_TAGS.dptoContabil}
                        label="Dpto Contábil"
                        style={[
                            tabStyles.title,
                            tab === TAB_TAGS.dptoContabil
                                ? null
                                : tabStyles.tabInactive,
                        ]}
                        active={tab === TAB_TAGS.dptoContabil}
                    />
                    <TabItem
                        setTab={setTab}
                        tab={TAB_TAGS.dptoFiscal}
                        label="Dpto Fiscal"
                        style={[
                            tabStyles.title,
                            tab === TAB_TAGS.dptoFiscal
                                ? null
                                : tabStyles.tabInactive,
                        ]}
                        active={tab === TAB_TAGS.dptoFiscal}
                    />
                    <TabItem
                        setTab={setTab}
                        tab={TAB_TAGS.dptoFinanceiro}
                        label="Dpto Financeiro"
                        style={[
                            tabStyles.title,
                            tab === TAB_TAGS.dptoFinanceiro
                                ? null
                                : tabStyles.tabInactive,
                        ]}
                        active={tab === TAB_TAGS.dptoFinanceiro}
                    />
                    <TabItem
                        setTab={setTab}
                        tab={TAB_TAGS.dptoProcessos}
                        label="Dpto Processos"
                        style={[
                            tabStyles.title,
                            tab === TAB_TAGS.dptoProcessos
                                ? null
                                : tabStyles.tabInactive,
                        ]}
                        active={tab === TAB_TAGS.dptoProcessos}
                    />
                    <TabItem
                        setTab={setTab}
                        tab={TAB_TAGS.reunioes}
                        label="Reuniões"
                        style={[
                            tabStyles.title,
                            tab === TAB_TAGS.reunioes
                                ? null
                                : tabStyles.tabInactive,
                        ]}
                        active={tab === TAB_TAGS.reunioes}
                    />
                    <TabItem
                        setTab={setTab}
                        tab={TAB_TAGS.inss}
                        label="INSS"
                        style={[
                            tabStyles.title,
                            tab === TAB_TAGS.inss
                                ? null
                                : tabStyles.tabInactive,
                        ]}
                        active={tab === TAB_TAGS.inss}
                    />
                </Tabs>
            </View>
            <ScrollView
                style={[styles.container]}
                scrollEnabled={scrollEnabled}
            >
                {folders.length ? (
                    <View style={styles.foldersContainer}>
                        {folders.map((folder, index) => (
                            <TouchableOpacity
                                onPress={() => openFolder(tags[index])}
                                key={`folder-${index}`}
                            >
                                <Image
                                    style={styles.iconFolder}
                                    source={require('../../../../../../../assets/images/ic_folder.png')}
                                />
                                <Text style={styles.folderName}>{folder}</Text>
                            </TouchableOpacity>
                        ))}
                    </View>
                ) : (
                    <View>
                        {documentsToShow.length ? (
                            <View style={styles.folderBackContainer}>
                                <TouchableOpacity onPress={() => closeFolder()}>
                                    <Image
                                        style={[
                                            styles.iconFolder,
                                            styles.iconFolderOpen,
                                        ]}
                                        source={require('../../../../../../../assets/images/ic_folder_open.png')}
                                    />
                                    <Text style={styles.folderName}>
                                        Voltar
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        ) : null}
                        {documentsToShow.map((document, index) => (
                            <View
                                key={`company-document-${index}`}
                                style={styles.docItemContainer}
                            >
                                {editing === null ||
                                editing !== document ||
                                editing.type ===
                                    TAB_TAGS.formularioDeConstituicao ? (
                                    <View>
                                        <View style={styles.actionButtons}>
                                            <TouchableOpacity
                                                style={styles.nameContainer}
                                                onPress={() => _edit(document)}
                                            >
                                                <Text style={styles.name}>
                                                    {document.name}
                                                    {tab ===
                                                    TAB_TAGS.notasFiscais
                                                        ? ', ' +
                                                              _findNfXml(
                                                                  document,
                                                              )?.name || ''
                                                        : ''}
                                                </Text>
                                                {tab ===
                                                TAB_TAGS.notasFiscais ? null : (
                                                    <Image
                                                        style={styles.iconEdit}
                                                        source={require('../../../../../../../assets/images/ic_edit.png')}
                                                    />
                                                )}
                                            </TouchableOpacity>
                                            <>
                                                {Platform.OS === 'web' ? (
                                                    <A
                                                        href={`${
                                                            getEnvVars()
                                                                .filesUrl
                                                        }${document.url || ''}`}
                                                        target="_blank"
                                                    >
                                                        <Image
                                                            style={[
                                                                styles.iconEdit,
                                                                document.url &&
                                                                document.url.trim()
                                                                    .length
                                                                    ? null
                                                                    : styles.iconDownloadError,
                                                            ]}
                                                            source={require('../../../../../../../assets/images/ic_download.png')}
                                                        />
                                                    </A>
                                                ) : (
                                                    <TouchableOpacity
                                                        style={
                                                            styles.nameContainer
                                                        }
                                                        onPress={() =>
                                                            _viewDoc(document)
                                                        }
                                                    >
                                                        <Image
                                                            style={
                                                                styles.iconEdit
                                                            }
                                                            source={require('../../../../../../../assets/images/ic_download.png')}
                                                        />
                                                    </TouchableOpacity>
                                                )}
                                            </>
                                        </View>
                                        <Text>
                                            {datetimeToString(
                                                document.dateUpdate,
                                            )}
                                        </Text>
                                    </View>
                                ) : (
                                    <View>
                                        <Input
                                            returnKeyType="done"
                                            autoCapitalize="sentences"
                                            keyboardType="default"
                                            value={editingName}
                                            style={{
                                                marginBottom: 8,
                                                width: 350,
                                            }}
                                            onSubmitEditing={() =>
                                                _saveEditing()
                                            }
                                            onChangeText={text =>
                                                setEditingName(text)
                                            }
                                        />
                                        <Button
                                            primary
                                            label="Salvar"
                                            style={{ marginBottom: 8 }}
                                            onPress={_saveEditing}
                                        />
                                        <Button
                                            secondary
                                            label="Cancelar"
                                            onPress={_cancelEditing}
                                        />
                                    </View>
                                )}
                                <TouchableOpacity
                                    onPress={() => _remove(document)}
                                >
                                    <Image
                                        style={styles.iconRemove}
                                        source={require('../../../../../../../assets/images/ic_remove.png')}
                                    />
                                </TouchableOpacity>
                            </View>
                        ))}
                    </View>
                )}
            </ScrollView>
            <Modal isVisible={documentToRemove !== null} title="Atenção">
                <Text style={styles.modalText}>
                    Tem certeza que deseja remover o item '
                    {documentToRemove?.name}
                    '?
                </Text>
                <Button
                    primary
                    style={{ marginTop: 12, marginBottom: 10 }}
                    label="Sim"
                    onPress={_doRemove}
                />
                <Button
                    secondary
                    style={{ marginBottom: 10 }}
                    label="Cancelar"
                    onPress={() => setDocumentToRemove(null)}
                />
            </Modal>
            <Modal isVisible={showNfModal} title="Notas Fiscais">
                <Text style={styles.modalText}>Arquivo:</Text>
                <Text style={styles.modalText}>{nfZip?.name}</Text>
                <Button
                    primary
                    style={{ marginTop: 12, marginBottom: 10 }}
                    label="NF ZIP"
                    onPress={_addNfZip}
                />
                <Text style={styles.modalText}>Arquivo:</Text>
                <Text style={styles.modalText}>{nfXml?.name}</Text>
                <Button
                    primary
                    style={{ marginBottom: 20 }}
                    label="NF XML"
                    onPress={_addNfXml}
                />
                <Button
                    primary
                    style={{ marginBottom: 10 }}
                    label="Salvar"
                    onPress={_saveNf}
                />
                <Button
                    secondary
                    style={{ marginBottom: 10 }}
                    label="Cancelar"
                    onPress={() => setShowNfModal(false)}
                />
            </Modal>
            <Modal
                isVisible={showFormConstituicaoModal}
                title="Formulário de Constituição de Empresa"
            >
                <View style={styles.container}>
                    <ScrollView style={{ flex: 1 }}>
                        <View>
                            <Text style={styles.textTitle}>
                                Documentos Necessários dos SÓCIOS:
                            </Text>
                            <Text style={styles.text}>
                                Cópia do Titulo de Eleitor.
                            </Text>
                            <Text style={styles.text}>
                                Uma Cópia do RG e CPF de todos os sócios.
                            </Text>
                            <Text style={styles.text}>
                                Cópia do comprovante de endereço do
                                empresário/sócios (conta de luz, água, telefone,
                                contrato de locação, etc.).
                            </Text>
                            <Text style={styles.text}>
                                Cópia da Certidão de Casamento / de Divórcio ou
                                Òbito (no caso de empresário/sócios casado(a)).
                            </Text>
                            <Text style={styles.text}>
                                Contrato de Locação.
                            </Text>
                            <Text style={styles.text}>
                                Atestado de funcionamento dos bombeiros deferido
                                (projeto aprovado de combate a incêndio).
                            </Text>
                            <Text style={styles.text}>
                                Cópia do Espelho do IPTU.
                            </Text>
                            <Text style={styles.text}>
                                Certificado digital E-CPF A3 (cartão ou token).
                            </Text>
                            <Text style={styles.text}>
                                Certificado digital E-CNPJ (POSTERIOR).
                            </Text>
                            {/* TIPO DE SOCIEDADE */}
                            <Dropdown
                                label="Informações necessárias"
                                selectedLabel={tipoSociedade?.name}
                                modalTitle="Escolha a área de atuação"
                                items={tiposSociedade.map(
                                    i => new DropdownItem(i.id, i.name),
                                )}
                                placeholder="Escolha"
                                onItemSelected={item =>
                                    setTipoSociedade(
                                        tiposSociedade.find(
                                            i => i.id === item.id,
                                        ),
                                    )
                                }
                            />
                            {/* PORTE */}
                            <Dropdown
                                label="Porte da Empresa"
                                selectedLabel={porteEmpresa?.name}
                                modalTitle="Escolha o porte da empresa"
                                items={portesEmpresa.map(
                                    i => new DropdownItem(i.id, i.name),
                                )}
                                placeholder="Escolha"
                                onItemSelected={item =>
                                    setPorteEmpresa(
                                        portesEmpresa.find(
                                            i => i.id === item.id,
                                        ),
                                    )
                                }
                            />
                            {/* NOME FANTASIA */}
                            <Input
                                autoCapitalize="words"
                                style={{ marginBottom: 36 }}
                                label="Nome Fantasia: (obrigatório pelo menos uma opção)"
                                value={nomeFantasia}
                                onChangeText={text => setNomeFantasia(text)}
                            />
                            {/* NOME EMPRESARIAL */}
                            <Text style={styles.textTitle}>
                                Nome Empresarial 1: (preenchimento obrigatório
                                dos três campos):
                            </Text>
                            <Input
                                autoCapitalize="words"
                                style={{ marginBottom: 36 }}
                                label="1:"
                                value={nomeEmpresarial1}
                                onChangeText={text => setNomeEmpresarial1(text)}
                            />
                            <Input
                                autoCapitalize="words"
                                style={{ marginBottom: 36 }}
                                label="2:"
                                value={nomeEmpresarial2}
                                onChangeText={text => setNomeEmpresarial2(text)}
                            />
                            <Input
                                autoCapitalize="words"
                                style={{ marginBottom: 36 }}
                                label="3:"
                                value={nomeEmpresarial3}
                                onChangeText={text => setNomeEmpresarial3(text)}
                            />
                            {/* SÓCIOS ASSINAM */}
                            <Text style={styles.textTitle}>
                                Sócios assinam pela empresa: (aplicável para
                                sociedade):
                            </Text>
                            <Dropdown
                                label="Assinam:"
                                selectedLabel={sociosAssinam}
                                modalTitle="Escolha como os sócios assinam"
                                items={['em conjunto', 'separadamente'].map(
                                    (i, idx) => new DropdownItem(idx, i),
                                )}
                                placeholder="Escolha"
                                onItemSelected={item =>
                                    setSociosAssinam(
                                        ['em conjunto', 'separadamente'].find(
                                            i => i === item.label,
                                        ) || '',
                                    )
                                }
                            />
                            {/* CAPITAL SOCIAL E ADMINISTRADOR */}
                            <Input
                                autoCapitalize="words"
                                style={{ marginBottom: 36 }}
                                label="Capital Social:"
                                value={capitalSocial}
                                onChangeText={text => setCapitalSocial(text)}
                            />
                            <Input
                                autoCapitalize="words"
                                style={{ marginBottom: 36 }}
                                label="Sócio Administrador:"
                                value={socioAdministrador}
                                onChangeText={text =>
                                    setSocioAdministrador(text)
                                }
                            />
                            {/* NOME DOS SÓCIOS */}
                            <Text style={styles.textTitle}>Sócios:</Text>
                            {/* setSocios([{ nome: '', percCapical: '', email: '' }])
            setAtividadesPretendidas([{ nome: '', cnae: '' }]) */}
                            {socios.map((socio, i) => (
                                <View key={`socio-list-${i}`}>
                                    <Text style={styles.textTitle}>
                                        {i + 1}:
                                    </Text>
                                    <Input
                                        autoCapitalize="words"
                                        style={{ marginBottom: 36 }}
                                        label=""
                                        value={socio.nome}
                                        onChangeText={text => {
                                            const ss = [...socios]
                                            ss[i].nome = text
                                            setSocios(ss)
                                        }}
                                    />
                                    <Text style={styles.textTitle}>
                                        % do Capital Social:
                                    </Text>
                                    <Input
                                        autoCapitalize="words"
                                        style={{ marginBottom: 36 }}
                                        label=""
                                        value={socio.percCapical}
                                        onChangeText={text => {
                                            const ss = [...socios]
                                            ss[i].percCapical = text
                                            setSocios(ss)
                                        }}
                                    />
                                    <Text style={styles.textTitle}>
                                        E-mail:
                                    </Text>
                                    <Input
                                        autoCapitalize="words"
                                        style={{ marginBottom: 36 }}
                                        keyboardType="email-address"
                                        label=""
                                        value={socio.email}
                                        onChangeText={text => {
                                            const ss = [...socios]
                                            ss[i].email = text
                                            setSocios(ss)
                                        }}
                                    />
                                    <Text style={styles.textTitle}>
                                        Telefone:
                                    </Text>
                                    <Input
                                        style={{ marginBottom: 36 }}
                                        keyboardType="phone-pad"
                                        label=""
                                        value={socio.phone}
                                        onChangeText={text => {
                                            const ss = [...socios]
                                            ss[i].phone = text
                                            setSocios(ss)
                                        }}
                                    />
                                </View>
                            ))}
                            <Button
                                primary
                                style={{ marginBottom: 10 }}
                                label="Adicionar Sócio"
                                onPress={() => {
                                    setSocios(cur => {
                                        const ss = [...cur]
                                        ss.push({
                                            nome: '',
                                            percCapical: '',
                                            email: '',
                                            phone: '',
                                        })
                                        return ss
                                    })
                                }}
                            />
                            {/* <Input
                                autoCapitalize="words"
                                style={{ marginBottom: 36 }}
                                label="1:"
                                value={nomeSocio1}
                                onChangeText={text => setNomeSocio1(text)}
                            />
                            <Input
                                autoCapitalize="words"
                                style={{ marginBottom: 36 }}
                                label="2:"
                                value={nomeSocio2}
                                onChangeText={text => setNomeSocio2(text)}
                            />
                            <Input
                                autoCapitalize="words"
                                style={{ marginBottom: 36 }}
                                label="3:"
                                value={nomeSocio3}
                                onChangeText={text => setNomeSocio3(text)}
                            /> */}
                            {/* ATIVIDADE PRETENDIDA */}
                            <Text style={styles.textTitle}>
                                Atividades Pretendidas (Para uso da DALCON):
                            </Text>
                            {/* setSocios([{ nome: '', percCapical: '', email: '' }])
            setAtividadesPretendidas([{ nome: '', cnae: '' }]) */}
                            <View style={styles.atividadesContainer}>
                                <View style={styles.atividadesContainerItem}>
                                    {atividadesPretendidas.map(
                                        (atividadePretendida, i) => (
                                            <View
                                                key={`atividade-pretendida-list-${i}`}
                                            >
                                                <Text style={styles.textTitle}>
                                                    {i + 1}:
                                                </Text>
                                                <Input
                                                    autoCapitalize="words"
                                                    editable
                                                    style={{ marginBottom: 36 }}
                                                    label={`Descrição ${
                                                        i + 1
                                                    }:`}
                                                    value={
                                                        atividadePretendida.nome
                                                    }
                                                    onChangeText={text => {
                                                        const ss = [
                                                            ...atividadesPretendidas,
                                                        ]
                                                        ss[i].nome = text
                                                        setAtividadesPretendidas(
                                                            ss,
                                                        )
                                                    }}
                                                />
                                            </View>
                                        ),
                                    )}
                                    {/* <Input
                                        autoCapitalize="words"
                                        editable={
                                            companyMasterId === user?.companyId
                                        }
                                        style={{ marginBottom: 36 }}
                                        label="1:"
                                        value={atividadePretendida1}
                                        onChangeText={text =>
                                            setAtividadePretendida1(text)
                                        }
                                    />
                                    <Input
                                        autoCapitalize="words"
                                        editable={
                                            companyMasterId === user?.companyId
                                        }
                                        style={{ marginBottom: 36 }}
                                        label="2:"
                                        value={atividadePretendida2}
                                        onChangeText={text =>
                                            setAtividadePretendida2(text)
                                        }
                                    />
                                    <Input
                                        autoCapitalize="words"
                                        editable={
                                            companyMasterId === user?.companyId
                                        }
                                        style={{ marginBottom: 36 }}
                                        label="3:"
                                        value={atividadePretendida3}
                                        onChangeText={text =>
                                            setAtividadePretendida3(text)
                                        }
                                    />
                                    <Input
                                        autoCapitalize="words"
                                        editable={
                                            companyMasterId === user?.companyId
                                        }
                                        style={{ marginBottom: 36 }}
                                        label="4:"
                                        value={atividadePretendida4}
                                        onChangeText={text =>
                                            setAtividadePretendida4(text)
                                        }
                                    /> */}
                                </View>
                                <View style={styles.atividadesContainerItem}>
                                    {atividadesPretendidas.map(
                                        (atividadePretendida, i) => (
                                            <View
                                                key={`atividade-pretendida-list-cnae=${i}`}
                                            >
                                                <Text style={styles.textTitle}>
                                                    {' '}
                                                </Text>
                                                <Input
                                                    autoCapitalize="words"
                                                    editable={
                                                        companyMasterId ===
                                                        user?.companyId
                                                    }
                                                    style={{ marginBottom: 36 }}
                                                    label={`CNAE ${i + 1}:`}
                                                    value={
                                                        atividadePretendida.cnae
                                                    }
                                                    onChangeText={text => {
                                                        const ss = [
                                                            ...atividadesPretendidas,
                                                        ]
                                                        ss[i].cnae = text
                                                        setAtividadesPretendidas(
                                                            ss,
                                                        )
                                                    }}
                                                />
                                            </View>
                                        ),
                                    )}
                                    {/* <Input
                                        autoCapitalize="words"
                                        editable={
                                            companyMasterId === user?.companyId
                                        }
                                        style={{ marginBottom: 36 }}
                                        label="CNAE 1:"
                                        value={atividadePretendidaCNAE1}
                                        onChangeText={text =>
                                            setAtividadePretendidaCNAE1(text)
                                        }
                                    />
                                    <Input
                                        autoCapitalize="words"
                                        editable={
                                            companyMasterId === user?.companyId
                                        }
                                        style={{ marginBottom: 36 }}
                                        label="CNAE 2:"
                                        value={atividadePretendidaCNAE2}
                                        onChangeText={text =>
                                            setAtividadePretendidaCNAE2(text)
                                        }
                                    />
                                    <Input
                                        autoCapitalize="words"
                                        editable={
                                            companyMasterId === user?.companyId
                                        }
                                        style={{ marginBottom: 36 }}
                                        label="CNAE 3:"
                                        value={atividadePretendidaCNAE3}
                                        onChangeText={text =>
                                            setAtividadePretendidaCNAE3(text)
                                        }
                                    />
                                    <Input
                                        autoCapitalize="words"
                                        editable={
                                            companyMasterId === user?.companyId
                                        }
                                        style={{ marginBottom: 36 }}
                                        label="CNAE 4:"
                                        value={atividadePretendidaCNAE4}
                                        onChangeText={text =>
                                            setAtividadePretendidaCNAE4(text)
                                        }
                                    /> */}
                                </View>
                            </View>
                            <Button
                                primary
                                style={{ marginBottom: 10 }}
                                label="Adicionar Atividade"
                                onPress={() => {
                                    setAtividadesPretendidas(cur => {
                                        const ss = [...cur]
                                        ss.push({ nome: '', cnae: '' })
                                        return ss
                                    })
                                }}
                            />

                            {/* SITUTAÇÃO DOS SÓCIOS */}
                            <Text style={styles.textTitle}>
                                Situação dos sócios:
                            </Text>
                            <Dropdown
                                label="Menor de 18 anos"
                                selectedLabel={menorDe18Anos ? 'Sim' : 'Não'}
                                modalTitle="Menor de 18 anos"
                                items={['Sim', 'Não'].map(
                                    (i, idx) => new DropdownItem(idx, i),
                                )}
                                placeholder="Escolha"
                                onItemSelected={item =>
                                    setMenorDe18Anos(
                                        (['Sim', 'Não'].find(
                                            i => i === item.label,
                                        ) || '') === 'Sim',
                                    )
                                }
                            />
                            <Dropdown
                                label="Participa (ou) como sócio em outra empresa"
                                selectedLabel={
                                    participaComoSocioEmOutraEmpresa
                                        ? 'Sim'
                                        : 'Não'
                                }
                                modalTitle="Participa (ou) como sócio em outra empresa"
                                items={['Sim', 'Não'].map(
                                    (i, idx) => new DropdownItem(idx, i),
                                )}
                                placeholder="Escolha"
                                onItemSelected={item =>
                                    setParticipaComoSocioEmOutraEmpresa(
                                        (['Sim', 'Não'].find(
                                            i => i === item.label,
                                        ) || '') === 'Sim',
                                    )
                                }
                            />
                            <Dropdown
                                label="Estrangeiro"
                                selectedLabel={extrangeiro ? 'Sim' : 'Não'}
                                modalTitle="Estrangeiro"
                                items={['Sim', 'Não'].map(
                                    (i, idx) => new DropdownItem(idx, i),
                                )}
                                placeholder="Escolha"
                                onItemSelected={item =>
                                    setExtrangeiro(
                                        (['Sim', 'Não'].find(
                                            i => i === item.label,
                                        ) || '') === 'Sim',
                                    )
                                }
                            />
                            <Dropdown
                                label="Inscrição estadual cancelada"
                                selectedLabel={
                                    inscricaoEstadualCancelada ? 'Sim' : 'Não'
                                }
                                modalTitle="Inscrição estadual cancelada"
                                items={['Sim', 'Não'].map(
                                    (i, idx) => new DropdownItem(idx, i),
                                )}
                                placeholder="Escolha"
                                onItemSelected={item =>
                                    setInscricaoEstadualCancelada(
                                        (['Sim', 'Não'].find(
                                            i => i === item.label,
                                        ) || '') === 'Sim',
                                    )
                                }
                            />
                            <Dropdown
                                label="Débitos estaduais"
                                selectedLabel={debitosEstaduais ? 'Sim' : 'Não'}
                                modalTitle="Débitos estaduais"
                                items={['Sim', 'Não'].map(
                                    (i, idx) => new DropdownItem(idx, i),
                                )}
                                placeholder="Escolha"
                                onItemSelected={item =>
                                    setDebitosEstaduais(
                                        (['Sim', 'Não'].find(
                                            i => i === item.label,
                                        ) || '') === 'Sim',
                                    )
                                }
                            />
                            <Dropdown
                                label="Aposentado por invalidez"
                                selectedLabel={
                                    aposentadoPorInvalidez ? 'Sim' : 'Não'
                                }
                                modalTitle="Aposentado por invalidez"
                                items={['Sim', 'Não'].map(
                                    (i, idx) => new DropdownItem(idx, i),
                                )}
                                placeholder="Escolha"
                                onItemSelected={item =>
                                    setAposentadoPorInvalidez(
                                        (['Sim', 'Não'].find(
                                            i => i === item.label,
                                        ) || '') === 'Sim',
                                    )
                                }
                            />
                            {/* CAPITAL DOS SÓCIOS */}
                            {/* <Text style={styles.textTitle}>
                                % do Capital Social dos Sócios:
                            </Text>
                            <Input
                                autoCapitalize="words"
                                style={{ marginBottom: 36 }}
                                label="1:"
                                value={percCapicalSocialSocio1}
                                onChangeText={text =>
                                    setPercCapicalSocialSocio1(text)
                                }
                            />
                            <Input
                                autoCapitalize="words"
                                style={{ marginBottom: 36 }}
                                label="2:"
                                value={percCapicalSocialSocio2}
                                onChangeText={text =>
                                    setPercCapicalSocialSocio2(text)
                                }
                            />
                            <Input
                                autoCapitalize="words"
                                style={{ marginBottom: 36 }}
                                label="3:"
                                value={percCapicalSocialSocio3}
                                onChangeText={text =>
                                    setPercCapicalSocialSocio3(text)
                                }
                            /> */}
                            {/* EMAIL DOS SÓCIOS */}
                            {/* <Text style={styles.textTitle}>
                                E-mail dos Sócios:
                            </Text>
                            <Input
                                autoCapitalize="words"
                                style={{ marginBottom: 36 }}
                                label="1:"
                                value={emailSocio1}
                                onChangeText={text => setEmailSocio1(text)}
                            />
                            <Input
                                autoCapitalize="words"
                                style={{ marginBottom: 36 }}
                                label="2:"
                                value={emailSocio2}
                                onChangeText={text => setEmailSocio2(text)}
                            />
                            <Input
                                autoCapitalize="words"
                                style={{ marginBottom: 36 }}
                                label="3:"
                                value={emailSocio3}
                                onChangeText={text => setEmailSocio3(text)}
                            /> */}
                            {/* TELEFONE DA EMPRESA */}
                            <Text style={styles.textTitle}>
                                Telefone da empresa:
                            </Text>
                            <Input
                                autoCapitalize="words"
                                style={{ marginBottom: 36 }}
                                label="1:"
                                value={telefoneEmpresa1}
                                onChangeText={text => setTelefoneEmpresa1(text)}
                            />
                            <Input
                                autoCapitalize="words"
                                style={{ marginBottom: 36 }}
                                label="2:"
                                value={telefoneEmpresa2}
                                onChangeText={text => setTelefoneEmpresa2(text)}
                            />
                            <Input
                                autoCapitalize="words"
                                style={{ marginBottom: 36 }}
                                label="3:"
                                value={telefoneEmpresa3}
                                onChangeText={text => setTelefoneEmpresa3(text)}
                            />
                            {/* VALOR DOS HONORÁRIOS */}
                            <Text style={styles.textTitle}>
                                Valor dos honorários (Para uso da DALCON):
                            </Text>
                            <Input
                                autoCapitalize="words"
                                editable={companyMasterId === user?.companyId}
                                style={{ marginBottom: 36 }}
                                label="Honorários"
                                value={valorHonorarios}
                                onChangeText={text => setValorHonorarios(text)}
                            />
                            {/* VALOR DA CONSTITUIÇÃO */}
                            <Text style={styles.textTitle}>
                                Valor da constituição (Para uso da DALCON):
                            </Text>
                            <Input
                                autoCapitalize="words"
                                editable={companyMasterId === user?.companyId}
                                style={{ marginBottom: 36 }}
                                label="Valor"
                                value={valorConstituicao}
                                onChangeText={text =>
                                    setValorConstituicao(text)
                                }
                            />
                            {/* QUANTIDADE DE FUNCIONÁRIOS */}
                            <Text style={styles.textTitle}>
                                Quantidade de funcionários:
                            </Text>
                            <Input
                                autoCapitalize="words"
                                style={{ marginBottom: 36 }}
                                label="Quantidade"
                                value={quantidadeFuncionarios}
                                onChangeText={text =>
                                    setQuantidadeFuncionarios(text)
                                }
                            />
                            <Button
                                primary
                                style={{ marginBottom: 10 }}
                                label="Salvar"
                                onPress={_saveFormConstituicao}
                            />
                            <Button
                                secondary
                                style={{ marginBottom: 10 }}
                                label="Cancelar"
                                onPress={() =>
                                    setShowFormConstituicaoModal(false)
                                }
                            />
                        </View>
                    </ScrollView>
                </View>
            </Modal>
            <Spinner visible={refreshing} overlayColor={'rgba(0, 0, 0, 0.5)'} />
            <FabButton
                icon={require('../../../../../../../assets/images/ic_plus.png')}
                onPress={_add}
            />
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        maxHeight: '100%',
        padding: 16,
    },
    textTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    text: {
        fontSize: 14,
        marginBottom: 10,
    },
    docItemContainer: {
        paddingTop: 16,
        paddingBottom: 16,
        borderBottomColor: '#bbb',
        borderBottomWidth: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    nameContainer: {
        flexDirection: 'row',
    },
    actionButtons: {
        flexDirection: 'row',
    },
    name: {
        color: colors.secondary,
        fontSize: 18,
        fontWeight: 'bold',
    },
    iconRemove: {
        width: iconSizeNormal,
        height: iconSizeNormal,
        tintColor: '#f55',
    },
    iconEdit: {
        width: iconSizeSmall,
        height: iconSizeSmall,
        marginLeft: 8,
    },
    iconDownloadError: {
        width: iconSizeSmall,
        height: iconSizeSmall,
        marginLeft: 8,
        tintColor: '#f55',
    },
    modalText: {
        fontSize: 16,
        marginTop: 8,
        marginBottom: 8,
    },
    atividadesContainer: {
        flexDirection: 'row',
        gap: 8,
    },
    atividadesContainerItem: {
        flexGrow: 1,
    },
    foldersContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        height: '100%',
    },
    folderBackContainer: {
        flexDirection: 'row',
        marginBottom: 24,
    },
    iconFolder: {
        width: 192,
        height: 192,
        tintColor: colors.accent,
    },
    iconFolderOpen: {
        width: 96,
        height: 96,
    },
    folderName: {
        textAlign: 'center',
        fontWeight: 'bold',
    },
})
