import React, { useEffect, useState } from 'react'
import { ScrollView } from 'react-native'
import Spinner from '../../../../../components/Spinner'
import User from '../../../../../models/user'
import styles from './styles'
import UserFormDetail from './UserFormDetail'
import UserFormEditing from './UserFormEditing'

interface Props {
    originalEmployee?: User
    onBack: () => void
}

const EmployeeForm: React.FC<Props> = ({ originalEmployee, onBack }) => {
    const [editing, setEditing] = useState(originalEmployee === undefined)

    const [user, setUser] = useState<User | undefined>()

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setUser(originalEmployee)
    }, [originalEmployee])

    const _setLoading = (val: boolean) => {
        setLoading(val)
    }

    const _toggleEdit = () => {
        if (user) {
            setEditing(!editing)
        }
    }

    const _onUserUpdated = async (u: User) => {
        setUser(undefined)
        setUser(u)
    }

    const _companyContent = () => {
        if (editing) {
            return (
                <UserFormEditing
                    user={user}
                    onUserUpdated={_onUserUpdated}
                    onBack={onBack}
                    setLoading={_setLoading}
                    setEditing={val => setEditing(val)}
                />
            )
        } else {
            return (
                <>
                    <UserFormDetail
                        user={user}
                        onBack={onBack}
                        toggleEdit={_toggleEdit}
                    />
                </>
            )
        }
    }

    if (originalEmployee) {
        if (!user) {
            return null
        }
    }

    return (
        <>
            <ScrollView style={styles.container}>
                <>{_companyContent()}</>
            </ScrollView>

            <Spinner visible={loading} overlayColor={'rgba(0, 0, 0, 0.5)'} />
        </>
    )
}

export default EmployeeForm
