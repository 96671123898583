import React from 'react'
import { Text, View } from 'react-native'
import styles from './styles'

interface Props {
    message: string
    show: boolean
    success?: boolean
    error?: boolean
    style?: any
    onTimeout?: () => void
}

const AlertInline = ({
    message,
    show,
    success,
    error,
    style,
    onTimeout,
}: Props) => {
    if (!show) {
        return null
    } else if (onTimeout) {
        setTimeout(() => {
            onTimeout()
        }, 2500)
    }
    return (
        <View
            style={[
                styles.container,
                success
                    ? styles.containerSuccess
                    : error
                    ? styles.containerError
                    : styles.containerNone,
                style,
            ]}
        >
            <Text
                style={[
                    styles.text,
                    success
                        ? styles.textSuccess
                        : error
                        ? styles.textError
                        : styles.textNone,
                ]}
            >
                {message}
            </Text>
        </View>
    )
}

export default AlertInline
