import { StyleSheet } from 'react-native'
import colors from './colors'
import metrics from './metrics'

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: metrics.padding,
    },
    label: {
        fontSize: 14,
        color: colors.secondary,
    },
    labelBold: {
        fontSize: 14,
        fontWeight: 'bold',
        color: colors.secondary,
    },
    labelLightGrey: {
        color: '#aaa',
    },
    marginTopSmall: {
        marginTop: 4,
    },
    marginTopNormal: {
        marginTop: 8,
    },
    marginTopLarge: {
        marginTop: 12,
    },
    marginTopLargest: {
        marginTop: 24,
    },
    marginBottomSmall: {
        marginBottom: 4,
    },
    marginBottomNormal: {
        marginBottom: 8,
    },
    marginBottomLarge: {
        marginBottom: 12,
    },
    marginBottomLargest: {
        marginBottom: 24,
    },
    formTextValue: {
        color: '#333',
        marginBottom: 10,
        fontSize: 16,
    },
    formLabelValue: {
        color: colors.primary,
        fontSize: 14,
    },
    buttonMaxWidthWeb: {
        width: 280,
    },
})

export default styles
