import Company from './company'

export class ImpostoRendaStatus {
    public id: number
    public name: string

    constructor(json: any) {
        this.id = json.id
        this.name = json.name
    }
}

class ImpostoRenda {
    public id: number
    public login: string
    public senha: string
    public codigoAcesso: string
    public dataNascimento: string
    public cep: string
    public nomeCompleto: string
    public impostoDeRendaStatusId: number

    public status?: ImpostoRendaStatus

    public company?: Company

    constructor(json: any) {
        this.id = json.id
        this.login = json.login
        this.senha = json.senha
        this.codigoAcesso = json.codigoAcesso
        this.dataNascimento = json.dataNascimento
        this.cep = json.cep
        this.nomeCompleto = json.nomeCompleto
        this.impostoDeRendaStatusId = json.impostoDeRendaStatusId
        if (json.status) {
            this.status = new ImpostoRendaStatus(json.status)
        }
        if (json.company) {
            this.company = new Company(json.company)
        }
    }
}

export default ImpostoRenda
