import { Dimensions, StyleSheet } from 'react-native'
import colors from '../../styles/colors'
import metrics, { logoRatio } from '../../styles/metrics'

const logoHeight = 40
const paddingLeftRight = 8

const windowWidth = Dimensions.get('window').width

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        paddingTop: metrics.headerPadding,
        paddingLeft: windowWidth < 700 ? paddingLeftRight : 20,
        paddingRight: paddingLeftRight,
        paddingBottom: 8,
        backgroundColor: colors.primary,
        // justifyContent: windowWidth < 720 ? 'space-between' : 'flex-start',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    icon: {
        width: 32,
        height: 32,
        tintColor: '#fff',
    },
    logo: {
        width: logoHeight * logoRatio,
        height: logoHeight,
    },

    timerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4,
    },
    iconTimer: {
        width: 28,
        height: 28,
        tintColor: '#fff',
    },
    timer: {
        color: 'white',
        marginRight: 4,
        width: 90,
    },
    timerNoActivity: {
        width: 'auto',
    },
})

export default styles
