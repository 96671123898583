import React, { useEffect, useState } from 'react'
import {
    NativeScrollEvent,
    NativeSyntheticEvent,
    RefreshControl,
    ScrollView,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import Button from '../../../../components/Button'
import ContentHeader from '../../../../components/ContentHeader'
import Dropdown, { DropdownItem } from '../../../../components/Dropdown'
import FabButton from '../../../../components/FabButton'
import IconButton from '../../../../components/IconButton'
import Modal from '../../../../components/Modal'
import NoItems from '../../../../components/NoItems'
import ImpostoRenda, {
    ImpostoRendaStatus,
} from '../../../../models/imposto-renda'
import User from '../../../../models/user'
import {
    getImpostoRendaList,
    removeImpostoRenda,
} from '../../../../services/api'
import Settings from '../../../../services/settings'
import metrics from '../../../../styles/metrics'
import ImpostoRendaForm from './ImpostoRendaForm'
import ImpostoRendaItem from './ImpostoRendaItem'

export interface ImpostoRendaFragmentProps {}

const ImpostoRendaFragment = () => {
    const [editing, setEditing] = useState(false)
    const [impostoRendaEditing, setImpostoRendaEditing] = useState<
        ImpostoRenda | undefined
    >()
    const [impostoRendaList, setImpostoRendaList] = useState<ImpostoRenda[]>([])
    const [statusList, setStatusList] = useState<ImpostoRendaStatus[]>([])
    const [loading, setLoading] = useState(false)
    const [refreshing, setRefreshing] = useState(false)
    const [impostoRendaRemoving, setImpostoRendaRemoving] = useState<
        ImpostoRenda | undefined
    >(undefined)

    const [filterStatusId, setFilterStatusId] = useState<number>(0)
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [statusFilter, setStatusFilter] = useState<
        ImpostoRendaStatus | undefined
    >()

    const [user, setUser] = useState<User | null>()

    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        ;(async () => {
            const _user = await Settings.getUser()
            setUser(_user)
        })()
    }, [])

    const _getImpostoRenda = async () => {
        setLoading(true)
        getImpostoRendaList()
            .then(result => {
                if (result) {
                    const _impostoRendaList: ImpostoRenda[] = [] // page === 0 ? [] : [...users]
                    for (const impostoRenda of result.impostoRendaList) {
                        if (
                            _impostoRendaList.find(
                                i => i.id === impostoRenda.id,
                            ) === undefined
                        ) {
                            _impostoRendaList.push(
                                new ImpostoRenda(impostoRenda),
                            )
                        }
                    }
                    setImpostoRendaList(_impostoRendaList)
                    setStatusList(result.statusList || [])
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        _getImpostoRenda()
    }, [])

    const _onRefresh = () => {
        // setPage(0)
        setRefreshing(true)
        setImpostoRendaList([])
        _getImpostoRenda()
    }

    const _closeEditing = () => {
        setImpostoRendaEditing(undefined)
        setEditing(false)
        // setPage(0)
        setImpostoRendaList([])
        _getImpostoRenda()
    }

    const _add = () => {
        setImpostoRendaEditing(undefined)
        setEditing(true)
    }

    const _edit = (s: ImpostoRenda) => {
        setImpostoRendaEditing(s)
        setEditing(true)
    }

    const _fullRefresh = () => {
        setImpostoRendaEditing(undefined)
        setEditing(false)
        setImpostoRendaList([])
        _getImpostoRenda()
    }

    const _remove = (s: ImpostoRenda) => {
        setImpostoRendaRemoving(s)
    }

    const _doRemove = async () => {
        const id = impostoRendaRemoving?.id || 0
        try {
            await removeImpostoRenda(id)
        } catch (e) {
            console.log('ImpostoRendaFragment _doRemove exception: ', e)
        }
        setImpostoRendaRemoving(undefined)
        _fullRefresh()
    }

    const _isCloseToBottom = (
        event: NativeSyntheticEvent<NativeScrollEvent>,
    ) => {
        const { layoutMeasurement, contentOffset, contentSize } =
            event.nativeEvent

        const paddingToBottom = 20
        const close =
            layoutMeasurement.height + contentOffset.y >=
            contentSize.height - paddingToBottom
        if (close) {
            //     if (!updating && hasMoreItems) {
            //         dealsGet(false, false)
            //     }
        }
    }

    const _content = () => {
        let items =
            filterStatusId === 0
                ? impostoRendaList
                : impostoRendaList.filter(
                      i => i.impostoDeRendaStatusId === filterStatusId,
                  )
        if (searchTerm.trim().length > 0) {
            items = items.filter(i => {
                if (i.company) {
                    // console.log(i.company.name, searchTerm)
                    return (
                        i.company.name
                            .toLowerCase()
                            .indexOf(searchTerm.toLowerCase()) >= 0
                    )
                } else {
                    return true
                }
            })
        }
        return editing ? (
            <ImpostoRendaForm
                originalImpostoRenda={impostoRendaEditing}
                statusList={statusList}
                onBack={_closeEditing}
            />
        ) : (
            <>
                {items.map(impostoRenda => (
                    <ImpostoRendaItem
                        key={impostoRenda.id}
                        impostoRenda={impostoRenda}
                        onSelected={s => _edit(s)}
                        onRemove={s => _remove(s)}
                        user={user}
                    />
                ))}
            </>
        )
    }

    if (!user) {
        return null
    }

    return (
        <>
            <ScrollView
                style={styles.container}
                onScroll={_isCloseToBottom}
                scrollEventThrottle={400}
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={_onRefresh}
                    />
                }
            >
                <View style={styles.headerC}>
                    <ContentHeader
                        title="Imposto de Renda"
                        style={styles.header}
                        styleTitle={{ marginBottom: 14 }}
                        searchBar={!editing}
                        onSearch={setSearchTerm}
                    />
                    {/* FILTER */}
                    <IconButton
                        styles={styles.headerFilterButton}
                        source={require('../../../../../assets/images/ic_filter.png')}
                        showBadge={filterStatusId > 0}
                        onPress={() => setShowFilterModal(true)}
                    />
                    {_content()}
                </View>
            </ScrollView>
            {impostoRendaList.length === 0 && !loading && !editing ? (
                <NoItems />
            ) : null}
            {!editing ? (
                <FabButton
                    icon={require('../../../../../assets/images/ic_plus.png')}
                    onPress={_add}
                />
            ) : null}
            <Modal
                isVisible={impostoRendaRemoving !== undefined}
                title="Atenção"
            >
                <Text style={styles.modalText}>
                    Tem certeza que deseja remover o imposto de renda de '
                    {impostoRendaRemoving?.company?.name}
                    '?
                </Text>
                <Button
                    primary
                    style={{ marginTop: 12, marginBottom: 10 }}
                    label="Sim"
                    onPress={_doRemove}
                />
                <Button
                    secondary
                    style={{ marginBottom: 10 }}
                    label="Cancelar"
                    onPress={() => setImpostoRendaRemoving(undefined)}
                />
            </Modal>
            <Modal isVisible={showFilterModal} title="Filtrar">
                <Dropdown
                    label="Filtrar por"
                    selectedLabel={statusFilter?.name || ''}
                    modalTitle="Escolha o filtro"
                    items={statusList.map(s => new DropdownItem(s.id, s.name))}
                    placeholder="Escolha o filtro"
                    onItemSelected={item =>
                        setStatusFilter(statusList.find(s => s.id === item.id))
                    }
                />
                <Button
                    primary
                    style={{ marginTop: 12, marginBottom: 10 }}
                    label="OK"
                    onPress={() => {
                        setFilterStatusId(statusFilter?.id || 0)
                        setShowFilterModal(false)
                    }}
                />
                <Button
                    secondary
                    style={{ marginBottom: 10 }}
                    label="Cancelar"
                    onPress={() => setShowFilterModal(false)}
                />
                <Button
                    secondary
                    style={{ marginBottom: 10 }}
                    label="Limpar Filtro"
                    onPress={() => {
                        setFilterStatusId(0)
                        setShowFilterModal(false)
                    }}
                />
            </Modal>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: metrics.padding,
        paddingLeft: metrics.padding,
        paddingRight: metrics.padding,
        paddingBottom: 50,
    },
    headerC: {
        position: 'relative',
        marginTop: 8,
    },
    header: {
        paddingBottom: 8,
    },
    modalText: {
        fontSize: 16,
        marginTop: 8,
        marginBottom: 8,
    },
    headerFilterButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        marginTop: 0,
        zIndex: 100,
    },
})

export default ImpostoRendaFragment
