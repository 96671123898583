import { AlertContext } from '../contexts/alert-context'
import { useContext } from 'react'

export function useAlert() {
    const { setTitle, setMessage, setButtonTitle } = useContext(AlertContext)
    function show(title: string, message: string, buttonTitle: string) {
        setTitle(title)
        setMessage(message)
        setButtonTitle(buttonTitle)
    }
    function dismiss() {
        setTitle(null)
        setMessage(null)
        setButtonTitle(null)
    }
    return { show, dismiss }
}
