import React, { createContext, useState } from 'react'

type MenuLayoutContextData = {
    height: number
    setHeight: React.Dispatch<React.SetStateAction<number>>
}

type MenuLayoutContextProps = {
    children: React.ReactNode
}

export const MenuLayoutContext = createContext({} as MenuLayoutContextData)

export const MenuLayoutContextProvider = ({
    children,
}: MenuLayoutContextProps) => {
    const [height, setHeight] = useState(0)

    const menuLayoutContext: MenuLayoutContextData = {
        height,
        setHeight,
    }

    return (
        <MenuLayoutContext.Provider value={menuLayoutContext}>
            {children}
        </MenuLayoutContext.Provider>
    )
}
