import React, { useEffect, useState } from 'react'
import { LayoutChangeEvent, Text, TouchableOpacity, View } from 'react-native'

import Constants from 'expo-constants'
import User from '../../models/user'
import { Fragment } from '../../screens/Home/MainScreen/ScreenConsts'
import Settings from '../../services/settings'
import { SystemRole } from '../../shared/consts'
import { isSmallScreen } from '../../styles/metrics'
import MenuItem from './MenuItem'
import styles from './styles'

interface Props {
    show?: boolean
    activeFragment: number
    onHide: () => void
    onItemSelected: (fragment: number) => void
    onLogout: () => void
    onLayout?: ((event: LayoutChangeEvent) => void) | undefined
}

const Menu: React.FC<Props> = ({
    show,
    activeFragment,
    onHide,
    onItemSelected,
    onLogout,
    onLayout,
}) => {
    const [privilege, setPrivilege] = useState(0)
    const [isMasterCompany, setIsMasterCompany] = useState(true)
    const [user, setUser] = useState<User | null>()

    useEffect(() => {
        ;(async () => {
            const _user = await Settings.getUser()
            setUser(_user)
            const companyMasterId = await Settings.getCompanyMasterId()
            setPrivilege(_user?.systemRole || 0)
            setIsMasterCompany(companyMasterId === _user?.companyId)
            // setIsMasterCompany(false)
        })()
    }, [])

    const _onItemSelected = (fragment: number) => {
        onItemSelected(fragment)
        onHide()
    }

    const _onLogout = () => {
        onHide()
        onLogout()
    }

    if (!user) {
        return <View />
    }

    return (
        <>
            {isSmallScreen && show ? (
                <TouchableOpacity
                    style={styles.menuContainer}
                    onPress={onHide}
                />
            ) : null}
            {show || !isSmallScreen ? (
                <View
                    style={[styles.container, styles.menuContainerAdaptative]}
                >
                    <View onLayout={onLayout}>
                        <View style={styles.companyContainer}>
                            <View style={styles.companyContainerIcon} />
                            <View>
                                <Text style={styles.companyData}>
                                    {user?.company?.name}
                                </Text>
                                <Text style={styles.companyData}>
                                    {user?.company?.cnpj}
                                </Text>
                            </View>
                        </View>

                        <MenuItem
                            icon={require('../../../assets/images/ic_home.png')}
                            active={activeFragment === Fragment.Main}
                            onPress={() => _onItemSelected(Fragment.Main)}
                        >
                            Solicitações
                        </MenuItem>
                        {!isMasterCompany && (
                            <MenuItem
                                icon={require('../../../assets/images/ic_statements.png')}
                                active={activeFragment === Fragment.Documents}
                                onPress={() =>
                                    _onItemSelected(Fragment.Documents)
                                }
                            >
                                Documentos
                            </MenuItem>
                        )}
                        {SystemRole.isSuperAdmin(privilege) ? (
                            <MenuItem
                                icon={require('../../../assets/images/ic_report.png')}
                                active={
                                    activeFragment === Fragment.ActivityReport
                                }
                                onPress={() =>
                                    _onItemSelected(Fragment.ActivityReport)
                                }
                            >
                                Relatório Atividades
                            </MenuItem>
                        ) : null}
                        {SystemRole.isSuperAdminBase(privilege) ? (
                            <MenuItem
                                icon={require('../../../assets/images/ic_companies.png')}
                                active={activeFragment === Fragment.Companies}
                                onPress={() =>
                                    _onItemSelected(Fragment.Companies)
                                }
                            >
                                Empresas
                            </MenuItem>
                        ) : null}
                        {isMasterCompany && (
                            <MenuItem
                                icon={require('../../../assets/images/ic_statements.png')}
                                active={
                                    activeFragment === Fragment.ImpostoRenda
                                }
                                onPress={() =>
                                    _onItemSelected(Fragment.ImpostoRenda)
                                }
                            >
                                Imposto de Renda
                            </MenuItem>
                        )}
                        {SystemRole.isSuperAdminBase(privilege) ? (
                            <MenuItem
                                icon={require('../../../assets/images/ic_statements.png')}
                                active={activeFragment === Fragment.Statements}
                                onPress={() =>
                                    _onItemSelected(Fragment.Statements)
                                }
                            >
                                Declarações
                            </MenuItem>
                        ) : null}
                        {SystemRole.isSuperAdmin(privilege) ? (
                            <MenuItem
                                icon={require('../../../assets/images/ic_companies.png')}
                                active={activeFragment === Fragment.RegimeTypes}
                                onPress={() =>
                                    _onItemSelected(Fragment.RegimeTypes)
                                }
                            >
                                Tipos de Regime (Tributário, etc. E Checklist)
                            </MenuItem>
                        ) : null}
                        {SystemRole.isSuperAdminBase(privilege) ? (
                            <MenuItem
                                icon={require('../../../assets/images/ic_service_types.png')}
                                active={
                                    activeFragment === Fragment.ServiceTypes
                                }
                                onPress={() =>
                                    _onItemSelected(Fragment.ServiceTypes)
                                }
                            >
                                Tipos de Serviço (Abertura, Alteração, ...)
                            </MenuItem>
                        ) : null}
                        {SystemRole.isSuperAdmin(privilege) ? (
                            <>
                                <MenuItem
                                    icon={require('../../../assets/images/ic_companies.png')}
                                    active={
                                        activeFragment === Fragment.CompanyTypes
                                    }
                                    onPress={() =>
                                        _onItemSelected(Fragment.CompanyTypes)
                                    }
                                >
                                    Tipos de Empresa (Indústria, Serviço,
                                    Comércio)
                                </MenuItem>
                            </>
                        ) : null}

                        <MenuItem
                            icon={require('../../../assets/images/ic_employees.png')}
                            active={activeFragment === Fragment.Employees}
                            onPress={() => _onItemSelected(Fragment.Employees)}
                        >
                            Funcionários
                        </MenuItem>
                        {isMasterCompany && (
                            <MenuItem
                                icon={require('../../../assets/images/ic_employees.png')}
                                active={
                                    activeFragment === Fragment.Responsibles
                                }
                                onPress={() =>
                                    _onItemSelected(Fragment.Responsibles)
                                }
                            >
                                Responsáveis
                            </MenuItem>
                        )}
                        {SystemRole.isSuperAdmin(privilege) ? (
                            <MenuItem
                                icon={require('../../../assets/images/ic_work.png')}
                                active={activeFragment === Fragment.Roles}
                                onPress={() => _onItemSelected(Fragment.Roles)}
                            >
                                Áreas de Atuação
                            </MenuItem>
                        ) : null}

                        <MenuItem
                            icon={require('../../../assets/images/ic_profile.png')}
                            active={activeFragment === Fragment.Profile}
                            onPress={() => _onItemSelected(Fragment.Profile)}
                        >
                            Meu Perfil
                        </MenuItem>
                        <MenuItem
                            icon={require('../../../assets/images/ic_exit.png')}
                            onPress={_onLogout}
                        >
                            Sair
                        </MenuItem>
                        <Text style={styles.version}>
                            Versão:{' '}
                            {Constants.manifest?.version ||
                                Constants.nativeAppVersion ||
                                '-'}
                        </Text>
                    </View>
                </View>
            ) : null}
        </>
    )
}

export default Menu
