import React, { useEffect, useState } from 'react'
import {
    NativeScrollEvent,
    NativeSyntheticEvent,
    RefreshControl,
    ScrollView,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import Button from '../../../../components/Button'
import ContentHeader from '../../../../components/ContentHeader'
import FabButton from '../../../../components/FabButton'
import Modal from '../../../../components/Modal'
import NoItems from '../../../../components/NoItems'
import ServiceType from '../../../../models/service-type'
import { getServiceTypes, removeServiceType } from '../../../../services/api'
import Settings from '../../../../services/settings'
import metrics from '../../../../styles/metrics'
import ServiceTypeForm from './ServiceTypeForm'
import ServiceTypeItem from './ServiceTypeItem'

export interface ServiceTypeFragmentProps {}

const ServiceTypeFragment = () => {
    const [editing, setEditing] = useState(false)
    const [serviceTypeEditing, setServiceTypeEditing] = useState<
        ServiceType | undefined
    >()
    const [serviceTypes, setServiceTypes] = useState<ServiceType[]>([])
    const [loading, setLoading] = useState(false)
    const [refreshing, setRefreshing] = useState(false)
    const [serviceTypeRemoving, setServiceTypeRemoving] = useState<
        ServiceType | undefined
    >(undefined)

    const [filter, setFilter] = useState('')

    const [privilege, setPrivilege] = useState(-1)

    useEffect(() => {
        ;(async () => {
            const _user = await Settings.getUser()
            setPrivilege(_user?.systemRole || 0)
        })()
    }, [])

    const _getServiceTypes = async () => {
        setLoading(true)
        getServiceTypes()
            .then(result => {
                if (result && Array.isArray(result)) {
                    const _serviceTypes = [] // page === 0 ? [] : [...users]
                    for (const serviceType of result) {
                        if (
                            _serviceTypes.find(i => i.id === serviceType.id) ===
                            undefined
                        ) {
                            _serviceTypes.push(new ServiceType(serviceType))
                        }
                    }
                    setServiceTypes(_serviceTypes)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        _getServiceTypes()
    }, [])

    const _onRefresh = () => {
        // setPage(0)
        setRefreshing(true)
        setServiceTypes([])
        _getServiceTypes()
    }

    const _closeEditing = () => {
        setServiceTypeEditing(undefined)
        setEditing(false)
        // setPage(0)
        setServiceTypes([])
        _getServiceTypes()
    }

    const _add = () => {
        setServiceTypeEditing(undefined)
        setEditing(true)
    }

    const _edit = (s: ServiceType) => {
        setServiceTypeEditing(s)
        setEditing(true)
    }

    const _fullRefresh = () => {
        setServiceTypeEditing(undefined)
        setEditing(false)
        setServiceTypes([])
        _getServiceTypes()
    }

    const _remove = (s: ServiceType) => {
        setServiceTypeRemoving(s)
    }

    const _doRemove = async () => {
        const id = serviceTypeRemoving?.id || 0
        try {
            await removeServiceType(id)
        } catch (e) {
            console.log('ServiceTypeFragment _doRemove exception: ', e)
        }
        setServiceTypeRemoving(undefined)
        _fullRefresh()
    }

    const _isCloseToBottom = (
        event: NativeSyntheticEvent<NativeScrollEvent>,
    ) => {
        const { layoutMeasurement, contentOffset, contentSize } =
            event.nativeEvent

        const paddingToBottom = 20
        const close =
            layoutMeasurement.height + contentOffset.y >=
            contentSize.height - paddingToBottom
        if (close) {
            //     if (!updating && hasMoreItems) {
            //         dealsGet(false, false)
            //     }
        }
    }

    const _content = () => {
        return editing ? (
            <ServiceTypeForm
                privilege={privilege}
                originalServiceType={serviceTypeEditing}
                onBack={_closeEditing}
            />
        ) : (
            <>
                {serviceTypes.map(serviceType => {
                    if (
                        filter.trim().length === 0 ||
                        serviceType.name
                            .toLowerCase()
                            .includes(filter.toLowerCase())
                    ) {
                        return (
                            <ServiceTypeItem
                                key={serviceType.id}
                                serviceType={serviceType}
                                onSelected={s => _edit(s)}
                                onRemove={s => _remove(s)}
                                privilege={privilege}
                            />
                        )
                    }
                })}
            </>
        )
    }

    if (privilege === -1) {
        return <View />
    }

    return (
        <>
            <ScrollView
                style={styles.container}
                onScroll={_isCloseToBottom}
                scrollEventThrottle={400}
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={_onRefresh}
                    />
                }
            >
                <>
                    <ContentHeader
                        title="Tipos de Serviço"
                        searchBar={!editing}
                        onSearch={term => {
                            setFilter(term)
                        }}
                    />
                    {_content()}
                </>
            </ScrollView>
            {serviceTypes.length === 0 && !loading && !editing ? (
                <NoItems />
            ) : null}
            {!editing ? (
                <FabButton
                    icon={require('../../../../../assets/images/ic_plus.png')}
                    onPress={_add}
                />
            ) : null}
            <Modal
                isVisible={serviceTypeRemoving !== undefined}
                title="Atenção"
            >
                <Text style={styles.modalText}>
                    Tem certeza que deseja remover o item '
                    {serviceTypeRemoving?.name}
                    '?
                </Text>
                <Button
                    primary
                    style={{ marginTop: 12, marginBottom: 10 }}
                    label="Sim"
                    onPress={_doRemove}
                />
                <Button
                    secondary
                    style={{ marginBottom: 10 }}
                    label="Cancelar"
                    onPress={() => setServiceTypeRemoving(undefined)}
                />
            </Modal>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: metrics.padding,
    },
    modalText: {
        fontSize: 16,
        marginTop: 8,
        marginBottom: 8,
    },
})

export default ServiceTypeFragment
