class Statement {
    public id: number
    public name: string
    public recurrence: string
    public day: number
    public month: number
    public userId: number

    constructor(json: any) {
        this.id = json.id
        this.name = json.name
        this.recurrence = json.recurrence
        this.day = json.day
        this.month = json.month
        this.userId = json.userId
    }
}

export default Statement
