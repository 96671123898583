import Dropdown, { DropdownItem } from '../../../../../../components/Dropdown'
import React, { useState } from 'react'

import Button from '../../../../../../components/Button'
import Input from '../../../../../../components/Input'
import Role from '../../../../../../models/role'
import Settings from '../../../../../../services/settings'
import { Text } from 'react-native'
import { isEmpty } from '../../../../../../shared/utils'
import { saveRole } from '../../../../../../services/api'
import styles from './styles'

interface Props {
    role?: Role
    onBack: () => void
    setLoading: (val: boolean) => void
    setEditing: (val: boolean) => void
    onRoleUpdated: (r: Role) => void
}

const RoleFormEditing: React.FC<Props> = ({
    role,
    onBack,
    setLoading,
    setEditing,
    onRoleUpdated,
}) => {
    const [errorMessage, setErrorMessage] = useState('')

    const [name, setName] = useState(role?.name || '')
    const [selectableAsCompanyResponsible, setSelectableAsCompanyResponsible] =
        useState(role?.selectableAsCompanyResponsible || false)

    const _save = async () => {
        if (isEmpty(name)) {
            setErrorMessage('Por favor, informe o nome da área de atuação.')
        } else {
            setErrorMessage('')
            setLoading(true)
            try {
                const data = {
                    role: {
                        id: 0,
                        name: name.trim(),
                        selectableAsCompanyResponsible,
                    },
                }
                if (role) {
                    data.role.id = role.id
                }

                const user = await Settings.getUser()

                const result = await saveRole(data, user?.companyId || 0)
                if (role && result) {
                    setEditing(false)
                    const r = new Role(result.role)
                    onRoleUpdated(r)
                } else {
                    onBack()
                }
            } catch (e) {
                console.log('RoleFormEditing exception: ', e)
                setErrorMessage(
                    'Erro inesperado. Verifique sua conexão com a internet e tente novamente.',
                )
            } finally {
                if (role) {
                    setLoading(false)
                }
            }
        }
    }

    return (
        <>
            {errorMessage.length > 0 ? (
                <Text style={styles.errorMessage}>{errorMessage}</Text>
            ) : null}
            <Input
                returnKeyType="next"
                autoCapitalize="words"
                style={{ marginBottom: 12 }}
                label="Nome"
                value={name}
                onChangeText={text => setName(text)}
            />
            <Dropdown
                label="Este setor, pode ter um responsável por uma empresa?"
                selectedLabel={selectableAsCompanyResponsible ? 'Sim' : 'Não'}
                modalTitle="Escolha se este setor pode ter um responsável por uma empresa"
                items={['Sim', 'Não'].map(
                    (i, index) => new DropdownItem(index + 1, i),
                )}
                placeholder="Selecione se o usuário é o responsável da área."
                onItemSelected={item =>
                    setSelectableAsCompanyResponsible(item.id === 1)
                }
            />

            <Button
                primary
                style={{ marginTop: 12, marginBottom: 10 }}
                label="Salvar"
                onPress={_save}
            />
            <Button
                secondary
                style={{ marginBottom: 50 }}
                label="Cancelar"
                onPress={() => (role ? setEditing(false) : onBack())}
            />
        </>
    )
}

export default RoleFormEditing
