import { StyleSheet } from 'react-native'
import colors from '../../../../../styles/colors'
import metrics from '../../../../../styles/metrics'

const headerFilterImageIconFilteredSize = 10

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: metrics.padding,
        paddingLeft: metrics.padding,
        paddingRight: metrics.padding,
        paddingBottom: 50,
    },
    header: {
        position: 'relative',
    },
    headerReloadButton: {
        position: 'absolute',
        right: 36,
        top: 0,
        marginTop: -8,
    },
    headerFilterButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        marginTop: -8,
    },
    headerFilterIcohContainer: {
        position: 'relative',
    },
    headerFilterIcon: {
        width: 36,
        height: 36,
        tintColor: colors.primary,
    },
    headerFilterImageIconFiltered: {
        position: 'absolute',
        width: headerFilterImageIconFilteredSize,
        height: headerFilterImageIconFilteredSize,
        borderRadius: headerFilterImageIconFilteredSize / 2,
        backgroundColor: 'red',
        marginTop: 2,
        marginLeft: 2,
    },
})

export default styles
