import Company from './company'
import Pendency from './pendency'
import Role from './role'

class User {
    id: number
    name: string
    email: string
    cpf: string
    address: string
    systemRole: number
    companyId: number
    removed: boolean
    responsibleForArea: boolean
    dateUpdate: Date
    roleText?: string

    roleId?: number
    role?: Role

    pendencies: Pendency[]

    company?: Company

    constructor(json: any) {
        this.id = json.id
        this.name = json.name
        this.email = json.email
        this.cpf = json.cpf
        this.roleText = json.roleText
        this.removed = json.removed
        this.address = json.address
        this.systemRole = json.systemRole
        this.companyId = json.companyId
        this.roleId = json.roleId
        this.role = json.role
        this.pendencies = json.pendencies || []
        this.company = json.company
        this.responsibleForArea = json.responsibleForArea
        this.dateUpdate = json.dateUpdate
    }

    toJson() {
        return {
            id: this.id,
            name: this.name,
            email: this.email,
            cpf: this.cpf,
            address: this.address,
            systemRole: this.systemRole,
            companyId: this.companyId,
            company: this.company,
            responsibleForArea: this.responsibleForArea,
            dateUpdate: this.dateUpdate,
        }
    }
}

export default User
