import { StyleSheet } from 'react-native'
import metrics from '../../../../styles/metrics'

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: metrics.padding,
    },
    checkBoxes: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    checkBoxActiveInactive: {
        flexDirection: 'row',
        alignItems: 'center',
    },
})

export default styles
