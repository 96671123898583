import { StyleSheet } from 'react-native'
import metrics from '../../../styles/metrics'

const styles = StyleSheet.create({
    container: {
        padding: 20,
        borderBottomWidth: 1,
        borderBottomColor: '#222',
        // shadowColor: 'black',
        // shadowOpacity: 0.2,
        // shadowRadius: 1.41,
        // shadowOffset: {
        //     width: 1,
        //     height: 1,
        // },
        // elevation: !isWeb ? 2 : 0,
    },
    active: {
        backgroundColor: '#393F42',
    },
    iconLabelContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        width: metrics.iconSizeSmall,
        height: metrics.iconSizeSmall,
        tintColor: '#fff',
    },
    label: {
        color: '#fff',
        fontSize: 14,
        fontWeight: 'bold',
        marginLeft: 12,
    },
})

export default styles
