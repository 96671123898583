import React, { useEffect, useState } from 'react'
import User from '../../../../models/user'
import Settings from '../../../../services/settings'
import ProfileForm from './ProfileForm'

// import { Container } from './styles';

const ProfileFragment: React.FC = () => {
    const [user, setUser] = useState<User | undefined | null>(undefined)

    useEffect(() => {
        ;(async () => {
            const u = await Settings.getUser()
            setUser(u)
        })()
    }, [])

    if (!user) {
        return null
    }

    return <ProfileForm />
}

export default ProfileFragment
