// import * as FileSystem from 'expo-file-system'
import getEnvVars from '../../environments'
import City from '../models/city'
import Company from '../models/company'
import DocFormularioConstituicao from '../models/doc-formulario-constituicao'
import Document from '../models/document'
import Pendency from '../models/pendency'
import PendencyComment from '../models/pendency-comment'
import Phone from '../models/phone'
import PorteEmpresa from '../models/porte-empresa'
import Regime from '../models/regime'
import Role from '../models/role'
import State from '../models/state'
import Statement from '../models/statement'
import TaskTimer from '../models/task-timer'
import TipoSociedade from '../models/tipo-sociedade'
import User from '../models/user'
import { isEmpty } from '../shared/utils'
import Settings from './settings'

const METHOD_GET = 'GET'
const METHOD_POST = 'POST'
const METHOD_PUT = 'PUT'
const METHOD_DELETE = 'DELETE'
const baseUrl = getEnvVars().apiUrl

export class File {
    uri: string
    filename: string
    type: string

    constructor(uri: string, filename: string, type: string) {
        this.uri = uri
        this.filename = filename
        this.type = type
    }
}

const baseHeaders = async (
    accessToken?: string,
    contentType = 'application/json',
) => {
    const token = accessToken ? accessToken : await Settings.getToken()
    const myHeaders = new Headers()
    if (token) {
        myHeaders.append('Authorization', `Bearer ${token}`)
        if (__DEV__) {
            console.log('token', token)
        }
    }
    if (contentType !== 'multipart/form-data') {
        myHeaders.append('Content-Type', contentType)
    } else {
        // myHeaders.append('Content-Type', 'multipart/form-data')
    }
    // myHeaders.append('Accept', 'application/json')
    return myHeaders
}

// const convertJsonToUrlEncoded = (data: any): string => {
//     const formBody: string[] = []
//     const keys = Object.keys(data)
//     for (const key of keys) {
//         formBody.push(`${key}=${encodeURIComponent(data[key])}`)
//     }
//     return formBody.join('&')
// }

// const fileRequestOptions = async (method: string, file: File) => {
//     const headers = await baseHeaders(undefined)

//     const requestOptions = {
//         method,
//         headers,
//     }
//     const formData = new FormData()
//     // @ts-ignore
//     formData.append('file', {
//         uri: file.uri,
//         name: file.filename,
//         type: file.type,
//     })
//     // @ts-ignore
//     requestOptions.body = formData
//     return requestOptions
// }

const baseRequestOptions = async (
    method: string,
    data?: any,
    accessToken?: string,
    contentType = 'application/json',
) => {
    const headers = await baseHeaders(accessToken, contentType)

    const requestOptions = {
        method,
        headers,
    }
    if (data) {
        try {
            // @ts-ignore
            requestOptions.body =
                contentType === 'application/json' ? JSON.stringify(data) : data
        } catch (e) {
            console.log('requestOptions.body exception', e)
        }
    }
    return requestOptions
}

const request = (url: string, requestOptions: any): Promise<any> => {
    console.log('request: ', url)
    return new Promise(async (resolve, reject) => {
        fetch(url, requestOptions)
            .then(response => {
                if (!response.ok) {
                    reject({ status: response.status })
                    return null
                }
                return response.json()
            })
            .then(async result => {
                // console.l{og('RESPONSE: ', result)
                if (result) {
                    resolve(result)
                }
            })
            .catch(error => {
                console.log('REQUEST EXCEPTION: ', error)
                reject(error)
            })
    })
}

export const signIn = async (data: any): Promise<any> => {
    const requestOptions = await baseRequestOptions(METHOD_POST, data)
    return request(`${baseUrl}/api/auth/sign-in`, requestOptions)
}

export const signUp = async (data: any): Promise<any> => {
    const requestOptions = await baseRequestOptions(METHOD_POST, data)
    return request(`${baseUrl}/api/auth/sign-up`, requestOptions)
}

export const getCompanies = async (page: number): Promise<Company[]> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(`${baseUrl}/api/companies/${page}`, requestOptions)
}

export const getCompany = async (id: number): Promise<Company> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(`${baseUrl}/api/companies/0/${id}`, requestOptions)
}

export const saveCompany = async (data: any): Promise<any> => {
    const requestOptions = await baseRequestOptions(
        data.company.id > 0 ? METHOD_PUT : METHOD_POST,
        data,
    )
    return request(
        `${baseUrl}/api/companies/0/${data.company.id || 0}`,
        requestOptions,
    )
}

export const getCity = async (id: number): Promise<City> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(`${baseUrl}/api/city/${id}`, requestOptions)
}

export const getState = async (id: number): Promise<State> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(`${baseUrl}/api/state/${id}`, requestOptions)
}

export const getPhones = async (companyId: number): Promise<Phone[]> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(`${baseUrl}/api/phones/${companyId}`, requestOptions)
}

export const getPendencies = async (
    companyId: number,
    page: number,
    isStatements = false,
): Promise<Pendency[]> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    const url = isStatements
        ? `${baseUrl}/api/pendencies/${companyId}/${page}/1`
        : `${baseUrl}/api/pendencies/${companyId}/${page}`
    return request(url, requestOptions)
}

export const savePendency = async (data: any): Promise<any> => {
    const requestOptions = await baseRequestOptions(
        !isEmpty(data.pendency.id) && data.pendency.id > 0
            ? METHOD_PUT
            : METHOD_POST,
        data,
    )
    return request(
        `${baseUrl}/api/pendencies/${data.pendency.id || 0}`,
        requestOptions,
    )
}

export const savePendencyComment = async (
    pendencyId: number,
    data: any,
): Promise<any> => {
    const requestOptions = await baseRequestOptions(
        !isEmpty(data.pendencyComment.id) && data.pendencyComment.id > 0
            ? METHOD_PUT
            : METHOD_POST,
        data,
    )
    return request(
        `${baseUrl}/api/pendencies/comments/${pendencyId}/${
            data.pendencyComment.id || 0
        }`,
        requestOptions,
    )
}

export const getPendencyComments = async (
    pendencyId: number,
): Promise<PendencyComment[]> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(
        `${baseUrl}/api/pendencies/comments/${pendencyId}`,
        requestOptions,
    )
}

export const saveActivity = async (
    pendencyId: number,
    data: any,
): Promise<any> => {
    const requestOptions = await baseRequestOptions(METHOD_POST, data)
    return request(`${baseUrl}/api/activities/${pendencyId}`, requestOptions)
}

export const getActivities = async (pendencyId: number): Promise<any> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(`${baseUrl}/api/activities/${pendencyId}`, requestOptions)
}

export const checkCEP = async (cep: string): Promise<any> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    // return request(`https://viacep.com.br/ws/${cep}/json`, requestOptions)
    return request(`${baseUrl}/api/cep/${cep}/json`, requestOptions)
}

export const getOwner = async (companyId: number): Promise<User> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(`${baseUrl}/api/user-owner/${companyId}`, requestOptions)
}

export const getUsers = async (
    page: number,
    companyId: number,
): Promise<User> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(`${baseUrl}/api/users/${page}/${companyId}`, requestOptions)
}

export const getUser = async (id: number): Promise<any> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(`${baseUrl}/api/users/0/0/${id}`, requestOptions)
}

export const saveUser = async (data: any): Promise<any> => {
    const requestOptions = await baseRequestOptions(
        !isEmpty(data.user.id) && data.user.id > 0 ? METHOD_PUT : METHOD_POST,
        data,
    )
    return request(`${baseUrl}/api/users/0/0/${data.user.id}`, requestOptions)
}

export const getRoles = async (companyId: number): Promise<Role[]> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(`${baseUrl}/api/roles/${companyId}`, requestOptions)
}

export const saveRole = async (data: any, companyId: number): Promise<any> => {
    const requestOptions = await baseRequestOptions(
        !isEmpty(data.role.id) && data.role.id > 0 ? METHOD_PUT : METHOD_POST,
        data,
    )
    return request(
        `${baseUrl}/api/roles/${companyId}/${data.role.id || 0}`,
        requestOptions,
    )
}

export const removeRole = async (id: number): Promise<any> => {
    const requestOptions = await baseRequestOptions(METHOD_DELETE)
    return request(`${baseUrl}/api/roles/0/${id}`, requestOptions)
}

export const getStatements = async (): Promise<Statement[]> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(`${baseUrl}/api/private/statements/0`, requestOptions)
}

export const saveStatement = async (data: any): Promise<any> => {
    const requestOptions = await baseRequestOptions(
        !isEmpty(data.statement.id) && data.statement.id > 0
            ? METHOD_PUT
            : METHOD_POST,
        data,
    )
    return request(
        `${baseUrl}/api/private/statements/${data.statement.id}`,
        requestOptions,
    )
}

export const removeStatement = async (id: number): Promise<any> => {
    const requestOptions = await baseRequestOptions(METHOD_DELETE)
    return request(`${baseUrl}/api/private/statements/${id}`, requestOptions)
}

export const getCompanyTypes = async (): Promise<Statement[]> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(`${baseUrl}/api/private/company-type/0`, requestOptions)
}

export const saveCompanyType = async (data: any): Promise<any> => {
    const requestOptions = await baseRequestOptions(
        !isEmpty(data.companyType.id) && data.companyType.id > 0
            ? METHOD_PUT
            : METHOD_POST,
        data,
    )
    return request(
        `${baseUrl}/api/private/company-type/${data.companyType.id}`,
        requestOptions,
    )
}

export const removeCompanyType = async (id: number): Promise<any> => {
    const requestOptions = await baseRequestOptions(METHOD_DELETE)
    return request(`${baseUrl}/api/private/company-type/${id}`, requestOptions)
}

export const getRegimes = async (): Promise<Regime[]> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(`${baseUrl}/api/private/regime/0`, requestOptions)
}

export const saveRegime = async (data: any): Promise<any> => {
    const requestOptions = await baseRequestOptions(
        !isEmpty(data.regime.id) && data.regime.id > 0
            ? METHOD_PUT
            : METHOD_POST,
        data,
    )
    return request(
        `${baseUrl}/api/private/regime/${data.regime.id}`,
        requestOptions,
    )
}

export const removeRegime = async (id: number): Promise<any> => {
    const requestOptions = await baseRequestOptions(METHOD_DELETE)
    return request(`${baseUrl}/api/private/regime/${id}`, requestOptions)
}

export const getServiceTypes = async (): Promise<Statement[]> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(`${baseUrl}/api/private/service-type/0`, requestOptions)
}

export const saveServiceType = async (data: any): Promise<any> => {
    const requestOptions = await baseRequestOptions(
        !isEmpty(data.serviceType.id) && data.serviceType.id > 0
            ? METHOD_PUT
            : METHOD_POST,
        data,
    )
    return request(
        `${baseUrl}/api/private/service-type/${data.serviceType.id}`,
        requestOptions,
    )
}

export const removeServiceType = async (id: number): Promise<any> => {
    const requestOptions = await baseRequestOptions(METHOD_DELETE)
    return request(`${baseUrl}/api/private/service-type/${id}`, requestOptions)
}

export const getImpostoRendaList = async (): Promise<any> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(`${baseUrl}/api/private/imposto-renda/0`, requestOptions)
}

export const saveImpostoRenda = async (data: any): Promise<any> => {
    const requestOptions = await baseRequestOptions(
        !isEmpty(data.impostoRenda.id) && data.impostoRenda.id > 0
            ? METHOD_PUT
            : METHOD_POST,
        data,
    )
    return request(
        `${baseUrl}/api/private/imposto-renda/${data.impostoRenda.id ?? 0}`,
        requestOptions,
    )
}

export const removeImpostoRenda = async (id: number): Promise<any> => {
    const requestOptions = await baseRequestOptions(METHOD_DELETE)
    return request(`${baseUrl}/api/private/imposto-renda/${id}`, requestOptions)
}

export const getUsersActivities = async (page: number): Promise<any> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(
        `${baseUrl}/api/private/reports/user-activity/${page}`,
        requestOptions,
    )
}

export const getDocumentsByCompany = async (
    companyId: number,
    type: string,
): Promise<Document[]> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(
        `${baseUrl}/api/private/documents/${companyId}/${type}/0`,
        requestOptions,
    )
}

// http://localhost:3000/api/private/upload
export const saveDocumentForCompanyTest = async (
    document: Document,
): Promise<any> => {
    if (document.formConstituicao) {
        return null
    }
    document.filename = document.filename || document.name
    const formData = new FormData()
    // @ts-ignore
    // formData.append('files', document)
    // formData.append('file_attachment', document.result)
    // formData.append('filename', document.filename)
    // formData.append('name', document.name)
    // formData.append('size', String(document.size))
    if (!document.docResult) {
        throw new Error('Arquivo inválido.')
    }
    // @ts-ignore
    const res = await fetch(document.docResult.uri)
    const blob = await res.blob()
    // const doc = {
    //     // @ts-ignore
    //     uri: document.docResult.uri,
    //     // @ts-ignore
    //     name: document.filename,
    //     type: document.docResult.type,
    // }
    // console.log('doc', doc)
    formData.append('file', blob)

    // data in this case would an object that contains your coverFile
    // Object.entries(document).forEach(([key, value]) => {
    //     formData.set(key, value)
    // })
    const requestOptions = await baseRequestOptions(
        !isEmpty(document.id) && document.id > 0 ? METHOD_PUT : METHOD_POST,
        formData,
        undefined,
        'multipart/form-data',
    )
    if (!document.companyId || !document.type) {
        throw new Error('companyId ou type é nulo. Impossível salvar.')
    }
    const url = `${baseUrl}/api/private/upload/${document.companyId}/${document.type}/${document.filename}/${document.size}`
    return request(url, requestOptions)

    // AXIOS
    // const token = await Settings.getToken()
    // if (__DEV__) {
    //     console.log('token', token)
    // }
    // console.log('url', url)
    // const result = await axios.post(url, formData, {
    //     headers: {
    //         Authorization: `Bearer ${token}`,
    //     },
    // })
    // // axios: result.status, result.data
    // return result.data
}

export const saveDocumentForCompany = async (
    document: Document,
): Promise<any> => {
    document.filename = document.filename || document.name || ''
    const requestOptions = await baseRequestOptions(
        document.formConstituicao || (!isEmpty(document.id) && document.id > 0)
            ? METHOD_PUT
            : METHOD_POST,
        document,
    )
    if (!document.companyId || !document.type) {
        throw new Error('companyId ou type é nulo. Impossível salvar.')
    }
    return request(
        `${baseUrl}/api/private/documents/${document.companyId}/${
            document.type
        }/${document.id || 0}`,
        requestOptions,
    )
}

export const removeDocumentsByCompany = async (
    document: Document,
): Promise<any> => {
    const requestOptions = await baseRequestOptions(METHOD_DELETE)
    return request(
        `${baseUrl}/api/private/documents/${document.companyId || 0}/${
            document.type || '-'
        }/${document.id || 0}`,
        requestOptions,
    )
}

export const getTiposSociedade = async (): Promise<TipoSociedade[]> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(`${baseUrl}/api/private/tipo-sociedade/0`, requestOptions)
}

export const getPortesEmpresa = async (): Promise<PorteEmpresa[]> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(`${baseUrl}/api/private/porte-empresa/0`, requestOptions)
}

export const getDocFormularioConstituicao = async (
    documentId: number,
): Promise<DocFormularioConstituicao> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(
        `${baseUrl}/api/private/doc-formulario-constituicao/${documentId}`,
        requestOptions,
    )
}

export const getUsersByRoleId = async (
    companyId: number,
    roleId: number,
): Promise<User[]> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(
        `${baseUrl}/api/private/role-users/${companyId}/${roleId}`,
        requestOptions,
    )
}

export const getTimer = async (
    pendencyId?: number,
): Promise<{ timer: TaskTimer }> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(
        `${baseUrl}/api/private/timer/${pendencyId || 0}`,
        requestOptions,
    )
}

export const startTimer = async (
    pendencyId: number,
): Promise<{ timer: TaskTimer }> => {
    const requestOptions = await baseRequestOptions(METHOD_POST, {
        timer: {},
    })
    return request(`${baseUrl}/api/private/timer/${pendencyId}`, requestOptions)
}

export const stopTimer = async (
    timer: TaskTimer,
): Promise<{ timer: TaskTimer }> => {
    const requestOptions = await baseRequestOptions(METHOD_POST, { timer })
    return request(
        `${baseUrl}/api/private/timer/${timer.pendencyId}`,
        requestOptions,
    )
}

export const getResponsibles = async (
    isByCompanyName: boolean,
    term: string,
): Promise<any> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(
        `${baseUrl}/api/private/responsibles/${
            isByCompanyName ? 1 : 0
        }/${term}`,
        requestOptions,
    )
}

export const saveCompanyRolesResponsible = async (
    companyId: number,
    roleId: number,
    userId: number,
): Promise<any> => {
    const requestOptions = await baseRequestOptions(METHOD_POST, {
        companyId,
        roleId,
        userId,
    })
    return request(
        `${baseUrl}/api/private/company-roles-responsible/${companyId}/${roleId}`,
        requestOptions,
    )
}

export const getCompanyRolesResponsible = async (
    companyId: number,
    roleId: number,
): Promise<any> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(
        `${baseUrl}/api/private/company-roles-responsible/${companyId}/${roleId}`,
        requestOptions,
    )
}

export const getCompanySituations = async (): Promise<any> => {
    const requestOptions = await baseRequestOptions(METHOD_GET)
    return request(`${baseUrl}/api/company-situations`, requestOptions)
}

export const uploadDocument = async (
    data: any,
    pendencyId?: number,
    pendencyCommentId?: number,
): Promise<any> => {
    // const requestOptions = await fileRequestOptions(METHOD_POST, file)
    const requestOptions = await baseRequestOptions(METHOD_POST, data)
    if (pendencyCommentId && pendencyCommentId > 0) {
        return request(
            `${baseUrl}/api/pendencies/comments/documents/${pendencyId}/${pendencyCommentId}`,
            requestOptions,
        )
    }
    return request(
        `${baseUrl}/api/pendencies/documents/${pendencyId}`,
        requestOptions,
    )
}
