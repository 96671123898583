import { Dimensions, StyleSheet } from 'react-native'
import colors from '../../styles/colors'
import { isSmallScreen } from '../../styles/metrics'

const menuWidth = Dimensions.get('window').width * 0.75

const styles = StyleSheet.create({
    container: {
        position: isSmallScreen ? 'absolute' : 'relative',
        backgroundColor: colors.secondary,
        elevation: 2,
        zIndex: 10,
        overflow: 'scroll',
    },
    menuContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        zIndex: 10,
        overflow: 'scroll',
    },
    version: {
        margin: 20,
        color: 'white',
    },
    menuContainerAdaptative: isSmallScreen
        ? {
              width: menuWidth,
              height: '100%',
              paddingTop: 20,
          }
        : {
              width: 260,
              height: '100%',
          },
    companyContainer: {
        paddingHorizontal: 20,
        paddingVertical: 8,
        flexDirection: 'row',
    },
    companyContainerIcon: {
        backgroundColor: 'white',
        width: 8,
        height: '100%',
        marginRight: 8,
    },
    companyData: {
        color: 'white',
        fontWeight: 'bold',
    },
})

export default styles
