import { StyleSheet } from 'react-native'
import metrics from '../../../../styles/metrics'

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: metrics.padding,
    },
    header: {
        position: 'relative',
        marginBottom: metrics.marginLarge,
    },
})

export default styles
