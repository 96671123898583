import * as DocumentPicker from 'expo-document-picker'
import * as FileSystem from 'expo-file-system'

import { Platform } from 'react-native'
import Document from '../models/document'

export const getMimeType = (filename: string): string => {
    if (filename) {
        const match = /\.(\w+)$/.exec(filename)
        let type = 'image/jpg'
        if (match) {
            switch (match[1].toLowerCase()) {
                case 'pdf':
                    type = 'application/pdf'
                    break
                case 'doc':
                    type = 'application/msword'
                    break
                case 'docx':
                    type =
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                    break
                default:
                    type = `image/${match[1].toLowerCase()}`
                    break
            }
            return type
        }
    }
    return ''
}

export const getSizeHumanReadable = (size: number): string => {
    if (size) {
        let _sufix = 'bytes'
        let _size = size
        if (_size > 1024) {
            _size /= 1024
            _sufix = 'KB'
        }
        if (_size > 1024) {
            _size /= 1024
            _sufix = 'MB'
        }
        if (_size > 1024) {
            _size /= 1024
            _sufix = 'GB'
        }
        return `${~~_size} ${_sufix}`
    }
    return '0 bytes'
}

export const getDocument = async (): Promise<Document | null> => {
    try {
        const result = await DocumentPicker.getDocumentAsync({
            type: '*/*',
            copyToCacheDirectory: false,
        })

        if (result.type === 'success') {
            // result.name
            // result.size
            // result.uri
            const base64 =
                Platform.OS !== 'web'
                    ? await FileSystem.readAsStringAsync(result.uri, {
                          encoding: FileSystem.EncodingType.Base64,
                      })
                    : result.uri
            const doc = new Document({
                name: result.name,
                filename: result.name,
                size: result.size,
                uri: result.uri,
                base64: base64,
                result: result,
            })
            if (Platform.OS === 'web') {
                doc.docResult = result
            }
            return doc
        }
    } catch (e) {
        console.log('file-utils getDocument exception: ', e)
    }
    return null
}
