import { StyleSheet } from 'react-native'
import colors from '../../../../../styles/colors'
import metrics, { iconSizeNormal, isSmallScreen } from '../../../../../styles/metrics'

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: metrics.padding,
    },
    tabsContainer: {
        flexDirection: 'row',
    },
    tab: {
        position: 'relative',
        width: '50%',
    },
    tabPendencies: {
        borderLeftWidth: 1,
        borderLeftColor: '#dfdfdf',
    },
    pendenciesBadge: {
        position: 'absolute',
        alignSelf: 'center',
        right: 20,
        backgroundColor: 'red',
        minWidth: 14,
        height: 16,
        borderRadius: 8,
        paddingLeft: 4,
        paddingRight: 4,
        justifyContent: 'center',
    },
    pendenciesBadgeText: {
        fontSize: 12,
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    tabInactive: {
        color: '#bbb',
    },
    title: {
        fontWeight: 'bold',
        fontSize: 16,
        color: colors.secondary,
        marginBottom: 8,
        textAlign: 'center',
    },
    lineContainer: {
        position: 'relative',
        justifyContent: 'center',
    },
    lineDetail: {
        position: 'absolute',
        width: isSmallScreen ? '20%' : '10%',
        height: 3,
        backgroundColor: colors.accent,
    },
    line: {
        height: 1,
        backgroundColor: '#bbb',
    },
})

export default styles
