import { Dimensions, StyleSheet } from 'react-native'
import colors from '../../styles/colors'

const screenWidth = Dimensions.get('window').width

const styles = StyleSheet.create({
    container: {
        width: screenWidth < 720 ? '100%' : '40%',
        maxHeight: '90%',
        backgroundColor: 'white',
        borderRadius: 10,
        overflow: 'scroll',
        alignSelf: 'center',
    },
    header: {
        flexDirection: 'row',
        backgroundColor: colors.primary,
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 8,
        paddingBottom: 8,
    },
    headerIconTitleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerIcon: {
        width: 34,
        height: 34,
        tintColor: 'white',
        resizeMode: 'contain',
    },
    headerTitle: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
        maxWidth: screenWidth * 0.65,
    },
    buttonClose: {},
    iconClose: {
        width: 24,
        height: 24,
        tintColor: 'white',
    },
    body: {
        padding: 12,
    },
})

export default styles
