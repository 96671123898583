import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'

import React from 'react'
import Statement from '../../../../models/statement'
import User from '../../../../models/user'
import { SystemRole } from '../../../../shared/consts'
import colors from '../../../../styles/colors'
import { iconSizeNormal } from '../../../../styles/metrics'

export interface StatementItemProps {
    statement: Statement
    onSelected: (statement: Statement) => void
    onRemove: (statement: Statement) => void
    user: User | null | undefined
}

const StatementItem = ({
    statement,
    onSelected,
    onRemove,
    user,
}: StatementItemProps) => {
    return (
        <TouchableOpacity
            style={styles.container}
            onPress={() => onSelected(statement)}
        >
            <View>
                <Text style={styles.name}>{statement.name}</Text>
                <Text style={styles.recurrence}>
                    Dia: {statement.day || '-'}
                </Text>
                <Text style={styles.recurrence}>{statement.recurrence}</Text>
            </View>
            {SystemRole.isSuperAdmin(user?.systemRole || 0) && (
                <TouchableOpacity onPress={() => onRemove(statement)}>
                    <Image
                        style={styles.iconRemove}
                        source={require('../../../../../assets/images/ic_remove.png')}
                    />
                </TouchableOpacity>
            )}
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingTop: 16,
        paddingBottom: 16,
        borderBottomColor: '#bbb',
        borderBottomWidth: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    name: {
        color: colors.secondary,
        fontSize: 18,
        fontWeight: 'bold',
    },
    recurrence: {
        color: colors.secondary,
        fontSize: 14,
        fontWeight: 'normal',
        marginTop: 8,
    },
    iconRemove: {
        width: iconSizeNormal,
        height: iconSizeNormal,
        tintColor: '#f55',
    },
})

export default StatementItem
