import React, { useEffect, useRef, useState } from 'react'
import { Keyboard, Text, TouchableOpacity } from 'react-native'
import Button from '../../../../../../components/Button'
import CheckBox from '../../../../../../components/CheckBox'
import Dropdown, { DropdownItem } from '../../../../../../components/Dropdown'
import Input from '../../../../../../components/Input'
import User from '../../../../../../models/user'
import { getRoles, saveUser } from '../../../../../../services/api'
import Settings from '../../../../../../services/settings'
import { SystemRole } from '../../../../../../shared/consts'
import { isEmpty } from '../../../../../../shared/utils'
import styles from './styles'

interface Props {
    user?: User
    setLoading: (val: boolean) => void
    setEditing: (val: boolean) => void
    onUserUpdated: (u: User) => void
    onCancel: () => void
}

const ProfileFormEditing: React.FC<Props> = ({
    user,
    setLoading,
    setEditing,
    onUserUpdated,
    onCancel,
}) => {
    const [errorMessage, setErrorMessage] = useState('')

    const [name, setName] = useState(user?.name || '')
    const [email, setEmail] = useState(user?.email || '')
    const [cpf, setCpf] = useState(user?.cpf || '')
    const [roleId, setRoleId] = useState(user?.roleId || 0)
    const [password, setPassword] = useState('')

    const [whatsAppNotification, setWhatsAppNotification] = useState(false)
    const [emailNotification, setEmailNotification] = useState(false)
    const [whatsAppNumber, setWhatsAppNumber] = useState('')

    const [rolesDropdownItems, setRolesDropdownItems] = useState<
        DropdownItem[]
    >([])

    const inputEmail = useRef()
    const inputCpf = useRef()
    const inputPassword = useRef()

    useEffect(() => {
        ;(async () => {
            setWhatsAppNotification(await Settings.getWhatsAppNotification())
            setEmailNotification(await Settings.getEmailNotification())
            setWhatsAppNumber((await Settings.getWhatsAppNumber()) || '')
        })()
    })

    useEffect(() => {
        ;(async () => {
            try {
                // const user = await Settings.getUser()
                const response = await getRoles(user?.companyId || 0)
                if (response) {
                    const items: DropdownItem[] = response.map(
                        role => new DropdownItem(role.id, role.name),
                    )
                    setRolesDropdownItems(items)
                }
            } catch (e) {
                console.log('CompanyFormPendencies init exception: ', e)
            }
        })()
    }, [user?.companyId])

    const _cancel = () => {
        onCancel()
    }

    const _save = async () => {
        if (isEmpty(name)) {
            setErrorMessage('Por favor, informe o nome do funcionário.')
        } else if (isEmpty(cpf)) {
            setErrorMessage('Por favor, informe o CPF do funcionário.')
        } else if (isEmpty(email)) {
            setErrorMessage('Por favor, informe o e-mail do funcionário.')
        } else {
            setErrorMessage('')
            setLoading(true)
            try {
                const loggedUser = await Settings.getUser()
                const data = {
                    user: {
                        id: 0,
                        name: name.trim(),
                        cpf,
                        email: email.toLowerCase(),
                        systemRole: user?.systemRole || SystemRole.employee,
                        password,
                        companyId: loggedUser?.companyId || 0,
                        roleId,
                    },
                }
                if (user) {
                    data.user.id = user.id
                }

                const result = await saveUser(data)
                if (user && result) {
                    setEditing(false)
                    const u = new User(result.user)
                    onUserUpdated(u)
                }

                // const result = await saveCompany(data)
                // if (company) {
                //     setEditing(false)
                //     const c = result.company ? new Company(result.company) : company
                //     onCompanyUpdated(c)
                // } else {
                //     onBack()
                // }
            } catch (e) {
                console.log('CompanyFormEditing exception: ', e)
                setErrorMessage(
                    'Erro inesperado. Verifique sua conexão com a internet e tente novamente.',
                )
            } finally {
                setLoading(false)
            }
        }
    }

    return (
        <>
            {errorMessage.length > 0 ? (
                <Text style={styles.errorMessage}>{errorMessage}</Text>
            ) : null}
            <Input
                nextRef={inputEmail}
                returnKeyType="next"
                autoCapitalize="words"
                style={{ marginBottom: 12 }}
                label="Nome"
                value={name}
                onChangeText={text => setName(text)}
            />
            <Input
                ref={inputEmail}
                nextRef={inputCpf}
                returnKeyType="next"
                keyboardType="email-address"
                autoCapitalize="none"
                style={{ marginBottom: 12 }}
                label="E-mail"
                value={email}
                onChangeText={text => setEmail(text)}
            />
            <Input
                ref={inputCpf}
                returnKeyType="done"
                keyboardType="numeric"
                maskType="cpf"
                style={{ marginBottom: 12 }}
                label="CPF"
                value={cpf}
                onSubmitEditing={() => Keyboard.dismiss()}
                onChangeText={text => setCpf(text)}
            />
            <Dropdown
                label="Área"
                selectedLabel={user?.role?.name}
                modalTitle="Escolha a área de atuação"
                items={rolesDropdownItems}
                placeholder="Escolha área"
                onItemSelected={item => setRoleId(item.id)}
            />
            <Input
                ref={inputPassword}
                returnKeyType="go"
                autoCapitalize="none"
                secureTextEntry={true}
                style={{ marginBottom: 12 }}
                label="Senha"
                value={password}
                onSubmitEditing={_save}
                onChangeText={text => setPassword(text)}
            />
            <TouchableOpacity
                style={styles.containerCheckbox}
                onPress={async () => {
                    setWhatsAppNotification(!whatsAppNotification)
                    Settings.setWhatsAppNotification(!whatsAppNotification)
                }}
            >
                <CheckBox checked={whatsAppNotification} />
                <Text>Notificação no WhatsApp</Text>
            </TouchableOpacity>
            <Input
                style={{ marginBottom: 12 }}
                label="Número do WhatsApp"
                keyboardType="numeric"
                maskType="cel-phone"
                value={whatsAppNumber}
                onChangeText={async text => {
                    setWhatsAppNumber(text)
                    Settings.setWhatsAppNumber(text)
                }}
            />
            <TouchableOpacity
                style={styles.containerCheckbox}
                onPress={async () => {
                    setEmailNotification(!emailNotification)
                    Settings.setEmailNotification(!emailNotification)
                }}
            >
                <CheckBox checked={emailNotification} />
                <Text>Notificação por Email</Text>
            </TouchableOpacity>

            <Button
                primary
                style={{ marginTop: 12, marginBottom: 10 }}
                label="Salvar"
                onPress={_save}
            />
            <Button
                secondary
                style={{ marginTop: 12, marginBottom: 10 }}
                label="Cancelar"
                onPress={_cancel}
            />
        </>
    )
}

export default ProfileFormEditing
