import { Dimensions, Platform } from 'react-native'

export const logoRatio = 300 / 72

export const iconSizeSmall = 26
export const iconSizeNormal = 32

export const isSmallScreen = Dimensions.get('window').width < 720

export default {
    padding: 15,
    paddingLarge: 30,
    paddingLargest: 45,
    margin: 15,
    marginLarge: 30,
    marginLargest: 45,
    headerHeight: Platform.select({ ios: 64, android: 44 }),
    headerPadding: Platform.select({
        ios: Platform.Version <= 13.5 ? 20 : 40,
        android: 40,
        web: 10,
    }),
    footerPadding: Platform.select({
        ios: Platform.Version <= 13.5 ? 0 : 20,
        android: 0,
    }),
    tabBarHeight: 50,
    fontSmallest: 12,
    fontSmaller: 14,
    fontSmall: 16,
    fontNormal: 20,
    fontLarge: 24,
    fontLargest: 36,
    fontBigTitle: 46,
    iconSizeSmall: 20,
    iconSize: 24,
}
