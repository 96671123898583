import { isEmpty } from '../shared/utils'
import CompanySituation from './company-situation'
import CompanyType from './company-type'
import Pendency from './pendency'
import Phone from './phone'
import Regime from './regime'
import ServiceType from './service-type'
import Statement from './statement'
import User from './user'

export function companyTypesString(company?: Company) {
    if (company && company.companyType.length > 0) {
        const str = company.companyType.map(i => i.name).join(', ')
        return str
    }
    return ''
}

export function companyServiceTypesString(company?: Company) {
    if (company && company.serviceType.length > 0) {
        const str = company.serviceType.map(i => i.name).join(', ')
        return str
    }
    return ''
}

export function companyStatementsString(company?: Company) {
    if (company && company.statements) {
        return company.statements
            .map(i => `${i.name}, recorrência: ${i.recurrence}, dia: ${i.day}`)
            .join(' - ')
    }
}

class Company {
    id: number
    name: string
    cnpj: string
    cpf: string // quando ainda não tem cnpj
    address: string
    addressNumber: string
    cep: string
    stateId: number
    cityId: number
    dateCreate: Date
    dateUpdate: Date
    regimeId: number
    companySituationId: number
    // serviceTypeId: number
    // companyTypeId: number
    additionalInformation: string
    cnaes: string

    pendencies: Pendency[]

    admin: User

    phones: Phone[]

    public regime: Regime
    public serviceType: ServiceType[]
    public companyType: CompanyType[]
    public statements: Statement[]

    public companySituation?: CompanySituation

    constructor(json: any) {
        this.id = json.id
        this.name = json.name
        this.cnpj = json.cnpj
        this.cpf = json.cpf || ''
        this.address = json.address
        this.addressNumber = json.addressNumber
        this.cep = json.cep
        this.stateId = json.stateId
        this.cityId = json.cityId
        this.dateCreate = json.dateCreate
        this.dateUpdate = json.dateUpdate
        this.pendencies = []
        if (json.pendencies) {
            for (const pendency of json.pendencies) {
                this.pendencies.push(new Pendency(pendency))
            }
        }
        this.admin = json.admin || new User({})
        this.phones = json.phones || []
        this.regime = json.regime
        this.serviceType = json.serviceType
        this.companyType = json.companyType
        this.statements = json.statements || []
        this.regimeId = json.regimeId
        this.companySituationId = json.companySituationId
        // this.serviceTypeId = json.serviceTypeId
        // this.companyTypeId = json.companyTypeId
        this.additionalInformation = json.additionalInformation
        this.cnaes = json.cnaes
        this.companySituation = json.companySituation
    }

    pendenciesIncompletedCount(): number {
        if (this.pendencies) {
            let count = 0
            for (const pendency of this.pendencies) {
                if (isEmpty(pendency.dateCompleted)) {
                    count++
                }
            }
            return count
        }
        return 0
    }
}

export default Company
