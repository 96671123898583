import React, { useEffect, useState } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import Pendency from '../../models/pendency'
import { datetimeToString } from '../../shared/date'
import colors from '../../styles/colors'
import globalStyles from '../../styles/styles'
import styles from './styles'

interface Props {
    pendency: Pendency
    // canChangeState?: boolean
    isCompanyMaster?: boolean
    editPendency: (pendency: Pendency) => void
    // deletePendency?: (pendency: Pendency) => void
    // startPendency?: (pendency: Pendency) => void
    // completePendency?: (pendency: Pendency) => void
}

const PendencyItem: React.FC<Props> = ({
    pendency,
    isCompanyMaster,
    editPendency,
}) => {
    const [creator, setCreator] = useState('')
    const [executor, setExecutor] = useState('')

    useEffect(() => {
        if (pendency.userCreator) {
            setCreator(pendency.userCreator.name)
        } else {
            setCreator('-')
        }
        if (pendency.userExecutor) {
            setExecutor(pendency.userExecutor.name)
        } else {
            setExecutor('-')
        }
    }, [pendency.userCreator, pendency.userExecutor])

    return (
        <View style={styles.container}>
            <TouchableOpacity
                style={styles.containerPendencies}
                onPress={() => editPendency(pendency)}
            >
                <View>
                    <Text
                        style={[
                            globalStyles.labelBold,
                            globalStyles.marginBottomNormal,
                        ]}
                    >
                        Solicitação número: {pendency.id}
                    </Text>
                    {isCompanyMaster && pendency.company ? (
                        <Text
                            style={[
                                globalStyles.labelBold,
                                globalStyles.marginBottomNormal,
                            ]}
                        >
                            {pendency.company.name}
                            {' - '}
                            {pendency.company.cnpj || pendency.company.cpf}
                        </Text>
                    ) : null}
                    <Text style={styles.pendencyDescription}>
                        {pendency.description}
                    </Text>
                    <Text style={[styles.pendencyDetail, { marginBottom: 8 }]}>
                        Departamento:{' '}
                        <Text style={styles.pendencyDetailBold}>
                            {pendency.role ? pendency.role.name : '-'}
                        </Text>
                    </Text>
                    <Text style={styles.pendencyDetail}>
                        Criado por:{' '}
                        <Text style={styles.pendencyDetailBold}>{creator}</Text>
                    </Text>
                    <Text style={styles.pendencyDetailDate}>
                        {datetimeToString(pendency.dateCreate)}
                    </Text>
                    <Text style={styles.pendencyDetail}>
                        Iniciado por:{' '}
                        <Text style={styles.pendencyDetailBold}>
                            {executor}
                        </Text>
                    </Text>
                    <Text style={styles.pendencyDetailDate}>
                        {pendency.userIdExecutor && pendency.userIdExecutor > 0
                            ? datetimeToString(pendency.dateStarted)
                            : ''}
                    </Text>
                    {pendency.dateCompleted ? (
                        <View
                            style={{
                                backgroundColor: colors.primary,
                                paddingLeft: 4,
                                paddingRight: 4,
                                borderRadius: 4,
                            }}
                        >
                            <Text
                                style={[
                                    styles.pendencyDetail,
                                    {
                                        color: '#fff',
                                        textAlign: 'center',
                                    },
                                ]}
                            >
                                Finalizado{' '}
                                <Text style={styles.pendencyDetailBold}>
                                    {datetimeToString(pendency.dateCompleted)}
                                </Text>
                            </Text>
                        </View>
                    ) : pendency.dateStarted ? (
                        <View
                            style={{
                                backgroundColor: colors.accent,
                                paddingLeft: 4,
                                paddingRight: 4,
                                borderRadius: 4,
                            }}
                        >
                            <Text
                                style={[
                                    styles.pendencyDetail,
                                    {
                                        color: '#fff',
                                        textAlign: 'center',
                                    },
                                ]}
                            >
                                Iniciado{' '}
                                <Text style={styles.pendencyDetailBold}>
                                    {datetimeToString(pendency.dateCompleted)}
                                </Text>
                            </Text>
                        </View>
                    ) : (
                        <Text style={styles.pendencyDetail}>Não iniciado</Text>
                    )}
                </View>
                {/* <TouchableOpacity onPress={() => deletePendency(pendency)}>
                    <Image
                        style={styles.deleteIcon}
                        source={require('../../../../../../../../assets/images/ic_remove.png')}
                    />
                </TouchableOpacity> */}
            </TouchableOpacity>
            {}
        </View>
    )
}

export default PendencyItem
