import { StyleSheet } from 'react-native'
import metrics from '../../../../styles/metrics'

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: metrics.padding,
    },
    containerBaixadaTransferida: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 8,
    },
})

export default styles
