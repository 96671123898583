import Company from './company'
import Document from './document'
import Role from './role'
import Statement from './statement'
import User from './user'

class Pendency {
    // Solicitação / Request
    id: number
    description: string
    companyId: number
    dateCreate: Date
    dateStarted: Date
    dateCompleted: Date
    userIdCreator: number
    userIdExecutor: number
    roleId: number

    userCreator?: User
    userExecutor?: User
    role?: Role
    company?: Company
    documents: Document[]

    statements: Statement[]

    constructor(json: any) {
        this.id = json.id
        this.description = json.description
        this.companyId = json.companyId
        this.dateCreate = json.dateCreate
        this.dateStarted = json.dateStarted
        this.dateCompleted = json.dateCompleted
        this.userIdCreator = json.userIdCreator
        this.userIdExecutor = json.userIdExecutor
        this.roleId = json.roleId

        this.userCreator = json.userCreator
        this.userExecutor = json.userExecutor
        this.role = json.role
        this.company = json.company

        this.documents = []
        if (json.documents) {
            this.documents = json.documents
        }

        this.statements = json.statements || []
    }

    getShortDescription(): string {
        if (this.description) {
            return this.description.length > 50
                ? `${this.description.substr(0, 50)}...`
                : this.description
        }
        return ''
    }
}

export default Pendency
