import { StyleSheet } from 'react-native'
import colors from '../../../../../../styles/colors'
import { iconSizeNormal } from '../../../../../../styles/metrics'

const styles = StyleSheet.create({
    container: {},

    errorMessage: {
        color: 'red',
        fontSize: 14,
        marginBottom: 10,
    },
})

export default styles
