import { StyleSheet } from 'react-native'
import colors from '../../styles/colors'

const headerFilterImageIconFilteredSize = 10

const styles = StyleSheet.create({
    iconContainer: {
        position: 'relative',
    },
    icon: {
        width: 36,
        height: 36,
        tintColor: colors.primary,
    },
    iconBadge: {
        position: 'absolute',
        width: headerFilterImageIconFilteredSize,
        height: headerFilterImageIconFilteredSize,
        borderRadius: headerFilterImageIconFilteredSize / 2,
        backgroundColor: 'red',
        marginTop: 2,
        marginLeft: 2,
    },
})

export default styles
