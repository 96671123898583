import React, { useEffect, useState } from 'react'
import { ScrollView, Text, View } from 'react-native'
import Dropdown, { DropdownItem } from '../../../../../../components/Dropdown'
import {
    getPendencies,
    getRoles,
    savePendency,
} from '../../../../../../services/api'

import Button from '../../../../../../components/Button'
import ContentHeader from '../../../../../../components/ContentHeader'
import Input from '../../../../../../components/Input'
import Modal from '../../../../../../components/Modal'
import PendencyItem from '../../../../../../components/PendencyItem'
import Company from '../../../../../../models/company'
import Pendency from '../../../../../../models/pendency'
import Settings from '../../../../../../services/settings'
import { isEmpty } from '../../../../../../shared/utils'
import styles from './styles'

interface Props {
    company?: Company
    onBack: () => void
    setLoading: (val: boolean) => void
    onCompanyUpdated: (c: Company) => void
    hideHeader?: boolean
    isStatements?: boolean
}

const CompanyFormPendencies: React.FC<Props> = ({
    company,
    setLoading,
    hideHeader,
    isStatements,
}) => {
    const [pendencies, setPendencies] = useState<Pendency[]>(
        !isStatements && company?.pendencies ? company.pendencies : [],
    )

    const [errorMessage, setErrorMessage] = useState('')
    const [showPendencyModal, setShowPendencyModal] = useState(false)
    const [errorMessagePendency, setErrorMessagePendency] = useState('')
    const [pendency, setPendency] = useState('')
    const [pendencyId, setPendencyId] = useState(0)
    const [roleId, setRoleId] = useState(0)
    const [pendencyName, setPendencyName] = useState('')

    const [rolesDropdownItems, setRolesDropdownItems] = useState<
        DropdownItem[]
    >([])

    const [updateStatements, setUpdateStatements] = useState(isStatements)

    useEffect(() => {
        console.log('>>>> COMPANY FRAGMENT getPendencies')
        ;(async () => {
            try {
                const user = await Settings.getUser()
                const response = await getRoles(user?.companyId || 0)
                if (response) {
                    const items: DropdownItem[] = response.map(
                        role => new DropdownItem(role.id, role.name),
                    )
                    setRolesDropdownItems(items)
                }
                if (
                    isStatements &&
                    pendencies.length === 0 &&
                    updateStatements
                ) {
                    setUpdateStatements(false)
                    const _pendencies = await getPendencies(
                        user?.companyId || 0,
                        0,
                        true,
                    )
                    setPendencies(_pendencies)
                }
            } catch (e) {
                console.log('CompanyFormPendencies init exception: ', e)
            }
        })()
    }, [isStatements, pendencies, updateStatements])

    const _addPendency = () => {
        setPendencyId(0)
        setPendency('')
        setShowPendencyModal(true)
    }

    const _editPendency = (_pendency: Pendency) => {
        setPendencyId(_pendency.id)
        setPendency(_pendency.description)
        setPendencyName(_pendency.role?.name || '')
        setShowPendencyModal(true)
    }

    const _savePendency = async () => {
        if (isEmpty(pendency)) {
            setErrorMessagePendency('Você precisa colocar uma descrição.')
        } else {
            try {
                // const user = await Settings.getUser()
                const data = {
                    pendency: {
                        id: 0,
                        description: pendency,
                        companyId: company?.id || 0,
                        roleId,
                    },
                }
                if (pendencyId > 0) {
                    data.pendency.id = pendencyId
                }
                setLoading(true)
                await savePendency(data)
                setLoading(false)
                console.log('>>>> COMPANY FRAGMENT getPendencies _savePendency')
                const _pendencies = await getPendencies(company?.id || 0, 0)
                setPendencies(_pendencies)
                // const c = { ...company }
                // c.pendencies = _pendencies
                // onCompanyUpdated(c as Company)
            } catch (e) {
                setLoading(false)
                console.log('_addPendency exception: ', e)
                setErrorMessage(
                    'Erro inesperado. Verifique sua conexão com a internet e tente novamente.',
                )
            }
            setShowPendencyModal(false)
        }
    }

    return (
        <ScrollView style={{ flex: 1 }}>
            {!hideHeader ? (
                <ContentHeader style={{ marginTop: 20 }} title="Pendências" />
            ) : (
                <View style={{ height: 30 }} />
            )}
            {errorMessage.length > 0 ? (
                <Text style={styles.errorMessage}>{errorMessage}</Text>
            ) : null}
            {pendencies.length === 0 ? <Text>Sem pendências</Text> : null}
            {pendencies.map((_pendency, index) => (
                <PendencyItem
                    key={`pendency_${index}`}
                    pendency={_pendency}
                    editPendency={_editPendency}
                />
            ))}
            <Button
                primary
                style={{ marginTop: 12, marginBottom: 50 }}
                label="Adicionar Pendência"
                onPress={_addPendency}
            />
            <Modal
                isVisible={showPendencyModal}
                title={
                    pendencyId > 0 ? 'Editar Pendência' : 'Adicionar Pendência'
                }
            >
                {errorMessagePendency.length > 0 ? (
                    <Text style={styles.errorMessage}>
                        {errorMessagePendency}
                    </Text>
                ) : null}
                <Input
                    autoCapitalize="sentences"
                    style={{ marginBottom: 12, height: 100 }}
                    multiline
                    numberOfLines={4}
                    textAlignVertical="top"
                    label="Descreva a pendência"
                    value={pendency}
                    onChangeText={text => setPendency(text)}
                />
                <Dropdown
                    label="Área"
                    selectedLabel={pendencyName}
                    modalTitle="Escolha a área de atuação"
                    items={rolesDropdownItems}
                    placeholder="Escolha área"
                    onItemSelected={item => setRoleId(item.id)}
                />
                <Button
                    primary
                    style={{ marginTop: 12, marginBottom: 10 }}
                    label="Salvar"
                    onPress={_savePendency}
                />
                <Button
                    secondary
                    style={{ marginBottom: 10 }}
                    label="Cancelar"
                    onPress={() => setShowPendencyModal(false)}
                />
            </Modal>
        </ScrollView>
    )
}

export default CompanyFormPendencies
