import { StyleSheet, View } from 'react-native'

import React from 'react'
import colors from '../../../../../styles/colors'

type TabsProps = {
    children: React.ReactNode
}

export default function Tabs({ children }: TabsProps) {
    return <View style={tabStyles.tabsContainer}>{children}</View>
}

export const tabStyles = StyleSheet.create({
    tabsContainer: {
        flexDirection: 'row',
    },
    tabInactive: {
        color: '#bbb',
    },
    title: {
        fontWeight: 'bold',
        fontSize: 16,
        color: colors.secondary,
        marginBottom: 8,
        textAlign: 'center',
    },
})
