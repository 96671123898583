import React from 'react'
import { Image, Text, TouchableOpacity, View } from 'react-native'
import Company from '../../../../../models/company'
import styles from './styles'

interface Props {
    company: Company
    onSelected: (company: Company) => void
}

const CompanyItem: React.FC<Props> = ({ company, onSelected }) => {
    return (
        <TouchableOpacity
            style={styles.container}
            onPress={() => onSelected(company)}
        >
            <View>
                <Text style={styles.name}>{company.name}</Text>
                <Text style={styles.cnpj}>
                    {company.cnpj || company.cpf || ''}
                </Text>
                <Text style={styles.cnpj}>
                    Situação: {company.companySituation?.name}
                </Text>
            </View>
            {company.pendencies && company.pendencies.length > 0 ? (
                <Image
                    style={styles.iconWarning}
                    source={require('../../../../../../assets/images/ic_warning.png')}
                />
            ) : null}
        </TouchableOpacity>
    )
}

export default CompanyItem
