import { StyleSheet } from 'react-native'
import colors from '../../styles/colors'

const styles = StyleSheet.create({
    container: {
        position: 'relative',
        height: 60,
        marginBottom: 10,
    },
    input: {
        height: 60,
    },
    button: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    modalContainer: {
        maxHeight: 300,
    },
    modalItemButtom: {
        paddingTop: 12,
        paddingRight: 8,
        paddingBottom: 12,
        paddingLeft: 8,
        borderBottomColor: '#bbb',
        borderBottomWidth: 1,
    },
    modalItemButtomCheckBox: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    modalItemLabel: {
        color: colors.secondary,
        fontSize: 14,
    },
    modalItemLabelNone: {
        fontStyle: 'italic',
    },
})

export default styles
