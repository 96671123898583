import { StyleSheet } from 'react-native'
import colors from '../../../../../styles/colors'
import { iconSizeNormal } from '../../../../../styles/metrics'

const styles = StyleSheet.create({
    container: {
        paddingTop: 16,
        paddingBottom: 16,
        borderBottomColor: '#bbb',
        borderBottomWidth: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    name: {
        color: colors.secondary,
        fontSize: 18,
        fontWeight: 'bold',
    },
    cnpj: {
        color: '#888',
        marginTop: 4,
    },
    iconWarning: {
        width: iconSizeNormal,
        height: iconSizeNormal,
        tintColor: '#E7D42E',
    },
    iconRemove: {
        width: iconSizeNormal,
        height: iconSizeNormal,
        tintColor: '#f55',
    },
    responsible: {
        fontWeight: 'bold',
        color: colors.accent,
    },
    responsibleNo: {
        color: '#f44',
    },
})

export default styles
