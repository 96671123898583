import React, { useState } from 'react'
import { StyleSheet, Text } from 'react-native'
import Button from '../../../../components/Button'
import Input from '../../../../components/Input'
import Spinner from '../../../../components/Spinner'
import Regime from '../../../../models/regime'
import { saveRegime } from '../../../../services/api'
import { isEmpty } from '../../../../shared/utils'

export interface RegimeFormProps {
    originalRegime?: Regime
    onBack: () => void
}

const RegimeForm = ({ originalRegime, onBack }: RegimeFormProps) => {
    const [name, setName] = useState(originalRegime?.name || '')
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const _save = async () => {
        if (isEmpty(name)) {
            setErrorMessage('Por favor, informe o nome do regime.')
        } else {
            setErrorMessage('')
            setLoading(true)
            try {
                const data = {
                    regime: {
                        id: 0,
                        name: name.trim(),
                    },
                }
                if (originalRegime) {
                    data.regime.id = originalRegime.id
                }

                const result = await saveRegime(data)
                if (originalRegime && result) {
                    // setEditing(false)
                    // const r = new Statement(result.statement)
                    // onStatementUpdated(r)
                } else {
                    onBack()
                }
            } catch (e) {
                console.log('RegimeForm exception: ', e)
                setErrorMessage(
                    'Erro inesperado. Verifique sua conexão com a internet e tente novamente.',
                )
            } finally {
                if (originalRegime) {
                    setLoading(false)
                }
            }
        }
    }

    return (
        <>
            {errorMessage.length > 0 ? (
                <Text style={styles.errorMessage}>{errorMessage}</Text>
            ) : null}
            <Input
                returnKeyType="next"
                autoCapitalize="words"
                style={{ marginBottom: 12 }}
                label="Nome"
                value={name}
                onChangeText={setName}
            />

            <Button
                primary
                style={{ marginTop: 12, marginBottom: 10 }}
                label="Salvar"
                onPress={_save}
            />
            <Button
                secondary
                style={{ marginBottom: 50 }}
                label="Cancelar"
                onPress={onBack}
            />
            <Spinner visible={loading} />
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    errorMessage: {
        color: 'red',
        fontSize: 14,
        marginBottom: 10,
    },
})

export default RegimeForm
