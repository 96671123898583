import { Dimensions, Platform, StyleSheet } from 'react-native'
import colors from '../../../styles/colors'
import { logoRatio } from '../../../styles/metrics'

const windowWidth = Dimensions.get('window').width

let loginBoxWidth = 500
let logoW = 300
let logoH = logoW / logoRatio

if (windowWidth < 720) {
    loginBoxWidth = windowWidth * 0.8
    logoW = loginBoxWidth * 0.6
    logoH = logoW / logoRatio
}

const isWeb = Platform.OS === 'web'

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: colors.primary,
        justifyContent: 'center',
        alignItems: 'center',
    },
    loginBox: {
        width: loginBoxWidth,
        backgroundColor: '#fff',
        padding: isWeb ? 40 : 20,
        borderRadius: 4,
        shadowColor: 'black',
        shadowOpacity: 0.2,
        shadowRadius: 1.41,
        shadowOffset: {
            width: 0,
            height: 1,
        },
        elevation: 2,
    },
    logoContainer: {
        alignItems: 'center',
    },
    logo: {
        width: logoW,
        height: logoH,
        resizeMode: 'contain',
    },
    line: {
        width: '100%',
        height: 1,
        backgroundColor: '#888',
        marginTop: 28,
        marginBottom: 16,
    },
    buttonContainer: {
        alignItems: 'center',
    },
    button: {
        width: 180,
    },
    errorMessage: {
        color: 'red',
        fontSize: 14,
        marginBottom: 10,
    },
    modalContainer: {
        maxHeight: 300,
    },
    modalItemButtom: {
        paddingTop: 12,
        paddingRight: 8,
        paddingBottom: 12,
        paddingLeft: 8,
        borderBottomColor: '#bbb',
        borderBottomWidth: 1,
    },
    modalItemButtomCheckBox: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    modalItemLabel: {
        color: colors.secondary,
        fontSize: 14,
    },
    version: {
        margin: 20,
        color: '#666',
        textAlign: 'center',
    },
})

export default styles
