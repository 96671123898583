import AsyncStorage from '@react-native-async-storage/async-storage'
import User from '../models/user'

export default class Settings {
    static async setToken(token: string) {
        await AsyncStorage.setItem('token', token)
    }

    static async getToken(): Promise<string | null> {
        return await AsyncStorage.getItem('token')
    }

    static async setWhatsAppNumber(whatsAppNumber: string) {
        await AsyncStorage.setItem('whats_app_number', whatsAppNumber)
    }

    static async getWhatsAppNumber(): Promise<string | null> {
        return await AsyncStorage.getItem('whats_app_number')
    }

    static async setWhatsAppNotification(notification: boolean) {
        await AsyncStorage.setItem(
            'whatsapp_notification',
            notification ? 'true' : 'false',
        )
    }

    static async getWhatsAppNotification(): Promise<boolean> {
        return (await AsyncStorage.getItem('whatsapp_notification')) === 'true'
    }

    static async setEmailNotification(notification: boolean) {
        await AsyncStorage.setItem(
            'email_notification',
            notification ? 'true' : 'false',
        )
    }

    static async getEmailNotification(): Promise<boolean> {
        return (await AsyncStorage.getItem('email_notification')) === 'true'
    }

    static async setCompanyMasterId(companyMasterId: number) {
        await AsyncStorage.setItem('company_master_id', `${companyMasterId}`)
    }

    static async getCompanyMasterId(): Promise<number | null> {
        const id = await AsyncStorage.getItem('company_master_id')
        return id ? Number(id) : null
    }

    static async removeToken() {
        await AsyncStorage.removeItem('token')
    }

    static async setUser(user: User) {
        await AsyncStorage.setItem('user', JSON.stringify(user.toJson()))
    }

    static async getUser(): Promise<User | null> {
        const userStr = await AsyncStorage.getItem('user')
        if (userStr) {
            const json = JSON.parse(userStr)
            return new User(json)
        }
        return null
    }

    static async setFilter(filterId: number) {
        await AsyncStorage.setItem('filter_id', filterId.toString())
    }

    static async getFilter(): Promise<number> {
        const filterId = await AsyncStorage.getItem('filter_id')
        return filterId ? Number(filterId) : 0
    }

    static async clear() {
        await AsyncStorage.removeItem('token')
        await AsyncStorage.removeItem('user')
        await AsyncStorage.removeItem('company_master_id')
    }
}
