import React from 'react'
import { Text, View } from 'react-native'
import globalStyles from '../../styles/styles'

interface Props {
    label: string
    text?: string
    styles?: any
}

const LabelText = ({ label, text, styles }: Props) => {
    return (
        <View style={styles}>
            <Text style={globalStyles.formLabelValue}>{label}</Text>
            {text ? (
                <Text style={[globalStyles.formTextValue]}>{text}</Text>
            ) : null}
        </View>
    )
}

export default LabelText
