import React from 'react'
import { Image, StyleSheet, View } from 'react-native'
import checkOff from '../../../assets/images/ic_checkbox_off.png'
import checkOn from '../../../assets/images/ic_checkbox_on.png'

type CheckBoxProps = {
    checked?: boolean
    onValueChanged?: (checked: boolean) => void
}

export default function CheckBox({ checked }: CheckBoxProps) {
    return (
        <View style={styles.container}>
            <Image style={styles.check} source={checked ? checkOn : checkOff} />
        </View>
    )
}

const checkSize = 32

const styles = StyleSheet.create({
    container: {},
    check: {
        width: checkSize,
        height: checkSize,
    },
})
