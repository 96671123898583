import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
    container: {
        padding: 16,
        borderWidth: 1,
        borderRadius: 8,
    },
    containerSuccess: {
        backgroundColor: '#c0e1fc',
        borderColor: '#a8d5fb',
    },
    containerError: {
        backgroundColor: '#fcd1d5',
        borderColor: '#fabdc4',
    },
    containerNone: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
    },
    text: {
        fontSize: 14,
    },
    textSuccess: {
        color: '#003975',
    },
    textError: {
        color: '#6d1722',
    },
    textNone: {
        color: '#303539',
    },
})

export default styles
