import React, { useEffect, useState } from 'react'
import { ScrollView } from 'react-native'
import Spinner from '../../../../../components/Spinner'
import Role from '../../../../../models/role'
import RoleFormDetail from './RoleFormDetail'
import RoleFormEditing from './RoleFormEditing'
import styles from './styles'

interface Props {
    originalRole?: Role
    onBack: () => void
}

const RoleForm: React.FC<Props> = ({ originalRole, onBack }) => {
    const [editing, setEditing] = useState(originalRole === undefined)

    const [role, setRole] = useState<Role | undefined>()

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setRole(originalRole)
    }, [originalRole])

    const _setLoading = (val: boolean) => {
        setLoading(val)
    }

    const _toggleEdit = () => {
        if (role) {
            setEditing(!editing)
        }
    }

    const _onRoleUpdated = async (r: Role) => {
        setRole(undefined)
        setTimeout(() => {
            setRole(r)
        }, 300)
    }

    const _content = () => {
        if (editing) {
            return (
                <RoleFormEditing
                    role={role}
                    onRoleUpdated={_onRoleUpdated}
                    onBack={onBack}
                    setLoading={_setLoading}
                    setEditing={val => setEditing(val)}
                />
            )
        } else {
            return (
                <RoleFormDetail
                    role={role}
                    onBack={onBack}
                    toggleEdit={_toggleEdit}
                />
            )
        }
    }

    if (originalRole) {
        if (!role) {
            return null
        }
    }

    return (
        <>
            <ScrollView style={styles.container}>
                <>{_content()}</>
            </ScrollView>

            <Spinner visible={loading} overlayColor={'rgba(0, 0, 0, 0.5)'} />
        </>
    )
}

export default RoleForm
