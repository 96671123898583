import React, { useState } from 'react'
import { Text, View } from 'react-native'
import Input from '../Input'
import styles from './styles'

interface Props {
    title: string
    searchBar?: boolean
    onSearch?: (term: string) => void
    style?: any
    styleTitle?: any
}

const ContentHeader: React.FC<Props> = ({
    title,
    searchBar,
    onSearch,
    style,
    styleTitle,
}) => {
    const [searchTerm, setSearchTerm] = useState('')

    const _onChangeText = (text: string) => {
        if (onSearch) {
            setSearchTerm(text)
            onSearch(text)
        }
    }
    return (
        <View style={[styles.container, style]}>
            <Text style={[styles.title, styleTitle]}>{title}</Text>
            <View style={styles.lineContainer}>
                <View style={styles.lineDetail} />
                <View style={styles.line} />
            </View>
            {searchBar ? (
                <Input
                    placeholder="Buscar"
                    value={searchTerm}
                    onChangeText={_onChangeText}
                />
            ) : null}
        </View>
    )
}

export default ContentHeader
