import React from 'react'
import { GestureResponderEvent, Image, Text, TouchableOpacity, View } from 'react-native'
import styles from './styles'

interface Props {
    icon?: any
    children: string
    active?: boolean
    onPress?: (event: GestureResponderEvent) => void
}

const MenuItem: React.FC<Props> = ({ icon, children, active, onPress }) => {
    return (
        <TouchableOpacity style={[styles.container, active ? styles.active : null]} onPress={onPress}>
            <View style={styles.iconLabelContainer}>
                {icon ? <Image style={styles.icon} source={icon} /> : null}
                <Text style={styles.label}>{children}</Text>
            </View>
        </TouchableOpacity>
    )
}

export default MenuItem
