import React, { useRef, useState } from 'react'
import { StyleSheet, Text } from 'react-native'
import Button from '../../../../components/Button'
import Dropdown from '../../../../components/Dropdown'
import Input from '../../../../components/Input'
import Spinner from '../../../../components/Spinner'
import Statement from '../../../../models/statement'
import { saveStatement } from '../../../../services/api'
import {
    RecurrenceType,
    RecurrenceTypeDropdownItems,
} from '../../../../shared/consts'
import { isEmpty } from '../../../../shared/utils'

export interface StatementFormProps {
    originalStatement?: Statement
    onBack: () => void
}

const StatementForm = ({ originalStatement, onBack }: StatementFormProps) => {
    const [name, setName] = useState(originalStatement?.name || '')
    const [recurrence, setRecurrence] = useState(
        originalStatement?.recurrence || RecurrenceType.monthly.name,
    )
    const [day, setDay] = useState(`${originalStatement?.day || '1'}`)
    const [month, setMonth] = useState(`${originalStatement?.month || '1'}`)
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const _save = async () => {
        if (isEmpty(name)) {
            setErrorMessage('Por favor, informe o nome da declaração.')
        } else {
            setErrorMessage('')
            setLoading(true)
            try {
                const data = {
                    statement: {
                        id: 0,
                        name: name.trim(),
                        recurrence: recurrence,
                        day: Number(day),
                        month:
                            recurrence === RecurrenceType.yearly.name
                                ? Number(month)
                                : -1,
                    },
                }
                if (originalStatement) {
                    data.statement.id = originalStatement.id
                }

                const result = await saveStatement(data)
                if (originalStatement && result) {
                    // setEditing(false)
                    // const r = new Statement(result.statement)
                    // onStatementUpdated(r)
                } else {
                    onBack()
                }
            } catch (e) {
                console.log('RoleFormEditing exception: ', e)
                setErrorMessage(
                    'Erro inesperado. Verifique sua conexão com a internet e tente novamente.',
                )
            } finally {
                if (originalStatement) {
                    setLoading(false)
                }
            }
        }
    }

    const dayRef = useRef()
    const monthRef = useRef()

    return (
        <>
            {errorMessage.length > 0 ? (
                <Text style={styles.errorMessage}>{errorMessage}</Text>
            ) : null}
            <Input
                nextRef={dayRef}
                returnKeyType="next"
                autoCapitalize="words"
                style={{ marginBottom: 12 }}
                label="Nome"
                value={name}
                onChangeText={setName}
            />
            <Input
                ref={dayRef}
                nextRef={monthRef}
                returnKeyType="next"
                autoCapitalize="words"
                style={{ marginBottom: 12 }}
                label="Dia"
                value={day}
                onChangeText={setDay}
            />
            {recurrence === RecurrenceType.yearly.name ? (
                <Input
                    ref={monthRef}
                    returnKeyType="done"
                    autoCapitalize="words"
                    style={{ marginBottom: 12 }}
                    label="Mês (em número)"
                    value={month}
                    onChangeText={setMonth}
                />
            ) : null}
            <Dropdown
                label="Recorrência"
                selectedLabel={recurrence}
                modalTitle="Escolha a recorrência"
                items={RecurrenceTypeDropdownItems}
                placeholder="Escolha recorrência"
                onItemSelected={item => setRecurrence(item.label)}
            />

            <Button
                primary
                style={{ marginTop: 12, marginBottom: 10 }}
                label="Salvar"
                onPress={_save}
            />
            <Button
                secondary
                style={{ marginBottom: 50 }}
                label="Cancelar"
                onPress={onBack}
            />
            <Spinner visible={loading} />
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    errorMessage: {
        color: 'red',
        fontSize: 14,
        marginBottom: 10,
    },
})

export default StatementForm
