import { Image, Text, TouchableOpacity, View } from 'react-native'

import React from 'react'
import Role from '../../../../../models/role'
import styles from './styles'

interface Props {
    role: Role
    onSelected: (role: Role) => void
    onRemove: (role: Role) => void
}

const RoleItem: React.FC<Props> = ({ role, onSelected, onRemove }) => {
    return (
        <TouchableOpacity
            style={styles.container}
            onPress={() => onSelected(role)}
        >
            <View>
                <Text style={styles.name}>{role.name}</Text>
                <Text>
                    Este setor tem responsável por empresa?{' '}
                    <Text
                        style={[
                            styles.responsible,
                            !role.selectableAsCompanyResponsible
                                ? styles.responsibleNo
                                : null,
                        ]}
                    >
                        {role.selectableAsCompanyResponsible ? 'SIM' : 'NÃO'}
                    </Text>
                </Text>
            </View>
            <TouchableOpacity onPress={() => onRemove(role)}>
                <Image
                    style={styles.iconRemove}
                    source={require('../../../../../../assets/images/ic_remove.png')}
                />
            </TouchableOpacity>
        </TouchableOpacity>
    )
}

export default RoleItem
