import React from 'react'
import { Image, Platform, Text, TouchableOpacity, View } from 'react-native'
import styles from './styles'

const CModal =
    Platform.OS !== 'web'
        ? require('react-native-modal').default
        : require('modal-enhanced-react-native-web').default

interface Props {
    isVisible?: boolean
    isCancelable?: boolean
    onDismiss?: () => void
    children?: any
    titleIcon?: any
    title?: string
    showCloseButton?: boolean
}

const Modal: React.FC<Props> = ({
    titleIcon,
    title,
    showCloseButton,
    isVisible,
    isCancelable,
    onDismiss,
    children,
}) => {
    const handleDismiss = () => {
        if (onDismiss) {
            onDismiss()
        }
    }

    const handleBackdropPressed = () => {
        if (isCancelable && onDismiss) {
            onDismiss()
        }
    }

    return (
        <CModal
            isVisible={isVisible || false}
            onBackdropPress={handleBackdropPressed}
            animationIn={'bounceInDown'}
            animationOut={'bounceOutUp'}
        >
            <View style={styles.container}>
                <View style={styles.header}>
                    <View style={styles.headerIconTitleContainer}>
                        {titleIcon && (
                            <Image
                                style={styles.headerIcon}
                                source={titleIcon}
                            />
                        )}
                        <Text
                            style={[
                                styles.headerTitle,
                                { marginLeft: titleIcon ? 12 : 0 },
                            ]}
                        >
                            {title}
                        </Text>
                    </View>

                    {showCloseButton && (
                        <TouchableOpacity
                            style={styles.buttonClose}
                            onPress={handleDismiss}
                        >
                            <Image
                                style={styles.iconClose}
                                source={require('../../../assets/images/ic_close_detail.png')}
                            />
                        </TouchableOpacity>
                    )}
                </View>
                <View style={styles.body}>{children}</View>
            </View>
        </CModal>
    )
}

export default Modal
