import React from 'react'
import { Image, Text, TouchableOpacity, View } from 'react-native'
import User from '../../../../../models/user'
import { SystemRole } from '../../../../../shared/consts'
import { formatDate } from '../../../../../shared/date'
import styles from './styles'

interface Props {
    user: User
    onSelected: (user: User) => void
}

const EmployeeItem: React.FC<Props> = ({ user, onSelected }) => {
    return (
        <TouchableOpacity
            style={styles.container}
            onPress={() => onSelected(user)}
        >
            <View>
                <Text
                    style={[styles.name, user.removed ? styles.removed : null]}
                >
                    {user.name}
                    {user.removed ? ' (inativo)' : ''}
                </Text>
                <Text
                    style={[styles.cnpj, user.removed ? styles.removed : null]}
                >
                    {user.email || '[sem e-mail]'}
                </Text>
                <Text
                    style={[styles.cnpj, user.removed ? styles.removed : null]}
                >
                    {user.cpf || ''}
                </Text>
                <Text
                    style={[styles.cnpj, user.removed ? styles.removed : null]}
                >
                    Última atualização dos dados:{' '}
                    {formatDate(user.dateUpdate, 'dd/MM/yy HH:mm')}
                </Text>
            </View>
            {user.systemRole === SystemRole.superadmin ||
            user.systemRole === SystemRole.admin ||
            user.systemRole === SystemRole.dalconEmployeeSuperAdmin ? (
                <Image
                    style={[
                        styles.iconWarning,
                        user.systemRole === SystemRole.dalconEmployeeSuperAdmin
                            ? styles.dalconEmployeeSuperAdmin
                            : null,
                    ]}
                    source={require('../../../../../../assets/images/ic_admin.png')}
                />
            ) : null}
        </TouchableOpacity>
    )
}

export default EmployeeItem
