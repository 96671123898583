import React, { useEffect, useState } from 'react'
import {
    NativeScrollEvent,
    NativeSyntheticEvent,
    RefreshControl,
    ScrollView,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import Button from '../../../../components/Button'
import ContentHeader from '../../../../components/ContentHeader'
import FabButton from '../../../../components/FabButton'
import Modal from '../../../../components/Modal'
import NoItems from '../../../../components/NoItems'
import Statement from '../../../../models/statement'
import User from '../../../../models/user'
import { getStatements, removeStatement } from '../../../../services/api'
import Settings from '../../../../services/settings'
import { SystemRole } from '../../../../shared/consts'
import metrics from '../../../../styles/metrics'
import StatementForm from './StatementForm'
import StatementItem from './StatementItem'

export interface StatementsFragmentProps {}

const StatementsFragment = () => {
    const [editing, setEditing] = useState(false)
    const [statementEditing, setStatementEditing] = useState<
        Statement | undefined
    >()
    const [statements, setStatements] = useState<Statement[]>([])
    const [loading, setLoading] = useState(false)
    const [refreshing, setRefreshing] = useState(false)
    const [statementRemoving, setStatementRemoving] = useState<
        Statement | undefined
    >(undefined)

    const [searchTerm, setSearchTerm] = useState('')

    const [user, setUser] = useState<User | null>()

    useEffect(() => {
        ;(async () => {
            const _user = await Settings.getUser()
            setUser(_user)
        })()
    }, [])

    const _getStatements = async () => {
        setLoading(true)
        getStatements()
            .then(result => {
                if (result && Array.isArray(result)) {
                    const _statements = [] // page === 0 ? [] : [...users]
                    for (const statement of result) {
                        if (
                            _statements.find(i => i.id === statement.id) ===
                            undefined
                        ) {
                            _statements.push(new Statement(statement))
                        }
                    }
                    setStatements(_statements)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        _getStatements()
    }, [])

    const _onRefresh = () => {
        // setPage(0)
        setRefreshing(true)
        setStatements([])
        _getStatements()
    }

    const _closeEditing = () => {
        setStatementEditing(undefined)
        setEditing(false)
        // setPage(0)
        setStatements([])
        _getStatements()
    }

    const _add = () => {
        setStatementEditing(undefined)
        setEditing(true)
    }

    const _edit = (s: Statement) => {
        setStatementEditing(s)
        setEditing(true)
    }

    const _fullRefresh = () => {
        setStatementEditing(undefined)
        setEditing(false)
        setStatements([])
        _getStatements()
    }

    const _remove = (s: Statement) => {
        setStatementRemoving(s)
    }

    const _doRemove = async () => {
        const id = statementRemoving?.id || 0
        try {
            await removeStatement(id)
        } catch (e) {
            console.log('StatementsFragment _doRemove exception: ', e)
        }
        setStatementRemoving(undefined)
        _fullRefresh()
    }

    const _isCloseToBottom = (
        event: NativeSyntheticEvent<NativeScrollEvent>,
    ) => {
        const { layoutMeasurement, contentOffset, contentSize } =
            event.nativeEvent

        const paddingToBottom = 20
        const close =
            layoutMeasurement.height + contentOffset.y >=
            contentSize.height - paddingToBottom
        if (close) {
            //     if (!updating && hasMoreItems) {
            //         dealsGet(false, false)
            //     }
        }
    }

    const _content = () => {
        let stats = [...statements]
        if (searchTerm.trim().length > 0) {
            stats = []
            for (const statment of statements) {
                if (
                    statment.name
                        .toLocaleLowerCase()
                        .indexOf(searchTerm.toLowerCase()) >= 0
                ) {
                    stats.push(statment)
                }
            }
        }
        return editing ? (
            <StatementForm
                originalStatement={statementEditing}
                onBack={_closeEditing}
            />
        ) : stats.length > 0 ? (
            <>
                {stats.map(statement => (
                    <StatementItem
                        key={statement.id}
                        statement={statement}
                        onSelected={s => _edit(s)}
                        onRemove={s => _remove(s)}
                        user={user}
                    />
                ))}
            </>
        ) : null
    }

    if (!user) {
        return <View />
    }

    return (
        <>
            <ScrollView
                style={styles.container}
                onScroll={_isCloseToBottom}
                scrollEventThrottle={400}
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={_onRefresh}
                    />
                }
            >
                <>
                    <ContentHeader
                        title="Declarações"
                        searchBar={!editing}
                        onSearch={term => {
                            setSearchTerm(term)
                        }}
                    />
                    {_content()}
                </>
            </ScrollView>
            {statements.length === 0 && !loading && !editing ? (
                <NoItems />
            ) : null}
            {SystemRole.isSuperAdmin(user?.systemRole || 0) && !editing ? (
                <FabButton
                    icon={require('../../../../../assets/images/ic_plus.png')}
                    onPress={_add}
                />
            ) : null}
            <Modal isVisible={statementRemoving !== undefined} title="Atenção">
                <Text style={styles.modalText}>
                    Tem certeza que deseja remover o item '
                    {statementRemoving?.name}
                    '?
                </Text>
                <Button
                    primary
                    style={{ marginTop: 12, marginBottom: 10 }}
                    label="Sim"
                    onPress={_doRemove}
                />
                <Button
                    secondary
                    style={{ marginBottom: 10 }}
                    label="Cancelar"
                    onPress={() => setStatementRemoving(undefined)}
                />
            </Modal>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: metrics.padding,
    },
    modalText: {
        fontSize: 16,
        marginTop: 8,
        marginBottom: 8,
    },
})

export default StatementsFragment
