import Document from './document'
import User from './user'

class PendencyComment {
    // Comentário da Solicitação / Request
    id: number
    description: string
    dateCreate: Date
    dateStarted: Date

    user?: User
    documents: Document[]

    constructor(json: any) {
        this.id = json?.id || 0
        this.description = json?.description || ''
        this.dateCreate = json?.dateCreate
        this.dateStarted = json?.dateStarted

        this.user = json?.user
        this.documents = []
        if (json && json.documents) {
            this.documents = json.documents
        }
    }

    getShortDescription(): string {
        if (this.description) {
            return this.description.length > 50
                ? `${this.description.substr(0, 50)}...`
                : this.description
        }
        return ''
    }

    static createEmpty(): PendencyComment {
        return new PendencyComment(undefined)
    }
}

export default PendencyComment
