import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
    Image,
    Keyboard,
    ScrollView,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'
import Dropdown, { DropdownItem } from '../../../../../../components/Dropdown'
import {
    checkCEP,
    getCity,
    getCompany,
    getCompanyRolesResponsible,
    getCompanySituations,
    getCompanyTypes,
    getOwner,
    getPhones,
    getRegimes,
    getRoles,
    getServiceTypes,
    getState,
    getStatements,
    getUsersByRoleId,
    saveCompany,
    saveCompanyRolesResponsible,
} from '../../../../../../services/api'

import Button from '../../../../../../components/Button'
import ContentHeader from '../../../../../../components/ContentHeader'
import Input from '../../../../../../components/Input'
import Company from '../../../../../../models/company'
import CompanySituation from '../../../../../../models/company-situation'
import CompanyType from '../../../../../../models/company-type'
import Phone from '../../../../../../models/phone'
import Regime from '../../../../../../models/regime'
import Role from '../../../../../../models/role'
import ServiceType from '../../../../../../models/service-type'
import Statement from '../../../../../../models/statement'
import User from '../../../../../../models/user'
import Settings from '../../../../../../services/settings'
import { isEmpty } from '../../../../../../shared/utils'
import styles from './styles'

interface Props {
    companyId: number
    onBack: () => void
    setLoading: (val: boolean) => void
    setEditing: (val: boolean) => void
    onCompanyUpdated: (c: Company) => void
}

const CompanyFormEditing: React.FC<Props> = ({
    companyId,
    onBack,
    setLoading,
    setEditing,
    onCompanyUpdated,
}) => {
    // const logger = useLogger(LoggerTags.formEditing)
    const [errorMessage, setErrorMessage] = useState('')

    const [company, setCompany] = useState<Company | undefined>()

    const [name, setName] = useState('')
    const [cnpj, setCnpj] = useState('')
    const [cpf, setCpf] = useState('')
    const [address, setAddress] = useState('')
    const [addressNumber, setAddressNumber] = useState('')
    const [cep, setCep] = useState('')
    const [additionalInformation, setAdditionalInformation] = useState('')
    const [cnaes, setCnaes] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')

    const [usingCEPAPI, setUsingCEPAPI] = useState(false)

    const [ownerId, setOwnerId] = useState(0)
    const [ownerName, setOwnerName] = useState('')
    const [ownerCPF, setOwnerCPF] = useState('')
    const [ownerEmail, setOwnerEmail] = useState('')
    const [ownerPassword, setOwnerPassword] = useState('')

    const [phones, setPhones] = useState<Phone[]>([])

    const [regimeId, setRegimeId] = useState(0)
    const [regime, setRegime] = useState('')

    const [companyServiceTypes, setCompanyServiceTypes] = useState<
        ServiceType[]
    >([])
    const [companyCompanyTypes, setCompanyCompanyTypes] = useState<
        CompanyType[]
    >([])

    const [companyServiceTypesD, setCompanyServiceTypesD] = useState<
        DropdownItem[]
    >([])
    const [companyCompanyTypesD, setCompanyCompanyTypesD] = useState<
        DropdownItem[]
    >([])

    const [companyStatements, setCompanyStatements] = useState<CompanyType[]>(
        [],
    )

    const [companyStatementsD, setCompanyStatementsD] = useState<
        DropdownItem[]
    >([])

    const [setDropdownOptions, setSetDropdownOptions] = useState(true)

    const [regimes, setRegimes] = useState<DropdownItem[]>([])
    const [serviceTypes, setServiceTypes] = useState<DropdownItem[]>([])
    const [companyTypes, setCompanyTypes] = useState<DropdownItem[]>([])
    const [statements, setStatements] = useState<DropdownItem[]>([])

    const [situations, setSituations] = useState<CompanySituation[]>([])
    const [situationsD, setSituationsD] = useState<DropdownItem[]>([])
    const [situation, setSituation] = useState<CompanySituation | null>(null)

    // const [roles, setRoles] = useState<Role[]>([])
    // const [roleEmployees, setRoleEmployees] = useState<User[]>([])
    const [rolesDropdownItems, setRolesDropdownItems] = useState<
        DropdownItem[]
    >([])
    const [roleEmployeesDropdownItems, setRoleEmployeesDropdownItems] =
        useState<DropdownItem[]>([])

    const inputCnpj = useRef()
    const inputCpf = useRef()
    const inputComplemento = useRef()
    const inputCnaes = useRef()
    const inputAddress = useRef()
    const inputAddressNumber = useRef()
    const inputCep = useRef()
    const inputCity = useRef()
    const inputState = useRef()

    const inputOwnerName = useRef()
    const inputOwnerCPF = useRef()
    const inputOwnerEmail = useRef()
    const inputOwnerPassword = useRef()

    useEffect(() => {
        ;(async () => {
            if (company) {
                return
            }
            try {
                // update company
                const _company = await getCompany(companyId || 0)
                if (!_company) {
                    return
                }
                setName(_company.name)
                setCnpj(_company.cnpj)
                setCpf(_company.cpf)
                if (_company.companySituation) {
                    setSituation(_company.companySituation)
                }
                setAddress(_company.address)
                setAdditionalInformation(_company.additionalInformation)
                setCep(_company.cep)
                setAddressNumber(_company.addressNumber)
                setCnaes(_company.cnaes)
                setCompany(_company)
                if (_company.serviceType) {
                    if (
                        _company.serviceType.length > 0 &&
                        companyServiceTypes.length === 0
                    ) {
                        setCompanyServiceTypes(_company.serviceType)
                        setCompanyServiceTypesD(
                            _company.serviceType.map(
                                i => new DropdownItem(i.id, i.name, true),
                            ),
                        )
                    }
                }
                if (_company.companyType) {
                    if (
                        _company.companyType.length > 0 &&
                        companyCompanyTypes.length === 0
                    ) {
                        setCompanyCompanyTypes(_company.companyType)
                        setCompanyCompanyTypesD(
                            _company.companyType.map(
                                i => new DropdownItem(i.id, i.name, true),
                            ),
                        )
                    }
                }
                if (_company.statements) {
                    if (
                        _company.statements.length > 0 &&
                        companyStatements.length === 0
                    ) {
                        setCompanyStatements(_company.statements)
                        setCompanyStatementsD(
                            _company.statements.map(
                                i =>
                                    new DropdownItem(
                                        i.id,
                                        `${i.name}, recorrência: ${i.recurrence}, dia: ${i.day}`,
                                        true,
                                    ),
                            ),
                        )
                    }
                }
                // city
                const cityResponse = await getCity(_company.cityId || 0)
                if (cityResponse.id) {
                    setCity(cityResponse.name || '')
                }
                // state
                const stateResponse = await getState(_company.stateId || 0)
                if (stateResponse.id) {
                    setState(stateResponse.name || '')
                }
                try {
                    // owner
                    const owner = await getOwner(_company.id || 0)
                    if (owner) {
                        const user = new User(owner)
                        setOwnerName(user.name || '')
                        setOwnerEmail(user.email || '')
                        setOwnerCPF(user.cpf || '')
                    }
                } catch (e) {
                    console.log('CompanyFormDetail useEffect ERROR: ', e)
                }

                // phones
                const phonesResponse = await getPhones(_company.id || 0)
                if (phonesResponse) {
                    const _phones: Phone[] = []
                    for (const phone of phonesResponse) {
                        _phones.push(new Phone(phone))
                    }
                    if (_phones.length === 0) {
                        _phones.push(new Phone({ companyId: _company.id || 0 }))
                    }
                    setPhones(_phones)
                }

                // roles
                const companyMasterId = await Settings.getCompanyMasterId()
                if (companyMasterId) {
                    const _roles: Role[] = []
                    const _employees: User[] = []
                    const _roleResponsible: {
                        roleId: number
                        userId: number
                    }[] = []
                    const _rolesResponse = await getRoles(companyMasterId)
                    for (const _role of _rolesResponse) {
                        const _r = new Role(_role)
                        if (_r.selectableAsCompanyResponsible) {
                            const _employeesByRole = await getUsersByRoleId(
                                companyMasterId,
                                _r.id,
                            )
                            for (const _employeeByRole of _employeesByRole) {
                                _employeeByRole.roleId = _r.id
                                _employees.push(new User(_employeeByRole))
                            }
                            if (_employeesByRole.length) {
                                _roles.push(_r)
                                if (companyId > 0) {
                                    try {
                                        const _resp =
                                            await getCompanyRolesResponsible(
                                                companyId,
                                                _r.id,
                                            )
                                        if (
                                            _resp &&
                                            _resp.user &&
                                            _resp.user.id
                                        ) {
                                            _roleResponsible.push({
                                                roleId: _r.id,
                                                userId: _resp.user.id,
                                            })
                                        }
                                    } catch (eee) {
                                        console.error(
                                            'getCompanyRolesResponsible exception: ',
                                            eee,
                                        )
                                    }
                                }
                            }
                        }
                    }
                    // setRoles(_roles)
                    // setRoleEmployees(_employees)
                    const _rolesDropdownItems = _roles.map(
                        i => new DropdownItem(i.id, i.name),
                    )
                    const _roleEmployeesDropdownItems = _employees.map(i => {
                        const d = new DropdownItem(
                            i.id,
                            `${i.name} (${i.email})`,
                        )
                        d.extra1 = i.roleId
                        d.checked =
                            _roleResponsible.find(
                                rs =>
                                    rs.roleId === i.roleId &&
                                    rs.userId === i.id,
                            ) !== undefined
                        return d
                    })
                    setRolesDropdownItems(_rolesDropdownItems)
                    setRoleEmployeesDropdownItems(_roleEmployeesDropdownItems)

                    // situations
                    const situationsResponse = await getCompanySituations()
                    if (situationsResponse && situationsResponse.situations) {
                        setSituations(situationsResponse.situations)
                        if (_company.companySituation) {
                            setSituation(_company.companySituation)
                        }

                        setSituationsD(
                            situationsResponse.situations.map((i: any) => {
                                return new DropdownItem(
                                    i.id,
                                    i.name,
                                    i.id === _company.companySituationId,
                                )
                            }),
                        )
                    }
                }
            } catch (e) {
                console.error('CompanyFormDetails init exception: ', e)
            }
        })()
    }, [
        company,
        companyCompanyTypes,
        companyId,
        companyServiceTypes,
        companyStatements,
    ])

    useEffect(() => {
        if (!setDropdownOptions) {
            return
        }
        setSetDropdownOptions(false)
        getCompanyTypes()
            .then(result => {
                if (result && Array.isArray(result)) {
                    const _companyTypes = [] // page === 0 ? [] : [...users]
                    for (const _companyType of result) {
                        if (
                            _companyTypes.find(
                                i => i.id === _companyType.id,
                            ) === undefined
                        ) {
                            const item = new CompanyType(_companyType)
                            _companyTypes.push(
                                new DropdownItem(item.id, item.name),
                            )
                        }
                    }
                    setCompanyTypes(_companyTypes)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                // setLoading(false)
            })
        getServiceTypes()
            .then(result => {
                if (result && Array.isArray(result)) {
                    const _serviceTypes = [] // page === 0 ? [] : [...users]
                    for (const _serviceType of result) {
                        if (
                            _serviceTypes.find(
                                i => i.id === _serviceType.id,
                            ) === undefined
                        ) {
                            const item = new ServiceType(_serviceType)
                            _serviceTypes.push(
                                new DropdownItem(item.id, item.name),
                            )
                        }
                    }
                    setServiceTypes(_serviceTypes)
                    // if (company) {
                    //     const i = _serviceTypes.find(
                    //         e => e.id === company.serviceTypeId,
                    //     )
                    //     if (i) {
                    //         setServiceType(i.label)
                    //     }
                    // }
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                // setLoading(false)
            })
        getRegimes()
            .then(result => {
                if (result && Array.isArray(result)) {
                    const _regimes = [] // page === 0 ? [] : [...users]
                    for (const _regime of result) {
                        if (
                            _regimes.find(i => i.id === _regime.id) ===
                            undefined
                        ) {
                            const item = new Regime(_regime)
                            _regimes.push(new DropdownItem(item.id, item.name))
                        }
                    }
                    setRegimes(_regimes)

                    if (company) {
                        const i = _regimes.find(e => e.id === company.regimeId)
                        if (i) {
                            setRegime(i.label)
                        }
                    }
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                // setLoading(false)
            })
        getStatements().then(result => {
            if (result && Array.isArray(result)) {
                const _statements = []
                for (const _statement of result) {
                    if (
                        _statements.find(i => i.id === _statement.id) ===
                        undefined
                    ) {
                        const item = new Statement(_statement)
                        _statements.push(
                            new DropdownItem(
                                item.id,
                                `${item.name}, recorrência: ${item.recurrence}, dia: ${item.day}`,
                            ),
                        )
                    }
                }
                setStatements(_statements)
            }
        })
    }, [company, regimes, setDropdownOptions])

    const _checkCEP = useCallback(async () => {
        if (!isEmpty(cep)) {
            try {
                const response = await checkCEP(cep)
                if (response) {
                    if (response.localidade) {
                        setCity(response.localidade)
                    }
                    if (response.logradouro) {
                        setAddress(response.logradouro)
                    }
                    if (response.uf) {
                        setState(response.uf)
                    }
                    setUsingCEPAPI(true)
                }
            } catch (e) {
                console.log('_checkCEP exception: ', e)
            }
        }
    }, [cep])

    useEffect(() => {
        if (company !== undefined && company.id > 0) {
            ;(async () => {
                try {
                    // cep
                    if (cep) {
                        _checkCEP()
                    }
                    try {
                        // owner
                        const owner = await getOwner(company?.id || 0)
                        if (owner) {
                            const user = new User(owner)
                            setOwnerId(user.id)
                            setOwnerName(user.name)
                            setOwnerEmail(user.email)
                        }
                    } catch (e) {
                        console.log(
                            'useEffect CompanyFormEditing exception: ',
                            e,
                        )
                    }

                    // phones
                    const response = await getPhones(company.id || 0)
                    if (response) {
                        const _phones: Phone[] = []
                        for (const phone of response) {
                            _phones.push(new Phone(phone))
                        }
                        if (_phones.length === 0) {
                            _phones.push(
                                new Phone({ companyId: company.id || 0 }),
                            )
                        }
                        setPhones(_phones)
                    }
                } catch (e) {
                    console.log('CompanyFormEditing init exception: ', e)
                }
            })()
        } else {
            const _phonesCopy = []
            _phonesCopy.push(new Phone({ companyId: 0 }))
            setPhones(_phonesCopy)
        }
    }, [_checkCEP, cep, company])

    const _addPhone = () => {
        const _phonesCopy = [...phones]
        _phonesCopy.push(new Phone({ companyId: company?.id || 0 }))
        setPhones(_phonesCopy)
    }

    const _removePhone = (index: number) => {
        const _phonesCopy = [...phones]
        _phonesCopy.splice(index, 1)
        if (_phonesCopy.length > 0) {
            setPhones(_phonesCopy)
        }
    }

    const _save = async () => {
        if (isEmpty(name)) {
            setErrorMessage('Por favor, informe o nome da empresa.')
            // } else if (isEmpty(cnpj)) {
            //     setErrorMessage('Por favor, informe o CNPJ da empresa.')
        } else {
            setErrorMessage('')
            setLoading(true)
            try {
                const data = {
                    company: {
                        id: 0,
                        name: name.trim(),
                        cnpj: (cnpj || '').trim(),
                        cpf: (cpf || '').trim(),
                        additionalInformation,
                        cnaes,
                        address,
                        addressNumber,
                        cep,
                        city,
                        state,
                        regimeId,
                        companySituationId: situation?.id || 0,
                        // serviceTypeId,
                        // companyTypeId,
                        companyType:
                            companyCompanyTypes.length > 0
                                ? companyCompanyTypes
                                : company?.companyType || [],
                        serviceType:
                            companyServiceTypes.length > 0
                                ? companyServiceTypes
                                : company?.serviceType || [],
                        statements:
                            companyStatements.length > 0
                                ? companyStatements
                                : company?.statements || [],
                    },
                    phones,
                    admin: {
                        id: ownerId,
                        name: ownerName.trim(),
                        email: ownerEmail.trim(),
                        cpf: ownerCPF.trim(),
                        password: ownerPassword.trim(),
                    },
                }
                if (company) {
                    data.company.id = company.id
                }

                console.log('data: ', data)

                const result = await saveCompany(data)

                for (const employee of roleEmployeesDropdownItems) {
                    if (employee.checked) {
                        try {
                            await saveCompanyRolesResponsible(
                                result.company.id,
                                employee.extra1 || 0,
                                employee.id,
                            )
                        } catch (ee) {
                            console.log('', ee)
                        }
                    }
                }
                setLoading(false)
                if (company && company.id) {
                    const c = result.company
                        ? new Company(result.company)
                        : company
                    c.pendencies = company.pendencies
                    if (result.error) {
                        setErrorMessage(result.error)
                    } else {
                        onCompanyUpdated(c)
                        setEditing(false)
                    }
                } else {
                    onBack()
                }
            } catch (e) {
                console.log('CompanyForm exception: ', e)
                setLoading(false)
                setErrorMessage(
                    'Erro inesperado. Verifique sua conexão com a internet e tente novamente.',
                )
            }
        }
    }

    return (
        <ScrollView style={{ flex: 1 }}>
            <View>
                {errorMessage.length > 0 ? (
                    <Text style={styles.errorMessage}>{errorMessage}</Text>
                ) : null}
                <Input
                    nextRef={inputCnpj}
                    returnKeyType="next"
                    autoCapitalize="words"
                    style={{ marginBottom: 12 }}
                    label="Nome"
                    value={name}
                    onChangeText={text => setName(text)}
                />
                <Input
                    ref={inputCnpj}
                    nextRef={inputCpf}
                    returnKeyType="next"
                    keyboardType="numeric"
                    autoCapitalize="words"
                    maskType="cnpj"
                    style={{ marginBottom: 12 }}
                    label="CNPJ"
                    value={cnpj}
                    onChangeText={text => setCnpj(text)}
                />
                <Input
                    ref={inputCpf}
                    nextRef={inputComplemento}
                    returnKeyType="next"
                    keyboardType="numeric"
                    autoCapitalize="words"
                    maskType="cpf"
                    style={{ marginBottom: 12 }}
                    label="CPF"
                    value={cpf}
                    onChangeText={text => setCpf(text)}
                />
                <Dropdown
                    label="Situação"
                    selectedLabel={situation?.name || ''}
                    modalTitle="Escolha a situação"
                    items={situationsD}
                    placeholder="Escolha a situação"
                    onItemSelected={item => {
                        const s = situations.find(i => i.id === item.id)
                        console.log('situations: ', s)
                        if (s) {
                            setSituation(s)
                        }
                    }}
                />
                <Input
                    ref={inputComplemento}
                    nextRef={inputCnaes}
                    returnKeyType="next"
                    autoCapitalize="sentences"
                    style={{ marginBottom: 12 }}
                    label="Complemento"
                    value={additionalInformation}
                    onChangeText={text => setAdditionalInformation(text)}
                />
                <Input
                    ref={inputCnaes}
                    nextRef={inputCep}
                    returnKeyType="next"
                    autoCapitalize="sentences"
                    style={{ marginBottom: 12 }}
                    label="CNAEs"
                    value={cnaes}
                    onChangeText={text => setCnaes(text)}
                />
                <Input
                    ref={inputCep}
                    nextRef={inputAddress}
                    returnKeyType="next"
                    keyboardType="numeric"
                    maskType="zip-code"
                    style={{ marginBottom: 12 }}
                    label="CEP"
                    value={cep}
                    onBlur={_checkCEP}
                    onChangeText={text => setCep(text)}
                />
                <Input
                    ref={inputAddress}
                    nextRef={inputAddressNumber}
                    returnKeyType="next"
                    autoCapitalize="words"
                    editable={!usingCEPAPI || address.length === 0}
                    style={{ marginBottom: 12 }}
                    label="Endereço"
                    value={address}
                    onChangeText={text => setAddress(text)}
                />
                <Input
                    ref={inputAddressNumber}
                    nextRef={inputCity}
                    returnKeyType="next"
                    autoCapitalize="words"
                    style={{ marginBottom: 12 }}
                    label="Número"
                    value={addressNumber}
                    onChangeText={text => setAddressNumber(text)}
                />

                <Input
                    ref={inputCity}
                    nextRef={inputState}
                    returnKeyType="next"
                    autoCapitalize="words"
                    editable={!usingCEPAPI || city.length === 0}
                    style={{ marginBottom: 12 }}
                    label="Cidade"
                    value={city}
                    onChangeText={text => setCity(text)}
                />
                <Input
                    ref={inputState}
                    returnKeyType="done"
                    autoCapitalize="words"
                    style={{ marginBottom: 12 }}
                    editable={!usingCEPAPI || state.length === 0}
                    label="Estado"
                    value={state}
                    onSubmitEditing={() => Keyboard.dismiss()}
                    onChangeText={text => setState(text)}
                />
                <Dropdown
                    label="Regime"
                    selectedLabel={regime}
                    modalTitle="Escolha o regime"
                    items={regimes}
                    placeholder="Escolha regime"
                    onItemSelected={item => {
                        setRegimeId(item.id)
                        setRegime(item.label)
                    }}
                />
                <Dropdown
                    label="Tipo de Empresa"
                    modalTitle="Escolha o tipo de empresa"
                    items={companyTypes}
                    selectedItems={companyCompanyTypesD}
                    multi
                    onMultiSelected={items => {
                        const types = items.map(
                            i => new CompanyType({ id: i.id, name: i.label }),
                        )
                        setCompanyCompanyTypes(types)
                    }}
                    selectedLabel={(companyCompanyTypes.length > 0
                        ? companyCompanyTypes
                        : companyCompanyTypesD
                    )
                        .map(i =>
                            (i as any).name
                                ? (i as any).name
                                : (i as any).label,
                        )
                        .join(', ')}
                    placeholder="Escolha tipo de empresa"
                />
                <Dropdown
                    label="Tipo de Serviço"
                    modalTitle="Escolha o tipo de serviço"
                    items={serviceTypes}
                    selectedItems={companyServiceTypesD}
                    multi
                    onMultiSelected={items =>
                        setCompanyServiceTypes(
                            items.map(
                                i =>
                                    new ServiceType({
                                        id: i.id,
                                        name: i.label,
                                    }),
                            ),
                        )
                    }
                    selectedLabel={(companyServiceTypes.length > 0
                        ? companyServiceTypes
                        : companyServiceTypesD
                    )
                        .map(i =>
                            (i as any).name
                                ? (i as any).name
                                : (i as any).label,
                        )
                        .join(', ')}
                    placeholder="Escolha tipo de serviço"
                />
                <Dropdown
                    label="Declarações"
                    modalTitle="Escolha as declarações"
                    items={statements}
                    selectedItems={companyStatementsD}
                    multi
                    onMultiSelected={items =>
                        setCompanyStatements(
                            items.map(
                                i => new Statement({ id: i.id, name: i.label }),
                            ),
                        )
                    }
                    selectedLabel={(companyStatements.length > 0
                        ? companyStatements
                        : companyStatementsD
                    )
                        .map(i =>
                            (i as any).name
                                ? (i as any).name
                                : (i as any).label,
                        )
                        .join(', ')}
                    placeholder="Escolha as declarações"
                />
                {/* RESPONSÁVEL */}
                <ContentHeader title="Responsável" />
                <Input
                    ref={inputOwnerName}
                    nextRef={inputOwnerCPF}
                    returnKeyType="next"
                    autoCapitalize="words"
                    style={{ marginBottom: 12 }}
                    label="Nome do responsável"
                    value={ownerName}
                    onChangeText={text => setOwnerName(text)}
                />
                {errorMessage.length > 0 ? (
                    <Text style={styles.errorMessage}>{errorMessage}</Text>
                ) : null}
                <Input
                    ref={inputOwnerCPF}
                    nextRef={inputOwnerEmail}
                    maskType="cpf"
                    returnKeyType="next"
                    autoCapitalize="words"
                    style={{ marginBottom: 12 }}
                    label="CPF do responsável"
                    value={ownerCPF}
                    onChangeText={text => setOwnerCPF(text)}
                />
                <Input
                    ref={inputOwnerEmail}
                    returnKeyType="done"
                    keyboardType="email-address"
                    autoCapitalize="none"
                    style={{ marginBottom: 12 }}
                    label="E-mail do responsável"
                    help="Será utilizado como nome de usuário administrador"
                    value={ownerEmail}
                    onSubmitEditing={() => Keyboard.dismiss()}
                    onChangeText={text => setOwnerEmail(text)}
                />
                <Text style={styles.ownerPasswordWarning}>
                    <Text style={styles.ownerPasswordWarningBold}>
                        Atenção:{' '}
                    </Text>{' '}
                    {company !== undefined
                        ? 'utilize o campo abaixo com cautela. Apenas altere a senha do usuário administrador se solicitado.'
                        : 'utilize o campo abaixo para definir a senha do administrador. Caso prefira, deixe em branco e uma senha aleatória será gerada e enviada ao administrador.'}
                </Text>
                <Input
                    ref={inputOwnerPassword}
                    returnKeyType="done"
                    autoCapitalize="none"
                    secureTextEntry={true}
                    style={{ marginBottom: 12 }}
                    label="Senha do responsável"
                    help="Será utilizado como senha de administrador"
                    value={ownerPassword}
                    onSubmitEditing={() => Keyboard.dismiss()}
                    onChangeText={text => setOwnerPassword(text)}
                />
                <ContentHeader title="Telefones" />
                {phones.map((phone, index) => {
                    return (
                        <View
                            key={`input_company_phone_${index}`}
                            style={styles.phoneLineContainer}
                        >
                            <Input
                                returnKeyType="done"
                                keyboardType="numeric"
                                maskType="cel-phone"
                                style={[
                                    { marginBottom: 12 },
                                    styles.phoneLineInput,
                                ]}
                                label={`Telefone ${index + 1}`}
                                value={phone.number}
                                onSubmitEditing={() => Keyboard.dismiss()}
                                onChangeText={text => {
                                    const phonesCopy = [...phones]
                                    const _phone = phonesCopy[index]
                                    _phone.number = text
                                    setPhones(phonesCopy)
                                }}
                            />
                            <TouchableOpacity
                                style={styles.phoneLineDeleteButton}
                                onPress={() => _removePhone(index)}
                            >
                                <Image
                                    style={styles.deleteIcon}
                                    source={require('../../../../../../../assets/images/ic_remove.png')}
                                />
                            </TouchableOpacity>
                        </View>
                    )
                })}

                <View style={styles.phonesContainer}>
                    <Button
                        primary
                        style={[
                            { marginTop: 12, marginBottom: 10 },
                            styles.phoneAddButton,
                        ]}
                        label="+"
                        onPress={_addPhone}
                    />
                </View>
                <ContentHeader title="Funcionário da contabilidade responsável" />
                {rolesDropdownItems.map(role => {
                    const employees = roleEmployeesDropdownItems.filter(
                        i => i.extra1 === role.id,
                    )
                    const selected = employees.find(i => i.checked)
                    return (
                        <Dropdown
                            key={`dropdown-employee-responsible-${role.id}`}
                            label={role.label}
                            modalTitle="Escolha o funcionário responsável"
                            items={employees}
                            selectedLabel={selected ? selected.label : ''}
                            placeholder="Escolha o funcionário responsável"
                            onItemSelected={item => {
                                const _roleEmployeesDropdownItems = [
                                    ...roleEmployeesDropdownItems,
                                ]
                                const ee = _roleEmployeesDropdownItems.filter(
                                    i => i.extra1 === role.id,
                                )
                                for (const e of ee) {
                                    e.checked = e.id === item.id
                                }
                                setRoleEmployeesDropdownItems(
                                    _roleEmployeesDropdownItems,
                                )
                            }}
                        />
                        // <Dropdown
                        //     label="Regime"
                        //     selectedLabel={regime}
                        //     modalTitle="Escolha o regime"
                        //     items={regimes}
                        //     placeholder="Escolha regime"
                        //     onItemSelected={item => {
                        //         setRegimeId(item.id)
                        //         setRegime(item.label)
                        //     }}
                        // />
                    )
                })}
                <Button
                    primary
                    style={{ marginTop: 12, marginBottom: 10 }}
                    label="Salvar"
                    onPress={_save}
                />
                <Button
                    secondary
                    style={{ marginBottom: 50 }}
                    label="Cancelar"
                    onPress={() => (company ? setEditing(false) : onBack())}
                />
            </View>
        </ScrollView>
    )
}

export default CompanyFormEditing
