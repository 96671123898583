import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import ContentHeader from '../../../../components/ContentHeader'
import Settings from '../../../../services/settings'
import CompanyFormDocuments from '../CompaniesFragment/CompanyForm/CompanyFormDocuments'

// type DocumentsFragmentProps = {}

export default function DocumentsFragment() {
    const [companyId, setCompanyId] = useState(0)

    useEffect(() => {
        ;(async () => {
            const user = await Settings.getUser()
            setCompanyId(user?.companyId || 0)
        })()
    }, [])
    return (
        <View style={styles.container}>
            <ContentHeader title="Documentos" onSearch={() => {}} />
            <CompanyFormDocuments companyId={companyId} scrollEnabled={false} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
})
