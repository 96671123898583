import React, { createContext, useState } from 'react'

import Alert from '../components/Alert'

type AlertContextData = {
    setTitle: React.Dispatch<React.SetStateAction<string | null | undefined>>
    setMessage: React.Dispatch<React.SetStateAction<string | null | undefined>>
    setButtonTitle: React.Dispatch<
        React.SetStateAction<string | null | undefined>
    >
}

type AlertContextProps = {
    children: React.ReactNode
}

export const AlertContext = createContext({} as AlertContextData)

export const AlertContextProvider = ({ children }: AlertContextProps) => {
    const [title, setTitle] = useState<string | null>()
    const [message, setMessage] = useState<string | null>()
    const [buttonTitle, setButtonTitle] = useState<string | null>()
    // const [negativeButton, setNegativeButton] = useState<string | null>()

    const alertContext: AlertContextData = {
        setTitle,
        setMessage,
        setButtonTitle,
    }

    return (
        <AlertContext.Provider value={alertContext}>
            {children}
            {title && message && buttonTitle ? (
                <Alert
                    title={title || ''}
                    message={message || ''}
                    buttonTitle={buttonTitle || ''}
                />
            ) : null}
        </AlertContext.Provider>
    )
}
