import { StyleSheet } from 'react-native'
import colors from '../../../../../styles/colors'
import { iconSizeNormal } from '../../../../../styles/metrics'

const styles = StyleSheet.create({
    container: {
        paddingTop: 16,
        paddingBottom: 16,
        borderBottomColor: '#bbb',
        borderBottomWidth: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    name: {
        color: colors.secondary,
        fontSize: 18,
        fontWeight: 'bold',
    },
    cnpj: {
        color: '#888',
        marginTop: 4,
    },
    iconWarning: {
        width: iconSizeNormal,
        height: iconSizeNormal,
        tintColor: '#E7D42E',
    },
    dalconEmployeeSuperAdmin: {
        tintColor: '#26f',
    },
    removed: {
        color: 'red',
    },
})

export default styles
