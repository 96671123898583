import React, { useEffect, useState } from 'react'
import {
    NativeScrollEvent,
    NativeSyntheticEvent,
    RefreshControl,
    ScrollView,
    StyleSheet,
    Text,
} from 'react-native'
import Button from '../../../../components/Button'
import ContentHeader from '../../../../components/ContentHeader'
import FabButton from '../../../../components/FabButton'
import Modal from '../../../../components/Modal'
import NoItems from '../../../../components/NoItems'
import CompanyType from '../../../../models/company-type'
import { getCompanyTypes, removeCompanyType } from '../../../../services/api'
import metrics from '../../../../styles/metrics'
import CompanyTypeForm from './CompanyTypeForm'
import CompanyTypeItem from './CompanyTypeItem'

export interface CompanyTypeFragmentProps {}

const CompanyTypeFragment = () => {
    const [editing, setEditing] = useState(false)
    const [companyTypeEditing, setCompanyTypeEditing] = useState<
        CompanyType | undefined
    >()
    const [companyTypes, setCompanyTypes] = useState<CompanyType[]>([])
    const [loading, setLoading] = useState(false)
    const [refreshing, setRefreshing] = useState(false)
    const [companyTypeRemoving, setCompanyTypeRemoving] = useState<
        CompanyType | undefined
    >(undefined)

    const [filter, setFilter] = useState('')

    const _getCompanyTypes = async () => {
        setLoading(true)
        getCompanyTypes()
            .then(result => {
                if (result && Array.isArray(result)) {
                    const _companyTypes = [] // page === 0 ? [] : [...users]
                    for (const companyType of result) {
                        if (
                            _companyTypes.find(i => i.id === companyType.id) ===
                            undefined
                        ) {
                            _companyTypes.push(new CompanyType(companyType))
                        }
                    }
                    setCompanyTypes(_companyTypes)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        _getCompanyTypes()
    }, [])

    const _onRefresh = () => {
        // setPage(0)
        setRefreshing(true)
        setCompanyTypes([])
        _getCompanyTypes()
    }

    const _closeEditing = () => {
        setCompanyTypeEditing(undefined)
        setEditing(false)
        // setPage(0)
        setCompanyTypes([])
        _getCompanyTypes()
    }

    const _add = () => {
        setCompanyTypeEditing(undefined)
        setEditing(true)
    }

    const _edit = (s: CompanyType) => {
        setCompanyTypeEditing(s)
        setEditing(true)
    }

    const _fullRefresh = () => {
        setCompanyTypeEditing(undefined)
        setEditing(false)
        setCompanyTypes([])
        _getCompanyTypes()
    }

    const _remove = (s: CompanyType) => {
        setCompanyTypeRemoving(s)
    }

    const _doRemove = async () => {
        const id = companyTypeRemoving?.id || 0
        try {
            await removeCompanyType(id)
        } catch (e) {
            console.log('StatementsFragment _doRemove exception: ', e)
        }
        setCompanyTypeRemoving(undefined)
        _fullRefresh()
    }

    const _isCloseToBottom = (
        event: NativeSyntheticEvent<NativeScrollEvent>,
    ) => {
        const { layoutMeasurement, contentOffset, contentSize } =
            event.nativeEvent

        const paddingToBottom = 20
        const close =
            layoutMeasurement.height + contentOffset.y >=
            contentSize.height - paddingToBottom
        if (close) {
            //     if (!updating && hasMoreItems) {
            //         dealsGet(false, false)
            //     }
        }
    }

    const _content = () => {
        return editing ? (
            <CompanyTypeForm
                originalCompanyType={companyTypeEditing}
                onBack={_closeEditing}
            />
        ) : (
            <>
                {companyTypes.map(companyType => {
                    if (
                        filter.trim().length === 0 ||
                        companyType.name
                            .toLowerCase()
                            .includes(filter.toLowerCase())
                    ) {
                        return (
                            <CompanyTypeItem
                                key={companyType.id}
                                companyType={companyType}
                                onSelected={s => _edit(s)}
                                onRemove={s => _remove(s)}
                            />
                        )
                    }
                })}
            </>
        )
    }

    return (
        <>
            <ScrollView
                style={styles.container}
                onScroll={_isCloseToBottom}
                scrollEventThrottle={400}
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={_onRefresh}
                    />
                }
            >
                <>
                    <ContentHeader
                        title="Tipos de Empresa"
                        searchBar={!editing}
                        onSearch={term => {
                            setFilter(term)
                        }}
                    />
                    {_content()}
                </>
            </ScrollView>
            {companyTypes.length === 0 && !loading && !editing ? (
                <NoItems />
            ) : null}
            {!editing ? (
                <FabButton
                    icon={require('../../../../../assets/images/ic_plus.png')}
                    onPress={_add}
                />
            ) : null}
            <Modal
                isVisible={companyTypeRemoving !== undefined}
                title="Atenção"
            >
                <Text style={styles.modalText}>
                    Tem certeza que deseja remover o item '
                    {companyTypeRemoving?.name}
                    '?
                </Text>
                <Button
                    primary
                    style={{ marginTop: 12, marginBottom: 10 }}
                    label="Sim"
                    onPress={_doRemove}
                />
                <Button
                    secondary
                    style={{ marginBottom: 10 }}
                    label="Cancelar"
                    onPress={() => setCompanyTypeRemoving(undefined)}
                />
            </Modal>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: metrics.padding,
    },
    modalText: {
        fontSize: 16,
        marginTop: 8,
        marginBottom: 8,
    },
})

export default CompanyTypeFragment
