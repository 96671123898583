import React, { useCallback, useEffect, useState } from 'react'
import {
    Dimensions,
    GestureResponderEvent,
    Image,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'
import { useTimer } from '../../contexts/timer-context'
import TaskTimer from '../../models/task-timer'
import { getTaskTime } from '../../shared/date'
import styles from './styles'

import icStart from '../../../assets/images/ic_play.png'
import icStop from '../../../assets/images/ic_stop.png'
import Settings from '../../services/settings'

interface Props {
    onShowMenu: (event: GestureResponderEvent) => void
}

const Header: React.FC<Props> = ({ onShowMenu }) => {
    const windowWidth = Dimensions.get('window').width

    const [updateTimer, setUpdateTimer] = useState(false)
    const [currentPendencyId, setCurrentPendencyId] = useState(0)
    const [currentTimer, setCurrentTimer] = useState<TaskTimer>()
    const [timerCount, setTimerCount] = useState(0)
    const { timer, getTimer, startTimer, stopTimer, pendencyId } = useTimer()

    const startStopTimer = async () => {
        if (currentTimer) {
            if (currentTimer.dtStop) {
                await startTimer(currentTimer.pendencyId)
            } else {
                await stopTimer()
            }
        }
    }

    const doUpdateTimer = useCallback(async () => {
        try {
            if (!(await Settings.getToken())) {
                return
            }
            await getTimer()
        } catch (e) {
            console.log(e)
        }
    }, [getTimer])

    useEffect(() => {
        if (currentTimer && !currentTimer.dtStop) {
            setTimeout(() => {
                setTimerCount(prev => prev + 1)
            }, 1000)
        }
    }, [currentTimer, timerCount])

    useEffect(() => {
        if (timer !== currentTimer) {
            if (timer) {
                setCurrentTimer(timer)
            }
        }
    }, [currentTimer, timer])

    useEffect(() => {
        if (updateTimer) {
            setUpdateTimer(false)
            doUpdateTimer()
        }
    }, [doUpdateTimer, updateTimer])

    useEffect(() => {
        if (pendencyId !== currentPendencyId) {
            setCurrentPendencyId(pendencyId || 0)
            setUpdateTimer(true)
        }
    }, [currentPendencyId, pendencyId])

    return (
        <View style={styles.container}>
            {windowWidth < 720 ? (
                <TouchableOpacity onPress={onShowMenu}>
                    <Image
                        style={styles.icon}
                        source={require('../../../assets/images/ic_menu.png')}
                    />
                </TouchableOpacity>
            ) : null}
            <Image
                style={styles.logo}
                source={require('../../../assets/images/logo-dalcon.png')}
            />
            {windowWidth < 720 ? (
                <Image
                    style={styles.icon}
                    source={require('../../../assets/images/ic_blank.png')}
                />
            ) : null}
            <View style={styles.timerContainer}>
                <Image
                    style={styles.iconTimer}
                    source={require('../../../assets/images/ic_timer.png')}
                />
                <Text
                    style={[
                        styles.timer,
                        currentTimer ? null : styles.timerNoActivity,
                    ]}
                >
                    {currentTimer
                        ? getTaskTime(currentTimer)
                        : 'Nenhuma atividade em andamento'}
                    {/* {'Nenhuma atividade em andamento'} */}
                </Text>
                {currentTimer ? (
                    <TouchableOpacity onPress={startStopTimer}>
                        <Image
                            style={styles.iconTimer}
                            source={currentTimer?.dtStop ? icStart : icStop}
                        />
                    </TouchableOpacity>
                ) : null}
            </View>
        </View>
    )
}

export default Header
