import React, { useEffect, useState } from 'react'
import Tabs, { tabStyles } from './Tabs'

import { View } from 'react-native'
import Spinner from '../../../../../components/Spinner'
import Company from '../../../../../models/company'
import styles from '../../../../../styles/styles'
import CompanyFormDetail from './CompanyFormDetail'
import CompanyFormDocuments from './CompanyFormDocuments'
import CompanyFormEditing from './CompanyFormEditing'
import CompanyFormPendencies from './CompanyFormPendencies'
import TabItem from './TabItem'

interface Props {
    originalCompany?: Company
    onBack: () => void
}

const CompanyForm: React.FC<Props> = ({ originalCompany, onBack }) => {
    const [editing, setEditing] = useState(originalCompany === undefined)

    const [company, setCompany] = useState<Company | undefined>()

    const [loading, setLoading] = useState(false)

    const [tabActive, setTabActive] = useState('details')

    useEffect(() => {
        const c = new Company({ ...originalCompany })
        setCompany(c)
    }, [originalCompany])

    const _setLoading = (val: boolean) => {
        setLoading(val)
    }

    const _toggleEdit = () => {
        if (company) {
            setEditing(!editing)
        }
    }

    const _onCompanyUpdated = async (c: Company) => {
        setCompany(undefined)
        setCompany(c)
    }

    const _companyContent = () => {
        if (tabActive === 'documents') {
            return <CompanyFormDocuments companyId={company?.id || 0} />
        } else if (tabActive === 'details') {
            if (editing) {
                return (
                    <CompanyFormEditing
                        companyId={company?.id || 0}
                        onCompanyUpdated={_onCompanyUpdated}
                        onBack={onBack}
                        setLoading={_setLoading}
                        setEditing={val => setEditing(val)}
                    />
                )
            } else {
                return (
                    <CompanyFormDetail
                        companyId={company?.id || 0}
                        onBack={onBack}
                        toggleEdit={_toggleEdit}
                    />
                )
            }
        } else if (tabActive === 'statements') {
            if (company) {
                return (
                    <CompanyFormPendencies
                        hideHeader
                        company={company}
                        onBack={onBack}
                        setLoading={_setLoading}
                        isStatements
                        onCompanyUpdated={_onCompanyUpdated}
                    />
                )
            }
        } else if (tabActive === 'pendencies') {
            if (company) {
                return (
                    <CompanyFormPendencies
                        hideHeader
                        company={company}
                        onBack={onBack}
                        isStatements={false}
                        setLoading={_setLoading}
                        onCompanyUpdated={_onCompanyUpdated}
                    />
                )
            }
        }
        return null
    }

    useEffect(() => {
        if (tabActive === 's') {
            setTabActive('statements')
        } else if (tabActive === 'p') {
            setTabActive('pendencies')
        }
    }, [tabActive])

    const _setTab = (tab: string) => {
        if (tabActive !== tab) {
            if (tab === 'statements') {
                setTabActive('s')
            } else if (tab === 'pendencies') {
                setTabActive('p')
            } else {
                setTabActive(tab)
            }
        }
    }

    if (originalCompany) {
        if (!company) {
            return null
        }
    }

    return (
        <View style={styles.container}>
            <Tabs>
                <TabItem
                    setTab={_setTab}
                    tab="details"
                    label={
                        company
                            ? editing
                                ? 'Editando'
                                : 'Detalhes'
                            : 'Nova Empresa'
                    }
                    style={[
                        tabStyles.title,
                        tabActive === 'details' ? null : tabStyles.tabInactive,
                    ]}
                    active={tabActive === 'details'}
                />
                <TabItem
                    setTab={_setTab}
                    tab="pendencies"
                    label="Pendências"
                    style={[
                        tabStyles.title,
                        tabActive === 'pendencies'
                            ? null
                            : tabStyles.tabInactive,
                    ]}
                    active={tabActive === 'pendencies'}
                />
                {company && company.id && (
                    <TabItem
                        setTab={_setTab}
                        tab="documents"
                        label="Documentos"
                        style={[
                            tabStyles.title,
                            tabActive === 'documents'
                                ? null
                                : tabStyles.tabInactive,
                        ]}
                        active={tabActive === 'documents'}
                    />
                )}
                {company && company.id && (
                    <TabItem
                        setTab={_setTab}
                        tab="statements"
                        label="Declarações"
                        style={[
                            tabStyles.title,
                            tabActive === 'statements'
                                ? null
                                : tabStyles.tabInactive,
                        ]}
                        active={tabActive === 'statements'}
                    />
                )}
            </Tabs>
            {_companyContent()}

            <Spinner visible={loading} overlayColor={'rgba(0, 0, 0, 0.5)'} />
        </View>
    )
}

export default CompanyForm
