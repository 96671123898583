import React, { useContext, useState } from 'react'

import { Dimensions, View } from 'react-native'
import Header from '../../../components/Header'
import Menu from '../../../components/Menu'
import { AuthContext } from '../../../contexts/auth-context'
import { useMenuLayout } from '../../../hooks/use-menu-layout'
import ActivitiesFragment from '../Fragments/ActivitiesFragment'
import CompaniesFragment from '../Fragments/CompaniesFragment'
import CompanyTypeFragment from '../Fragments/CompanyTypeFragment'
import DocumentsFragment from '../Fragments/DocumentsFragment'
import EmployeesFragment from '../Fragments/EmployeesFragment'
import ImpostoRendaFragment from '../Fragments/ImpostoRendaFragment'
import MainFragment from '../Fragments/MainFragment'
import ProfileFragment from '../Fragments/ProfileFragment'
import RegimeFragment from '../Fragments/RegimeFragment'
import ResponsiblesFragment from '../Fragments/ResponsiblesFragment'
import RolesFragment from '../Fragments/RolesFragment'
import ServiceTypeFragment from '../Fragments/ServiceTypeFragment'
import StatementsFragment from '../Fragments/StatementsFragment'
import { Fragment } from './ScreenConsts'
import styles from './styles'

const windowHeight = Dimensions.get('window').height

const MainScreen: React.FC = () => {
    const [fragment, setFragment] = useState(Fragment.Main)
    const [showMenu, setShowMenu] = useState(false)
    const { height, setHeight } = useMenuLayout()

    // const navigation = useNavigation()

    const { logout } = useContext(AuthContext)

    const _content = () => {
        switch (fragment) {
            case Fragment.Main:
                return <MainFragment />
            case Fragment.Companies:
                return <CompaniesFragment />
            case Fragment.Employees:
                return <EmployeesFragment />
            case Fragment.Roles:
                return <RolesFragment />
            case Fragment.Profile:
                return <ProfileFragment />
            case Fragment.Statements:
                return <StatementsFragment />
            case Fragment.CompanyTypes:
                return <CompanyTypeFragment />
            case Fragment.RegimeTypes:
                return <RegimeFragment />
            case Fragment.ServiceTypes:
                return <ServiceTypeFragment />
            case Fragment.ImpostoRenda:
                return <ImpostoRendaFragment />
            case Fragment.ActivityReport:
                return <ActivitiesFragment />
            case Fragment.Documents:
                return <DocumentsFragment />
            case Fragment.Responsibles:
                return <ResponsiblesFragment />
        }
    }

    const _setContent = (content: number) => {
        setFragment(content)
    }

    const _logout = async () => {
        logout()
    }

    return (
        <View style={styles.container}>
            <Header onShowMenu={() => setShowMenu(!showMenu)} />
            <View style={styles.containerContent}>
                <Menu
                    onLayout={event => {
                        setHeight(event.nativeEvent.layout.height || 0)
                    }}
                    show={showMenu}
                    activeFragment={fragment}
                    onHide={() => setShowMenu(false)}
                    onItemSelected={_setContent}
                    onLogout={_logout}
                />
                <View
                    style={[
                        styles.content,
                        { height: Math.max(height + 48, windowHeight - 48) },
                    ]}
                >
                    {_content()}
                </View>
            </View>
        </View>
    )
}

export default MainScreen
