import React, { useCallback, useEffect, useState } from 'react'
import {
    NativeScrollEvent,
    NativeSyntheticEvent,
    RefreshControl,
    ScrollView,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'
import CheckBox from '../../../../components/CheckBox'
import ContentHeader from '../../../../components/ContentHeader'
import FabButton from '../../../../components/FabButton'
import NoItems from '../../../../components/NoItems'
import User from '../../../../models/user'
import { getUsers } from '../../../../services/api'
import Settings from '../../../../services/settings'
import EmployeeForm from './EmployeeForm'
import EmployeeItem from './EmployeeItem'
import styles from './styles'

const EmployeesFragment: React.FC = () => {
    const [page, setPage] = useState(0)
    const [users, setUsers] = useState<User[]>([])
    const [usersFiltered, setUsersFiltered] = useState<User[]>([])
    const [userSearchTerm, setUserSearchTerm] = useState('')
    const [showActives, setShowActives] = useState(true)
    const [showInactives, setShowInactives] = useState(true)
    // const [checkActive, setCheckActive] = useState(true)

    const [userEditing, setUserEditing] = useState<User | undefined>(undefined)

    const [refreshing, setRefreshing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [editing, setEditing] = useState(false)

    const checkActiveInactive = useCallback(
        (_users: User[]) => {
            // if (!checkActive) {
            //     return _users
            // }
            // setCheckActive(false)
            const newUsersFiltered = _users.filter(user => {
                const removed = Boolean(user.removed)
                if (removed && !showInactives) {
                    return false
                }
                if (!removed && !showActives) {
                    return false
                }
                return true
            })
            // setUsersFiltered(newUsersFiltered)
            return newUsersFiltered
        },
        [showActives, showInactives],
    )

    useEffect(() => {
        if (userSearchTerm.length > 0) {
            const filtered: User[] = users.filter(user =>
                user.name.toLowerCase().includes(userSearchTerm.toLowerCase()),
            )
            setUsersFiltered(filtered)
        } else {
            setUsersFiltered(users)
        }
    }, [checkActiveInactive, userSearchTerm, users])

    const _getUsers = useCallback(async () => {
        setLoading(true)
        try {
            const _user = await Settings.getUser()
            const result = await getUsers(page, _user?.companyId || 0)
            if (result && Array.isArray(result)) {
                const _users = page === 0 ? [] : [...users]
                for (const user of result) {
                    if (_users.find(i => i.id === user.id) === undefined) {
                        _users.push(new User(user))
                    }
                }
                setUsers(_users)
            }
        } catch (e) {
            console.log('_getUsers exception: ', e)
        } finally {
            setLoading(false)
        }
        setRefreshing(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        _getUsers()
    }, [_getUsers])

    const _isCloseToBottom = (
        event: NativeSyntheticEvent<NativeScrollEvent>,
    ) => {
        const { layoutMeasurement, contentOffset, contentSize } =
            event.nativeEvent

        const paddingToBottom = 20
        const close =
            layoutMeasurement.height + contentOffset.y >=
            contentSize.height - paddingToBottom
        if (close) {
            //     if (!updating && hasMoreItems) {
            //         dealsGet(false, false)
            //     }
        }
    }

    const _onRefresh = () => {
        setPage(0)
        setUsers([])
        setRefreshing(true)
        _getUsers()
    }

    const _add = () => {
        setUserEditing(undefined)
        setEditing(true)
    }

    const _edit = (u: User) => {
        setUserEditing(u)
        setEditing(true)
    }

    const _closeEditing = () => {
        setUserEditing(undefined)
        setEditing(false)
        setPage(0)
        setUsers([])
        _getUsers()
    }

    const _onInactivesChanged = () => {
        setShowInactives(!showInactives)
        // setCheckActive(true)
        setUsersFiltered([])
    }

    const _onActivesChanged = () => {
        setShowActives(!showActives)
        // setCheckActive(true)
        setUsersFiltered([])
    }

    const usersToShow = checkActiveInactive(usersFiltered)

    return editing ? (
        <EmployeeForm originalEmployee={userEditing} onBack={_closeEditing} />
    ) : (
        <>
            <ScrollView
                style={styles.container}
                onScroll={_isCloseToBottom}
                scrollEventThrottle={400}
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={_onRefresh}
                    />
                }
            >
                <>
                    <ContentHeader
                        title="Funcionários"
                        searchBar
                        onSearch={setUserSearchTerm}
                    />
                    <View style={styles.checkBoxes}>
                        <TouchableOpacity
                            onPress={_onActivesChanged}
                            style={styles.checkBoxActiveInactive}
                        >
                            <CheckBox checked={showActives} />
                            <Text>Ativos</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={_onInactivesChanged}
                            style={[
                                styles.checkBoxActiveInactive,
                                { marginLeft: 20 },
                            ]}
                        >
                            <CheckBox checked={showInactives} />
                            <Text>Inativos</Text>
                        </TouchableOpacity>
                    </View>

                    {usersToShow.map(user => (
                        <EmployeeItem
                            key={user.id}
                            user={user}
                            onSelected={u => _edit(u)}
                        />
                    ))}
                </>
            </ScrollView>
            {users.length === 0 && !loading ? <NoItems /> : null}
            <FabButton
                icon={require('../../../../../assets/images/ic_plus.png')}
                onPress={_add}
            />
        </>
    )
}

export default EmployeesFragment
