import { DropdownItem } from '../components/Dropdown'

export const LoggerTags = {
    formEditing: '@formEditing',
}

export const SystemRole = {
    superadmin: 1,
    dalconEmployee: 2,
    dalconEmployeeSuperAdmin: 3,
    admin: 100,
    employee: 101,
    isSuperAdmin: (role: number): boolean => {
        return role === SystemRole.superadmin
    },
    isSuperAdminBase: (role: number): boolean => {
        return (
            role === SystemRole.superadmin ||
            role === SystemRole.dalconEmployeeSuperAdmin ||
            role === SystemRole.dalconEmployee
        )
    },
    isDalconEmployee: (role: number): boolean => {
        return role === SystemRole.dalconEmployee
    },
    isCustomerAdmin: (role: number): boolean => {
        return role === SystemRole.admin
    },
    isCustomerEmployee: (role: number): boolean => {
        return role === SystemRole.employee
    },
}

export const FilterType = {
    notStarted: { id: 1, name: 'Solicitações não iniciadas' },
    started: { id: 2, name: 'Iniciado' },
    finished: { id: 3, name: 'Finalizado' },
}

export const FilterTypeDropdownItems = [
    new DropdownItem(0, 'Todos'),
    new DropdownItem(FilterType.notStarted.id, FilterType.notStarted.name),
    new DropdownItem(FilterType.started.id, FilterType.started.name),
    new DropdownItem(FilterType.finished.id, FilterType.finished.name),
]

export const RecurrenceType = {
    monthly: { id: 1, name: 'Mensal' },
    quarterly: { id: 2, name: 'Trimestral' },
    semester: { id: 3, name: 'Semestral' },
    yearly: { id: 4, name: 'Anual' },
}

export const RecurrenceTypeDropdownItems = [
    new DropdownItem(RecurrenceType.monthly.id, RecurrenceType.monthly.name),
    new DropdownItem(
        RecurrenceType.quarterly.id,
        RecurrenceType.quarterly.name,
    ),
    new DropdownItem(RecurrenceType.semester.id, RecurrenceType.semester.name),
    new DropdownItem(RecurrenceType.yearly.id, RecurrenceType.yearly.name),
]
