import * as DocumentPicker from 'expo-document-picker'
import { dateTag } from '../shared/date'
import DocFormularioConstituicao from './doc-formulario-constituicao'

class Document {
    id: number
    name: string
    filename: string
    url?: string
    visualized: boolean
    dateLastVisualized?: Date
    dateUpdate?: Date
    size: number
    base64?: string
    mimetype: string

    uri?: string

    // file?: File
    docResult?: DocumentPicker.DocumentResult

    companyId?: number
    type?: string

    groupId?: number

    created: boolean = false

    result: any = {}

    formConstituicao?: DocFormularioConstituicao

    folderTag: string

    constructor(json: any) {
        this.id = json.id
        this.name = json.name || '-'
        this.filename = json.filename || ''
        this.url = json.url
        this.visualized = json.visualized || false
        this.dateLastVisualized = json.dateLastVisualized
        this.size = json.size
        this.base64 = json.base64
        this.mimetype = json.mimetype || ''
        this.uri = json.uri
        this.companyId = json.companyId
        this.type = json.type
        this.dateUpdate = json.dateUpdate
        this.folderTag = ''
        if (this.dateUpdate) {
            this.folderTag = dateTag(this.dateUpdate)
        }
    }
}

export default Document
