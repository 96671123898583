import React, { useEffect, useState } from 'react'
import {
    NativeScrollEvent,
    NativeSyntheticEvent,
    Platform,
    RefreshControl,
    ScrollView,
    View,
} from 'react-native'
import ContentHeader from '../../../../components/ContentHeader'
import Dropdown, { DropdownItem } from '../../../../components/Dropdown'
import FabButton from '../../../../components/FabButton'
import NoItems from '../../../../components/NoItems'
import Spinner from '../../../../components/Spinner'
import Company from '../../../../models/company'
import CompanySituation from '../../../../models/company-situation'
import Regime from '../../../../models/regime'
import {
    getCompanies,
    getCompanySituations,
    getRegimes,
} from '../../../../services/api'
import { removeAccents } from '../../../../shared/string-utils'
import CompanyForm from './CompanyForm'
import CompanyItem from './CompanyItem'
import styles from './styles'

const CompaniesFragment: React.FC = () => {
    const [refreshing, setRefreshing] = useState(false)
    const [companies, setCompanies] = useState<Company[]>([])
    const [page, setPage] = useState(0)
    const [searchTerm, setSearchTerm] = useState('')

    const [editing, setEditing] = useState(false)
    const [companyEditing, setCompanyEditing] = useState<Company | undefined>(
        undefined,
    )

    // const [showBaixadaTransferida, setShowBaixadaTransferida] = useState(false)

    const [situations, setSituations] = useState<CompanySituation[]>([])
    const [situationsD, setSituationsD] = useState<DropdownItem[]>([])
    const [situation, setSituation] = useState<CompanySituation | null>(null)

    const [regimes, setRegimes] = useState<Regime[]>([])
    const [regimesD, setRegimesD] = useState<DropdownItem[]>([])
    const [regime, setRegime] = useState<Regime | null>(null)

    useEffect(() => {
        console.log('>>>> getRegimes')
        if (regimes.length === 0) {
            ;(async () => {
                // situations
                const resp = await getRegimes()
                if (resp && Array.isArray(resp)) {
                    const _regimes = [{ id: 0, name: 'Nenhum' }, ...resp]
                    setRegimes(_regimes)
                    setRegime(_regimes[0])

                    setRegimesD(
                        _regimes.map((i: any) => {
                            return new DropdownItem(i.id, i.name, i.id === 0)
                        }),
                    )
                }
            })()
        }
    }, [regimes.length])

    useEffect(() => {
        console.log('>>>> getCompanySituations')
        if (situations.length === 0) {
            ;(async () => {
                // situations
                const situationsResponse = await getCompanySituations()
                if (situationsResponse && situationsResponse.situations) {
                    setSituations(situationsResponse.situations)
                    setSituation(situationsResponse.situations[0])

                    setSituationsD(
                        situationsResponse.situations.map((i: any) => {
                            return new DropdownItem(
                                i.id,
                                i.name,
                                i.id === situationsResponse.situations[0].id,
                            )
                        }),
                    )
                }
            })()
        }
    }, [situations.length])

    useEffect(() => {
        console.log('searchTerm situation')
        setSearchTerm(searchTerm)
    }, [searchTerm, situation])

    const _getCompanies = async () => {
        setRefreshing(true)
        console.log('>>>> getCompanies')
        try {
            const result = await getCompanies(page)
            if (result && Array.isArray(result)) {
                const _comapanies = page === 0 ? [] : [...companies]
                for (const company of result) {
                    if (
                        _comapanies.find(i => i.id === company.id) === undefined
                    ) {
                        const c = new Company(company)
                        _comapanies.push(c)
                    }
                }
                setCompanies(_comapanies)
            }
        } catch (e) {
            console.log('getCompanies exception: ', e)
        } finally {
            setRefreshing(false)
        }
    }

    useEffect(() => {
        ;(async () => {
            await _getCompanies()
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _onRefresh = () => {
        setPage(0)
        setCompanies([])
        setRefreshing(true)
        _getCompanies()
    }

    const _isCloseToBottom = (
        event: NativeSyntheticEvent<NativeScrollEvent>,
    ) => {
        const { layoutMeasurement, contentOffset, contentSize } =
            event.nativeEvent
        const paddingToBottom = 20
        const close =
            layoutMeasurement.height + contentOffset.y >=
            contentSize.height - paddingToBottom
        console.log('isCloseToBottom: ', close)
        // if (close) {
        //     if (!updating && hasMoreItems) {
        //         dealsGet(false, false)
        //     }
        // }
    }

    const _add = () => {
        setCompanyEditing(undefined)
        setEditing(true)
    }

    const _edit = (company: Company) => {
        setCompanyEditing(company)
        setEditing(true)
    }

    const _closeEditing = () => {
        setCompanyEditing(undefined)
        setEditing(false)
        setPage(0)
        setCompanies([])
        _getCompanies()
    }

    const _onSearch = (text: string) => {
        setSearchTerm(text)
    }

    let showingCompanies =
        searchTerm.trim().length > 0
            ? companies.filter(c => {
                  return (
                      removeAccents(c.name.toLowerCase()).includes(
                          removeAccents(searchTerm.trim().toLowerCase()),
                      ) && c.companySituationId === (situation?.id || 0)
                  )
              })
            : companies.filter(
                  c => c.companySituationId === (situation?.id || 0),
              )
    if (regime && regime.id > 0) {
        showingCompanies = showingCompanies.filter(
            c => c.regimeId === regime.id,
        )
    }

    // if (showBaixadaTransferida) {
    //     showingCompanies = showingCompanies.filter(
    //         c => c.companySituationId > 2,
    //     )
    // } else {
    //     showingCompanies = showingCompanies.filter(
    //         c => c.companySituationId < 3,
    //     )
    // }

    const _content = () => {
        if (editing) {
            return (
                <CompanyForm
                    originalCompany={companyEditing}
                    onBack={_closeEditing}
                />
            )
        } else {
            return (
                <>
                    <ScrollView
                        style={styles.container}
                        onScroll={_isCloseToBottom}
                        scrollEventThrottle={400}
                        refreshControl={
                            <RefreshControl
                                refreshing={refreshing}
                                onRefresh={_onRefresh}
                            />
                        }
                    >
                        <>
                            <ContentHeader
                                title="Empresas"
                                searchBar
                                onSearch={_onSearch}
                            />
                            {/* <TouchableOpacity
                                style={styles.containerBaixadaTransferida}
                                onPress={() =>
                                    setShowBaixadaTransferida(
                                        !showBaixadaTransferida,
                                    )
                                }
                            >
                                <CheckBox checked={showBaixadaTransferida} />
                                <Text>
                                    Mostrar Empresas Baixadas ou Transferidas
                                </Text>
                            </TouchableOpacity> */}
                            <View
                                style={{
                                    width: 100,
                                    marginTop: 16,
                                    flexDirection: 'row',
                                }}
                            >
                                <Dropdown
                                    label="Filtro Situação"
                                    selectedLabel={situation?.name || ''}
                                    modalTitle="Escolha a situação"
                                    items={situationsD}
                                    placeholder="Escolha a situação"
                                    onItemSelected={item => {
                                        const s = situations.find(
                                            i => i.id === item.id,
                                        )
                                        if (s) {
                                            setSituation(s)
                                        }
                                    }}
                                />
                                <Dropdown
                                    label="Filtro Regime"
                                    selectedLabel={regime?.name || ''}
                                    modalTitle="Escolha o regime"
                                    style={{ marginLeft: 8 }}
                                    items={regimesD}
                                    placeholder="Escolha o regime"
                                    onItemSelected={item => {
                                        const s = regimes.find(
                                            i => i.id === item.id,
                                        )
                                        console.log('regimes: ', s)
                                        if (s) {
                                            setRegime(s)
                                        }
                                    }}
                                />
                            </View>

                            {showingCompanies.map(company => (
                                <CompanyItem
                                    key={company.id}
                                    company={company}
                                    onSelected={c => _edit(c)}
                                />
                            ))}
                        </>
                    </ScrollView>
                    {companies.length === 0 ? <NoItems /> : null}
                    <FabButton
                        icon={require('../../../../../assets/images/ic_plus.png')}
                        onPress={_add}
                    />
                    {Platform.OS === 'web' ? (
                        <Spinner
                            visible={refreshing}
                            overlayColor={'rgba(0, 0, 0, 0.5)'}
                        />
                    ) : null}
                </>
            )
        }
    }

    return <>{_content()}</>
}

export default CompaniesFragment
