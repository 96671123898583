import React, { createContext, useCallback, useEffect, useState } from 'react'
import TaskTimer from '../models/task-timer'

import {
    getTimer as apiGetTimer,
    startTimer as apiStartTimer,
    stopTimer as apiStopTimer,
} from '../services/api'

type TimerContextData = {
    startTimer: (_pendencyId: number) => Promise<void>
    stopTimer: (finish?: boolean) => Promise<void>
    getTimer: () => Promise<void>
    clearTimer: () => void
    timer: TaskTimer | null | undefined
    pendencyId?: number
    setPendencyId: React.Dispatch<React.SetStateAction<number>>
}

type TimerContextProps = {
    children: React.ReactNode
}

export const TimerContext = createContext({} as TimerContextData)

export const TimerContextProvider = ({ children }: TimerContextProps) => {
    const [timer, setTimer] = useState<TaskTimer | null>()
    const [pendencyId, setPendencyId] = useState(0)
    const clearTimer = () => {
        setTimer(null)
        setPendencyId(0)
    }
    const startTimer = useCallback(async (_pendencyId: number) => {
        try {
            const _t = await apiStartTimer(_pendencyId)
            setTimer(_t.timer)
            setPendencyId(_pendencyId)
        } catch (e) {
            console.log(e)
        }
    }, [])
    const stopTimer = useCallback(
        async (finish = false) => {
            try {
                if (timer) {
                    const _t = await apiStopTimer(timer)
                    setTimer(finish ? null : _t.timer)
                    setPendencyId(finish ? 0 : timer.pendencyId)
                }
            } catch (e) {
                console.log(e)
            }
        },
        [timer],
    )
    const getTimer = useCallback(async () => {
        try {
            const _resp = await apiGetTimer(pendencyId)
            if (_resp) {
                setTimer(_resp.timer)
            }
        } catch (e) {
            console.log(e)
        }
    }, [pendencyId])

    useEffect(() => {
        if (!timer) {
            getTimer()
        }
    }, [getTimer, timer])

    const timerContext: TimerContextData = {
        startTimer,
        stopTimer,
        getTimer,
        clearTimer,
        timer,
        pendencyId,
        setPendencyId,
    }

    return (
        <TimerContext.Provider value={timerContext}>
            {children}
        </TimerContext.Provider>
    )
}

export const useTimer = (): TimerContextData => {
    const context = React.useContext(TimerContext)
    if (context === undefined) {
        throw new Error(
            'useMouseTimerdal must be used within a TimerContextProvider',
        )
    }
    return context
}
