import { StyleSheet } from 'react-native'
import { iconSizeNormal } from '../../../../../../styles/metrics'

const styles = StyleSheet.create({
    container: {},
    errorMessage: {
        color: 'red',
        fontSize: 14,
        marginBottom: 10,
    },
    ownerPasswordWarning: {
        color: '#f55',
        backgroundColor: '#ff9',
        padding: 8,
        marginBottom: 8,
    },
    ownerPasswordWarningBold: {
        fontWeight: 'bold',
    },
    phonesContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    phoneViewSpace: {},
    phoneAddButton: {
        width: 58,
    },
    phoneLineContainer: {
        flexDirection: 'row',
    },
    phoneLineInput: {
        flexGrow: 1,
    },
    phoneLineDeleteButton: {
        marginLeft: 8,
        alignSelf: 'center',
        marginRight: 8,
    },
    deleteIcon: {
        width: iconSizeNormal,
        height: iconSizeNormal,
        tintColor: 'red',
    },
})

export default styles
