import { StyleSheet } from 'react-native'
import metrics from '../../../../../../styles/metrics'

const iconRemoveSize = 40

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    content: {
        padding: metrics.padding,
    },
    inputTextArea: {
        height: 100,
    },
    line: {
        height: 1,
        backgroundColor: '#aaa',
    },
    listItem: {
        flex: 1,
        flexShrink: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    iconRemove: {
        width: iconRemoveSize,
        height: iconRemoveSize,
        tintColor: 'red',
    },
    errorMessage: {
        color: 'red',
        fontSize: 14,
        marginTop: 10,
        marginBottom: 10,
    },
})

export default styles
