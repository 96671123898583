import metrics, { iconSizeNormal } from '../../../../styles/metrics'

import { StyleSheet } from 'react-native'
import colors from '../../../../styles/colors'

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: metrics.padding,
    },
    modalText: {
        fontSize: 16,
        marginTop: 8,
        marginBottom: 8,
    },
    name: {
        color: colors.secondary,
        fontSize: 18,
        fontWeight: 'bold',
    },
    cnpj: {
        color: '#888',
        marginTop: 4,
    },
    iconWarning: {
        position: 'absolute',
        right: 16,
        top: 16,
        width: iconSizeNormal,
        height: iconSizeNormal,
        tintColor: '#E7D42E',
    },
    dalconEmployeeSuperAdmin: {
        tintColor: '#26f',
    },
    removed: {
        color: 'red',
    },
    searchContainer: {
        alignItems: 'flex-start',
        marginTop: 8,
    },
    searchCheckBox: {
        flexDirection: 'row',
        alignItems: 'center',
    },
})

export default styles
