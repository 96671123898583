import React, {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'

import Pendency from '../models/pendency'
import { getPendencies } from '../services/api'
import Settings from '../services/settings'
import { AuthContext } from './auth-context'

interface PendencyContextData {
    pendencies: Pendency[]
    newPendenciesCount: number
    loading: boolean
    refreshing: boolean
    setRefreshing: (_refreshing: boolean) => void
    fetchPendencies: (currentPage: number) => Promise<void>
}

interface PendencyProviderProps {
    children: ReactNode
}

export const PendencyContext = createContext({} as PendencyContextData)

export const PendencyContextProvider = ({
    children,
}: PendencyProviderProps) => {
    const [pendencies, setPendencies] = useState<Pendency[]>([])
    const [newPendenciesCount, setNewPendenciesCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [refreshing, setRefreshing] = useState(false)
    const updateOnCreate = useRef(true)

    const { logout } = useContext(AuthContext)

    const _updatePendencies = useCallback(
        async (currentPage: number, newPendencies: Pendency[]) => {
            if (newPendencies.length === 0) {
                console.log('>>>> NO MORE PENDENCIES')
                return
            }
            let count = pendencies.length
            const _pendencies = currentPage > 0 ? [...pendencies] : []
            for (const item of newPendencies) {
                _pendencies.push(new Pendency(item))
                count++
            }
            setPendencies(_pendencies)
            setNewPendenciesCount(count)
        },
        [pendencies],
    )

    const fetchPendencies = useCallback(
        async (currentPage: number) => {
            setLoading(true)
            setRefreshing(true)

            try {
                const user = await Settings.getUser()
                console.log('>>>> PENDENCY CONTEXT getPendencies')
                const result = await getPendencies(
                    user?.companyId || 0,
                    currentPage,
                )
                await _updatePendencies(currentPage, result)
            } catch (e: any) {
                if (e.status && e.status === 401) {
                    logout()
                }
            } finally {
                setLoading(false)
                setRefreshing(false)
            }
        },
        [_updatePendencies, logout],
    )

    useEffect(() => {
        if (updateOnCreate.current) {
            updateOnCreate.current = false
            fetchPendencies(0)
        }
    }, [fetchPendencies])

    const pendencyContext = {
        pendencies,
        newPendenciesCount,
        loading,
        refreshing,
        setRefreshing,
        fetchPendencies,
    }

    return (
        <PendencyContext.Provider value={pendencyContext}>
            {children}
        </PendencyContext.Provider>
    )
}
