import React from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import ServiceType from '../../../../models/service-type'
import { SystemRole } from '../../../../shared/consts'
import colors from '../../../../styles/colors'
import { iconSizeNormal } from '../../../../styles/metrics'

export interface ServiceTypeFormPropsItemProps {
    serviceType: ServiceType
    onSelected: (serviceType: ServiceType) => void
    onRemove: (serviceType: ServiceType) => void
    privilege: number
}

const ServiceTypeItem = ({
    serviceType,
    onSelected,
    onRemove,
    privilege,
}: ServiceTypeFormPropsItemProps) => {
    return (
        <TouchableOpacity
            style={styles.container}
            onPress={() => onSelected(serviceType)}
        >
            <View>
                <Text style={styles.name}>{serviceType.name}</Text>
            </View>
            {SystemRole.isSuperAdmin(privilege) ? (
                <TouchableOpacity onPress={() => onRemove(serviceType)}>
                    <Image
                        style={styles.iconRemove}
                        source={require('../../../../../assets/images/ic_remove.png')}
                    />
                </TouchableOpacity>
            ) : null}
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingTop: 16,
        paddingBottom: 16,
        borderBottomColor: '#bbb',
        borderBottomWidth: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    name: {
        color: colors.secondary,
        fontSize: 18,
        fontWeight: 'bold',
    },
    recurrence: {
        color: colors.secondary,
        fontSize: 14,
        fontWeight: 'normal',
        marginTop: 8,
    },
    iconRemove: {
        width: iconSizeNormal,
        height: iconSizeNormal,
        tintColor: '#f55',
    },
})

export default ServiceTypeItem
