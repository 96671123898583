import { StyleSheet } from 'react-native'
import colors from '../../styles/colors'
import { iconSizeNormal } from '../../styles/metrics'

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        width: iconSizeNormal,
        height: iconSizeNormal,
        tintColor: colors.primary,
        marginTop: 26,
    },
    inputContainer: {
        flex: 1,
    },
    inputLabel: {
        fontSize: 14,
        color: colors.primary,
        marginBottom: 6,
    },
    input: {
        flex: 1,
        borderWidth: 1,
        borderColor: colors.secondary,
        borderRadius: 4,
        padding: 8,
        color: '#333',
    },
    disabled: {
        backgroundColor: '#ddd',
    },
    inputHelp: {
        fontSize: 12,
        color: colors.primary,
        marginBottom: 6,
        marginTop: 4,
    },
})

export default styles
