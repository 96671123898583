import { StyleSheet } from 'react-native'
import colors from '../../styles/colors'
import { iconSizeNormal } from '../../styles/metrics'

const styles = StyleSheet.create({
    container: {
        borderBottomColor: '#888',
        borderBottomWidth: 1,
        paddingTop: 10,
    },
    containerPendencies: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

        paddingBottom: 8,
        marginTop: 8,
    },
    deleteIcon: {
        width: iconSizeNormal,
        height: iconSizeNormal,
        tintColor: 'red',
    },
    pendencyDescription: {
        color: colors.secondary,
        fontSize: 16,
        marginBottom: 10,
    },
    pendencyDetail: {
        fontSize: 14,
        color: '#777',
    },
    pendencyDetailBold: {
        fontWeight: 'bold',
    },
    pendencyDetailDate: {
        fontSize: 12,
        color: '#888',
        marginBottom: 8,
    },
})

export default styles
