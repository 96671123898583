import React, { useEffect, useRef, useState } from 'react'
import { StyleSheet, Text } from 'react-native'
import Dropdown, { DropdownItem } from '../../../../components/Dropdown'
import ImpostoRenda, {
    ImpostoRendaStatus,
} from '../../../../models/imposto-renda'
import { getCompanies, saveImpostoRenda } from '../../../../services/api'
import { dateToString, stringToDate } from '../../../../shared/date'

import Button from '../../../../components/Button'
import Input from '../../../../components/Input'
import Spinner from '../../../../components/Spinner'
import Company from '../../../../models/company'
import { isEmpty } from '../../../../shared/utils'

export interface ImpostoRendaFormProps {
    originalImpostoRenda?: ImpostoRenda
    statusList: ImpostoRendaStatus[]
    onBack: () => void
}

const ImpostoRendaForm = ({
    originalImpostoRenda,
    statusList,
    onBack,
}: ImpostoRendaFormProps) => {
    const [login, setLogin] = useState(originalImpostoRenda?.login || '')
    const [senha, setSenha] = useState(originalImpostoRenda?.senha || '')
    const [codigoAcesso, setCodigoAcesso] = useState(
        originalImpostoRenda?.codigoAcesso || '',
    )
    const [dataNascimento, setDataNascimento] = useState(
        dateToString(originalImpostoRenda?.dataNascimento as any) || '',
    )
    const [cep, setCep] = useState(originalImpostoRenda?.cep || '')
    const [nomeCompleto, setNomeCompleto] = useState(
        originalImpostoRenda?.nomeCompleto || '',
    )
    const [status, setStatus] = useState(
        originalImpostoRenda?.status?.name || '',
    )
    const [company, setCompany] = useState<Company | undefined>(
        originalImpostoRenda?.company,
    )
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const [companies, setCompanies] = useState<Company[]>([])

    const _getCompanies = async () => {
        setLoading(true)
        try {
            const result = await getCompanies(0)
            if (result && Array.isArray(result)) {
                const _companies = []
                for (const _company of result) {
                    if (
                        _companies.find(i => i.id === _company.id) === undefined
                    ) {
                        const c = new Company(_company)
                        _companies.push(c)
                    }
                }
                setCompanies(_companies)
            }
        } catch (e) {
            console.log('getCompanies exception: ', e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        ;(async () => {
            _getCompanies()
        })()
    }, [])

    const _save = async () => {
        if (!company) {
            setErrorMessage('Por favor, informe a empresa.')
        } else if (isEmpty(status)) {
            setErrorMessage('Por favor, informe um status.')
        } else {
            setErrorMessage('')
            setLoading(true)
            try {
                const st = statusList.find(i => i.name === status)
                console.log('st', st)
                const data = {
                    impostoRenda: {
                        id: originalImpostoRenda?.id || 0,
                        login: login.trim(),
                        senha: senha.trim(),
                        codigoAcesso: codigoAcesso.trim(),
                        dataNascimento: stringToDate(dataNascimento.trim()),
                        cep: cep.trim(),
                        nomeCompleto: nomeCompleto.trim(),
                        status: {
                            id: st?.id || 0,
                            name: st?.name || status,
                        },
                        company,
                    },
                }

                const result = await saveImpostoRenda(data)
                if (originalImpostoRenda && result) {
                    // setEditing(false)
                    // const r = new Statement(result.statement)
                    // onStatementUpdated(r)
                    onBack()
                } else {
                    onBack()
                }
            } catch (e) {
                console.log('CompanyTypeForm exception: ', e)
                setErrorMessage(
                    'Erro inesperado. Verifique sua conexão com a internet e tente novamente.',
                )
                if (!originalImpostoRenda) {
                    setLoading(false)
                }
            } finally {
                if (originalImpostoRenda) {
                    setLoading(false)
                }
            }
        }
    }

    const senhaRef = useRef()
    const codigoAcessoRef = useRef()
    const dataNascimentoRef = useRef()
    const cepRef = useRef()
    const nomeCompletoRef = useRef()

    console.log('originalImpostoRenda', originalImpostoRenda)

    return (
        <>
            {errorMessage.length > 0 ? (
                <Text style={styles.errorMessage}>{errorMessage}</Text>
            ) : null}

            <Input
                nextRef={senhaRef}
                returnKeyType="next"
                autoCapitalize="none"
                style={{ marginBottom: 12 }}
                label="CPF"
                value={login}
                onChangeText={text => setLogin(text)}
            />
            <Input
                ref={senhaRef}
                nextRef={codigoAcessoRef}
                returnKeyType="next"
                autoCapitalize="none"
                style={{ marginBottom: 12 }}
                label="Senha"
                value={senha}
                onChangeText={text => setSenha(text)}
            />

            <Input
                ref={codigoAcessoRef}
                nextRef={dataNascimentoRef}
                returnKeyType="next"
                style={{ marginBottom: 12 }}
                label="Código de Acesso"
                value={codigoAcesso}
                onChangeText={text => setCodigoAcesso(text)}
            />

            <Input
                ref={dataNascimentoRef}
                nextRef={cepRef}
                keyboardType="numeric"
                returnKeyType="next"
                maskType="datetime"
                style={{ marginBottom: 12 }}
                label="Data de Nascimento"
                value={dataNascimento}
                onChangeText={text => setDataNascimento(text)}
            />

            <Input
                ref={cepRef}
                nextRef={nomeCompletoRef}
                keyboardType="numeric"
                returnKeyType="next"
                maskType="zip-code"
                style={{ marginBottom: 12 }}
                label="CEP"
                value={cep}
                onChangeText={text => setCep(text)}
            />

            <Input
                ref={nomeCompletoRef}
                autoCapitalize="words"
                returnKeyType="done"
                style={{ marginBottom: 12 }}
                label="Nome Completo"
                value={nomeCompleto}
                onChangeText={text => setNomeCompleto(text)}
            />

            <Dropdown
                label="Status"
                selectedLabel={status}
                canCreate
                modalTitle="Escolha o regime"
                items={statusList.map(i => new DropdownItem(i.id, i.name))}
                placeholder="Buscar"
                onItemSelected={item => {
                    setStatus(item.label)
                }}
            />

            <Dropdown
                label="Empresa / Indivíduo"
                selectedLabel={company?.name || ''}
                modalTitle="Escolha a empresa / indivíduo"
                items={companies.map(i => new DropdownItem(i.id, i.name))}
                placeholder="Buscar"
                onItemSelected={item => {
                    setCompany(companies.find(i => i.id === item.id))
                }}
            />

            <Button
                primary
                style={{ marginTop: 12, marginBottom: 10 }}
                label="Salvar"
                onPress={_save}
            />
            <Button
                secondary
                style={{ marginBottom: 50 }}
                label="Cancelar"
                onPress={onBack}
            />
            <Spinner visible={loading} overlayColor={'rgba(0, 0, 0, 0.5)'} />
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    errorMessage: {
        color: 'red',
        fontSize: 14,
        marginBottom: 10,
    },
})

export default ImpostoRendaForm
