class Role {
    id: number
    name: string
    selectableAsCompanyResponsible: boolean

    constructor(json: any) {
        this.id = json.id
        this.name = json.name
        this.selectableAsCompanyResponsible =
            json.selectableAsCompanyResponsible
    }
}

export default Role
