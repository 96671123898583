import { StyleSheet } from 'react-native'
import colors from '../../styles/colors'

const styles = StyleSheet.create({
    container: {
        backgroundColor: colors.accent,
        padding: 16,
        alignItems: 'center',
        borderRadius: 4,
        shadowColor: 'black',
        shadowOpacity: 0.2,
        shadowRadius: 1.41,
        shadowOffset: {
            width: 0,
            height: 1,
        },
        elevation: 2,
        alignSelf: 'center',
    },
    secondady: {
        backgroundColor: colors.secondary,
    },
    grey: {
        backgroundColor: '#999',
    },
    lightGrey: {
        backgroundColor: '#bbb',
    },
    darkBlue: {
        backgroundColor: colors.primary,
    },
    complementary: {
        backgroundColor: colors.complementary,
    },
    text: {
        color: '#fff',
        fontWeight: 'bold',
    },
})

export default styles
