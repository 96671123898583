import TaskTimer from '../models/task-timer'

export const stringToDate = (dateStr: string): Date | null => {
    if (dateStr && dateStr.length > 0) {
        const parts = dateStr.split('/')
        if (parts.length === 3) {
            const dtStr = `${parts[2]}-${
                Number(parts[1]) > 9 ? parts[1] : `0${parts[1]}`
            }-${Number(parts[0]) > 9 ? parts[0] : `0${parts[0]}`}`
            return new Date(dtStr)
        }
    }
    return null
}

export const dateToString = (date: Date): string => {
    if (date) {
        try {
            let dt = date
            if (typeof dt === 'string') {
                dt = new Date(dt)
            }
            if (date.toString().indexOf('T') > 0) {
                const dtStr = date.toString().split('T')[0]
                const parts = dtStr.split('-')
                return `${parts[2]}/${parts[1]}/${parts[0]}`
            } else {
                let day = dt.getDate()
                const month = dt.getMonth() + 1
                return `${day > 9 ? day : `0${day}`}/${
                    month > 9 ? month : `0${month}`
                }/${dt.getFullYear()}`
            }
        } catch (e) {
            console.log('dateToString error: ', e)
        }
    }
    return ''
}

export const dateTag = (date: Date): string => {
    if (date) {
        try {
            let dt = date
            if (typeof dt === 'string') {
                dt = new Date(dt)
            }
            const month = dt.getMonth() + 1
            return `${dt.getFullYear()}${month > 9 ? month : `0${month}`}`
        } catch (e) {
            console.log('dateToString error: ', e)
        }
    }
    return ''
}

export function getMonthShort(
    dt: Date,
    locale: 'pt-BR' | 'en-US' = 'pt-BR',
    upperCaseFirst = false,
) {
    const monthShort = dt
        .toLocaleString(locale, { month: 'short' })
        .slice(0, -1)
    if (upperCaseFirst) {
        return monthShort.charAt(0).toUpperCase() + monthShort.slice(1)
    }
    return monthShort
}

export function twoDigits(d: number | string) {
    return String(d).padStart(2, '0')
}

export function formatDate(date?: Date, format?: string) {
    if (!date || !format) {
        return ''
    }
    let dt = date
    if (typeof dt === 'string') {
        dt = new Date(dt)
    }
    const monthShort = getMonthShort(dt, 'pt-BR', true)
    let result = format.replace('dd', twoDigits(dt.getDate()))
    result = result.replace('MMM', monthShort)
    result = result.replace('MM', twoDigits(dt.getMonth() + 1))
    result = result.replace('yyyy', dt.getFullYear().toString())
    result = result.replace(
        'yy',
        dt.toLocaleString('pt-BR', { year: '2-digit' }),
    )
    result = result.replace('HH', twoDigits(dt.getHours()))
    result = result.replace('mm', twoDigits(dt.getMinutes()))
    result = result.replace('ss', twoDigits(dt.getSeconds()))
    return result
}

export const datetimeToString = (date?: Date): string => {
    if (date) {
        try {
            let dt = date
            if (typeof dt === 'string') {
                dt = new Date(dt)
            }
            if (date.toString().indexOf('T') > 0) {
                const dtStr = date.toString().split('T')[0]
                const timeStr = date.toString().split('T')[1]
                const parts = dtStr.split('-')
                const partsTime = timeStr.split('.')[0].split(':')
                return `${partsTime[0]}:${partsTime[1]} ${parts[2]}/${parts[1]}/${parts[0]}`
            } else {
                let day = dt.getDate()
                const month = dt.getMonth() + 1
                const hour = dt.getHours()
                const min = dt.getMinutes()
                return `${hour > 9 ? hour : `0${hour}`}:${
                    min > 0 ? min : `0${min}`
                } ${day > 9 ? day : `0${day}`}/${
                    month > 9 ? month : `0${month}`
                }/${dt.getFullYear()}`
            }
        } catch (e) {
            console.log('dateToString error: ', e)
        }
    }
    return ''
}

export const duration = (date1: Date, date2: Date) => {
    if (date1 && date2) {
        const dt1 = new Date(date1)
        const dt2 = new Date(date2)
        const diff = dt2.getTime() - dt1.getTime()
        let minutes = Math.round(diff / 60000)
        let hours = 0
        while (minutes > 59) {
            minutes -= 60
            hours += 1
        }
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
            2,
            '0',
        )}`
    }
    return ''
}

export const pad = (n: number) => {
    return String(n).padStart(2, '0')
}

export const getTaskTime = (timer: TaskTimer) => {
    if (timer.dtStart) {
        const dt =
            typeof timer.dtStart === 'string'
                ? new Date(timer.dtStart)
                : timer.dtStart
        const now = new Date()
        let diffTime = (now.getTime() - dt.getTime()) / 1000 + timer.total
        if (timer.dtStop) {
            diffTime = timer.total
        }
        const hour = Math.floor(diffTime / 60 / 60)
        const min = Math.floor((diffTime / 60) % 60)
        const sec = Math.floor(diffTime % 60)
        return `${pad(hour)}:${pad(min)}:${pad(sec)}`
    }
    return ''
}
