import * as DocumentPicker from 'expo-document-picker'
import * as FileSystem from 'expo-file-system'

import React, { useEffect, useState } from 'react'
import { Image, Platform, Text, TouchableOpacity, View } from 'react-native'
import Dropdown, {
    DropdownItem,
} from '../../../../../../../components/Dropdown'
import {
    getRoles,
    getUsersByRoleId,
    savePendency,
    uploadDocument,
} from '../../../../../../../services/api'

import { RequestFormCompanyDropDown } from '..'
import Button from '../../../../../../../components/Button'
import Input from '../../../../../../../components/Input'
import Document from '../../../../../../../models/document'
import User from '../../../../../../../models/user'
import Settings from '../../../../../../../services/settings'
import { getMimeType } from '../../../../../../../shared/file-utils'
import { isEmpty } from '../../../../../../../shared/utils'
import globalStyles from '../../../../../../../styles/styles'
import styles from './styles'

interface Props {
    onLoading: (loading: boolean) => void
    onError: (message: string) => void
    onCancel: () => void
}

const RequestFormNew: React.FC<Props> = ({ onLoading, onError, onCancel }) => {
    const [description, setDescription] = useState('')
    const [documents, setDocuments] = useState<Document[]>([])
    const [roleId, setRoleId] = useState(0)
    const [rolesDropdownItems, setRolesDropdownItems] = useState<
        DropdownItem[]
    >([])
    const [isCompanyMaster, setIsCompanyMaster] = useState(false)
    const [companyId, setCompanyId] = useState(0)
    const [requestCompanyId, setRequestCompanyId] = useState(0)
    const [users, setUsers] = useState<User[]>([])
    const [userId, setUserId] = useState(0)

    useEffect(() => {
        ;(async () => {
            try {
                const companyMasterId = await Settings.getCompanyMasterId()
                const user = await Settings.getUser()
                if (user && companyMasterId) {
                    setCompanyId(user.companyId)
                    setIsCompanyMaster(user.companyId === companyMasterId)
                }
                const response = await getRoles(companyMasterId || 0)
                if (response) {
                    const items: DropdownItem[] = response.map(
                        r => new DropdownItem(r.id, r.name),
                    )
                    const none = new DropdownItem(0, 'Nenhum')
                    if (items.length > 0) {
                        items.splice(0, 0, none)
                    } else {
                        items.push(none)
                    }
                    setRolesDropdownItems(items)
                }
            } catch (e) {
                console.log('RequestFormFragment init exception: ', e)
            }
        })()
    }, [])

    const _onRoleSelected = async (_roleId: number) => {
        setRoleId(_roleId)
        setUsers([])
        onLoading(true)
        try {
            const companyMasterId = await Settings.getCompanyMasterId()
            if (companyMasterId) {
                const _users = await getUsersByRoleId(companyMasterId, _roleId)
                setUsers(_users)
            }
        } catch (e) {
            console.log(e)
        } finally {
            onLoading(false)
        }
    }

    const _addDocument = async () => {
        try {
            const result = await DocumentPicker.getDocumentAsync()
            if (result.type === 'success') {
                // result.name
                // result.size
                // result.uri

                if (Platform.OS !== 'web') {
                    const base64 = await FileSystem.readAsStringAsync(
                        result.uri,
                        {
                            encoding: FileSystem.EncodingType.Base64,
                        },
                    )
                    const doc = new Document({
                        name: result.name,
                        size: result.size,
                        uri: result.uri,
                        base64: base64,
                    })
                    setDocuments(prevState => {
                        return [...prevState, doc]
                    })
                } else {
                    const doc = new Document({
                        name: result.name,
                        size: result.size,
                        uri: result.uri,
                        base64: result.uri,
                    })
                    setDocuments(prevState => {
                        return [...prevState, doc]
                    })
                }

                // test
                // const mimetype = getMimeType(doc.name)
                // const data = {
                //     document: {
                //         base64: `data:${mimetype};base64,${doc.base64}` || '',
                //         filename: doc.name,
                //         mimetype,
                //     },
                //     companyId,
                // }
                // await uploadDocument(data, 1, 0)
            }
        } catch (e) {
            console.log('DocumentPicker.getDocumentAsync exception: ', e)
        }
    }

    const _removeDocument = async (doc: Document) => {
        let docs = [...documents]
        if (doc.id && doc.id > 0) {
            docs = docs.filter(d => d.id !== doc.id)
        } else {
            docs = docs.filter(d => d.uri !== doc.uri)
        }
        setDocuments(docs)
    }

    const _saveFiles = async (
        index: number,
        pendencyId: number,
        pendencyCommentId: number,
    ) => {
        if (index >= documents.length) {
            return
        }
        const doc = documents[index]
        if (doc.uri) {
            try {
                const mimetype = getMimeType(doc.name)
                const compId =
                    requestCompanyId > 0 ? requestCompanyId : companyId
                const data = {
                    document: {
                        base64: `data:${mimetype};base64,${doc.base64}` || '',
                        filename: doc.name,
                        mimetype,
                        size: doc.size,
                    },
                    companyId: compId,
                }
                await uploadDocument(data, pendencyId, pendencyCommentId)
            } catch (e) {
                console.log('_saveFiles exception: ', e)
            }
        }

        await _saveFiles(index + 1, pendencyId, pendencyCommentId)
    }

    const _save = async () => {
        if (isEmpty(description)) {
            onError('Por favor, informe a descrição da sua solicitação.')
        } else if (description.length < 10) {
            onError(
                'Faça uma descrição um pouco mais detalhada. A descrição deve conter pelo menos 10 letras.',
            )
        } else {
            onError('')
            onLoading(true)
            let success = false
            try {
                const user = await Settings.getUser()
                const compId =
                    requestCompanyId > 0
                        ? requestCompanyId
                        : user?.companyId || 0
                const data = {
                    pendency: {
                        id: 0,
                        description,
                        companyId: compId,
                        roleId,
                        userIdExecutor: userId,
                    },
                }
                onLoading(true)
                const result = await savePendency(data)
                if (result.success) {
                    success = true
                    await _saveFiles(0, result.pendency.id, 0)
                } else {
                    onError('Erro salvando solicitação.')
                }
            } catch (e) {
                success = false
            } finally {
                onLoading(false)
                if (success) {
                    // return to previous screen
                    onCancel()
                }
            }
        }
    }

    const dropdownSelectedLabel =
        roleId === 0
            ? ''
            : rolesDropdownItems.find(i => i.id === roleId)?.label || ''

    const usersDropdownItems = users.map(i => new DropdownItem(i.id, i.name))

    const dropdownSelectedUserLabel =
        userId === 0
            ? ''
            : usersDropdownItems.find(i => i.id === userId)?.label || ''

    return (
        <>
            {isCompanyMaster && (
                <RequestFormCompanyDropDown
                    onCompanySelected={company =>
                        setRequestCompanyId(company.id)
                    }
                />
            )}
            <Input
                returnKeyType="next"
                autoCapitalize="sentences"
                multiline
                textAlignVertical="top"
                inputStyle={styles.inputTextArea}
                style={globalStyles.marginBottomLarge}
                label="Descrição"
                value={description}
                onChangeText={text => setDescription(text)}
            />
            <Dropdown
                style={globalStyles.marginBottomLarge}
                label={`Escolha o Setor ${isCompanyMaster ? '' : '(opcional)'}`}
                selectedLabel={dropdownSelectedLabel}
                modalTitle="Escolha a área de atuação"
                items={rolesDropdownItems}
                placeholder="Escolha área"
                onItemSelected={item => _onRoleSelected(item.id)}
            />
            {users.length > 0 ? (
                <Dropdown
                    style={globalStyles.marginBottomLarge}
                    label={`Escolha a pessoa ${
                        isCompanyMaster ? '' : '(opcional)'
                    }`}
                    selectedLabel={dropdownSelectedUserLabel}
                    modalTitle="Escolha a pessoa"
                    items={usersDropdownItems}
                    placeholder="Escolha a pessoa"
                    onItemSelected={item => setUserId(item.id)}
                />
            ) : null}
            <Button
                style={globalStyles.marginBottomLarge}
                darkBlue
                label="Adicionar Documento"
                onPress={_addDocument}
            />
            {documents.map((doc, index) => (
                <View
                    key={`doc_item_${index}`}
                    style={[styles.listItem, globalStyles.marginBottomNormal]}
                >
                    <Text style={{ flex: 0.9 }}>{doc.name}</Text>
                    <TouchableOpacity onPress={() => _removeDocument(doc)}>
                        <Image
                            style={styles.iconRemove}
                            source={require('../../../../../../../../assets/images/ic_remove.png')}
                        />
                    </TouchableOpacity>
                </View>
            ))}
            <View style={[styles.line, globalStyles.marginBottomLarge]} />

            <Button
                style={globalStyles.marginBottomLarge}
                primary
                label="Salvar"
                onPress={_save}
            />
            <Button
                style={globalStyles.marginBottomLarge}
                secondary
                label="Cancelar"
                onPress={onCancel}
            />
        </>
    )
}

export default RequestFormNew
