import { Platform, StyleSheet, Text, View } from 'react-native'

import Button from '../Button'
import React from 'react'
import { useAlert } from '../../hooks/use-alert'

type AlertProps = {
    title: string
    message: string
    buttonTitle: string
}

export default function Alert({ title, message, buttonTitle }: AlertProps) {
    const alert = useAlert()
    return (
        <View style={styles.container}>
            <View style={styles.box}>
                <View>
                    <Text style={styles.title}>{title}</Text>
                </View>
                <View>
                    <Text style={styles.message}>{message}</Text>
                </View>
                <View>
                    <Button
                        label={buttonTitle}
                        onPress={() => alert.dismiss()}
                    />
                </View>
            </View>
        </View>
    )
}

const width = Platform.OS === 'web' ? 350 : '80%'

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.7)',
    },
    box: {
        maxWidth: width,
        backgroundColor: '#fff',
        borderRadius: 8,
        padding: 12,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        borderBottomColor: '#ccc',
        borderBottomWidth: 1,
        paddingBottom: 8,
        marginBottom: 8,
    },
    message: {
        fontSize: 16,
        marginBottom: 16,
    },
})
