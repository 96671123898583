import React, { useEffect, useState } from 'react'
import { Text } from 'react-native'
import Button from '../../../../../../components/Button'
import Company from '../../../../../../models/company'
import User from '../../../../../../models/user'
import { getCompany } from '../../../../../../services/api'
import Settings from '../../../../../../services/settings'
import styles from './styles'

interface Props {
    user?: User
    toggleEdit: () => void
}

const ProfileFormDetail: React.FC<Props> = ({ user, toggleEdit }) => {
    const [company, setCompany] = useState<Company | null>()

    const [whatsAppNotification, setWhatsAppNotification] = useState(false)
    const [emailNotification, setEmailNotification] = useState(false)
    const [whatsAppNumber, setWhatsAppNumber] = useState('')

    useEffect(() => {
        ;(async () => {
            setWhatsAppNotification(await Settings.getWhatsAppNotification())
            setEmailNotification(await Settings.getEmailNotification())
            setWhatsAppNumber((await Settings.getWhatsAppNumber()) || '')
        })()
    })

    useEffect(() => {
        if (user) {
            getCompany(user.companyId).then(setCompany)
        }
    }, [user])

    return (
        <>
            <Text style={styles.label}>Nome</Text>
            <Text style={styles.name}>{user?.name}</Text>
            <Text style={styles.label}>Company</Text>
            <Text style={styles.value}>{company?.name}</Text>
            <Text style={styles.label}>E-mail</Text>
            <Text style={styles.value}>{user?.email || '-'}</Text>
            <Text style={styles.label}>CPF</Text>
            <Text style={styles.value}>{user?.cpf || '-'}</Text>
            <Text style={styles.label}>Área</Text>
            <Text style={styles.value}>{user?.role?.name || '-'}</Text>
            <Text style={styles.label}>Notificação no WhatsApp</Text>
            <Text style={styles.value}>
                {whatsAppNotification ? 'Sim' : 'Não'}
            </Text>
            <Text style={styles.label}>Número do WhatsApp</Text>
            <Text style={styles.value}>
                {whatsAppNumber && whatsAppNumber.length ? whatsAppNumber : '-'}
            </Text>
            <Text style={styles.label}>Notificação por Email</Text>
            <Text style={styles.value}>
                {emailNotification ? 'Sim' : 'Não'}
            </Text>
            <Button
                primary
                style={{ marginTop: 12, marginBottom: 10 }}
                label="Editar"
                onPress={toggleEdit}
            />
        </>
    )
}

export default ProfileFormDetail
