import { StyleSheet } from 'react-native'
import colors from '../../../../../../../styles/colors'
import metrics from '../../../../../../../styles/metrics'

const iconRemoveSize = 40

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    content: {
        padding: metrics.padding,
    },
    inputTextArea: {
        height: 100,
    },
    line: {
        height: 1,
        backgroundColor: '#aaa',
    },
    moreDataButton: {
        backgroundColor: colors.accent,
        borderRadius: 4,
        paddingHorizontal: 16,
        paddingVertical: 4,
        flexDirection: 'row',
        justifyContent: 'center',
        marginHorizontal: 8,
    },
    moreDataText: {
        color: '#ffffff',
    },
    moreData: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    moreDataIcon: {
        width: 20,
        height: 20,
        tintColor: '#ffffff',
    },
    moreDataLine: {
        flexGrow: 1,
    },
    listItem: {
        flex: 1,
        flexShrink: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    iconRemove: {
        width: iconRemoveSize,
        height: iconRemoveSize,
        tintColor: 'red',
    },
    errorMessage: {
        color: 'red',
        fontSize: 14,
        marginTop: 10,
        marginBottom: 10,
    },
    noComments: {
        marginBottom: 14,
        color: colors.primary,
    },
    addCommentWarning: {
        marginBottom: 14,
        fontWeight: 'bold',
    },
    addCommentWarningAccent: {
        color: colors.accent,
    },
    comment: {
        marginBottom: 14,
    },
    commentUserName: {
        fontWeight: 'bold',
        fontSize: 18,
    },
    commentDescription: {
        marginTop: 8,
    },
    commentDocuments: {},
    commentDocumentsTitle: {
        marginTop: 8,
        fontWeight: 'bold',
    },
    commentDocument: {},
    activity: {
        marginBottom: 16,
    },
})

export default styles
