import React from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import Regime from '../../../../models/regime'
import colors from '../../../../styles/colors'
import { iconSizeNormal } from '../../../../styles/metrics'

export interface RegimeItemProps {
    regime: Regime
    onSelected: (regime: Regime) => void
    onRemove: (regime: Regime) => void
}

const RegimeItem = ({ regime, onSelected, onRemove }: RegimeItemProps) => {
    return (
        <TouchableOpacity
            style={styles.container}
            onPress={() => onSelected(regime)}
        >
            <View>
                <Text style={styles.name}>{regime.name}</Text>
            </View>
            <TouchableOpacity onPress={() => onRemove(regime)}>
                <Image
                    style={styles.iconRemove}
                    source={require('../../../../../assets/images/ic_remove.png')}
                />
            </TouchableOpacity>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingTop: 16,
        paddingBottom: 16,
        borderBottomColor: '#bbb',
        borderBottomWidth: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    name: {
        color: colors.secondary,
        fontSize: 18,
        fontWeight: 'bold',
    },
    recurrence: {
        color: colors.secondary,
        fontSize: 14,
        fontWeight: 'normal',
        marginTop: 8,
    },
    iconRemove: {
        width: iconSizeNormal,
        height: iconSizeNormal,
        tintColor: '#f55',
    },
})

export default RegimeItem
