import React from 'react'
import { GestureResponderEvent, Image, TouchableOpacity } from 'react-native'
import styles from './styles'

interface Props {
    icon: any
    onPress?: (event: GestureResponderEvent) => void
}

const FabButton: React.FC<Props> = ({ icon, onPress }) => {
    return (
        <TouchableOpacity style={styles.container} onPress={onPress}>
            <Image style={styles.icon} source={icon} />
        </TouchableOpacity>
    )
}

export default FabButton
