import Dropdown, { DropdownItem } from '../../../../../../components/Dropdown'
import React, { useCallback, useEffect, useState } from 'react'
import { ScrollView, Text, View } from 'react-native'

import Company from '../../../../../../models/company'
import ContentHeader from '../../../../../../components/ContentHeader'
import Pendency from '../../../../../../models/pendency'
import RequestFormEdit from './RequestFormEdit'
import RequestFormNew from './RequestFormNew'
import Spinner from '../../../../../../components/Spinner'
import { getCompanies } from '../../../../../../services/api'
import styles from './styles'

interface RequestFormCompanyDropDownProps {
    onCompanySelected: (company: Company) => void
}

export const RequestFormCompanyDropDown = ({
    onCompanySelected,
}: RequestFormCompanyDropDownProps) => {
    const [companies, setCompanies] = useState<Company[]>([])
    const [updating, setUpdating] = useState(false)
    const [selected, setSelected] = useState<Company | null>()

    const _getCompanies = useCallback(async () => {
        try {
            const result = await getCompanies(0)
            if (result && Array.isArray(result)) {
                const _companies: Company[] = []
                for (const _company of result) {
                    _companies.push(new Company(_company))
                }
                setCompanies(_companies)
            }
        } catch (err) {
            console.log(err)
        } finally {
            setUpdating(false)
        }
    }, [])

    useEffect(() => {
        if (!companies.length && !updating) {
            setUpdating(true)
            _getCompanies()
        }
    }, [_getCompanies, companies, updating])

    const onItemSelected = (item: DropdownItem) => {
        const company = companies.find(c => c.id === item.id)
        if (company) {
            setSelected(company)
            onCompanySelected(company)
        }
    }

    if (!companies || !companies.length) {
        return (
            <Text
                style={{
                    color: '#6677ff',
                    fontWeight: 'bold',
                    marginVertical: 8,
                }}
            >
                Carregando empresas...
            </Text>
        )
    }

    const items: DropdownItem[] = companies.map(
        c => new DropdownItem(c.id, c.name),
    )

    return (
        <Dropdown
            label="Empresa"
            selectedLabel={selected?.name}
            modalTitle="Escolha a empresa"
            items={items}
            placeholder="Escolha a empresa"
            onItemSelected={onItemSelected}
        />
    )
}

interface Props {
    request?: Pendency
    setRequestEditing: (request?: Pendency) => void
    onCancel: () => void
}

const RequestForm: React.FC<Props> = ({
    request,
    setRequestEditing,
    onCancel,
}) => {
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)

    return (
        <>
            <ScrollView style={styles.container}>
                <View style={styles.content}>
                    <ContentHeader
                        title={
                            request
                                ? `Solicitação número: ${request.id}`
                                : 'Solicitação'
                        }
                    />
                    {errorMessage.length > 0 ? (
                        <Text style={styles.errorMessage}>{errorMessage}</Text>
                    ) : null}
                    {request ? (
                        <RequestFormEdit
                            request={request}
                            onLoading={setLoading}
                            onError={setErrorMessage}
                            onCancel={onCancel}
                            setRequestEditing={setRequestEditing}
                        />
                    ) : (
                        <RequestFormNew
                            onLoading={setLoading}
                            onError={setErrorMessage}
                            onCancel={onCancel}
                        />
                    )}
                </View>
            </ScrollView>
            {loading ? (
                <Spinner
                    visible={loading}
                    overlayColor={'rgba(0, 0, 0, 0.5)'}
                />
            ) : null}
        </>
    )
}

export default RequestForm
