import AsyncStorage from '@react-native-async-storage/async-storage'
import React from 'react'
import { AlertContextProvider } from './src/contexts/alert-context'
import { AuthContextProvider } from './src/contexts/auth-context'
import { MenuLayoutContextProvider } from './src/contexts/menu-layout-context'
import { TimerContextProvider } from './src/contexts/timer-context'
import Navigation from './src/navigation'
import { LoggerTags } from './src/shared/consts'
;(async () => {
    await AsyncStorage.setItem(LoggerTags.formEditing, LoggerTags.formEditing)
})()

const App = () => {
    return (
        <AlertContextProvider>
            <TimerContextProvider>
                <AuthContextProvider>
                    <MenuLayoutContextProvider>
                        <Navigation />
                    </MenuLayoutContextProvider>
                </AuthContextProvider>
            </TimerContextProvider>
        </AlertContextProvider>
    )
}

export default App
