import React, { useEffect, useState } from 'react'
import {
    NativeScrollEvent,
    NativeSyntheticEvent,
    RefreshControl,
    ScrollView,
    StyleSheet,
    Text,
} from 'react-native'
import Button from '../../../../components/Button'
import ContentHeader from '../../../../components/ContentHeader'
import FabButton from '../../../../components/FabButton'
import Modal from '../../../../components/Modal'
import NoItems from '../../../../components/NoItems'
import Regime from '../../../../models/regime'
import { getRegimes, removeRegime } from '../../../../services/api'
import metrics from '../../../../styles/metrics'
import RegimeForm from './RegimeForm'
import RegimeItem from './RegimeItem'

export interface CompanyTypeFragmentProps {}

const RegimeFragment = () => {
    const [editing, setEditing] = useState(false)
    const [regimeEditing, setRegimeEditing] = useState<Regime | undefined>()
    const [regimes, setRegimes] = useState<Regime[]>([])
    const [loading, setLoading] = useState(false)
    const [refreshing, setRefreshing] = useState(false)
    const [regimeRemoving, setRegimeRemoving] = useState<Regime | undefined>(
        undefined,
    )
    const [filter, setFilter] = useState('')

    const _getRegimes = async () => {
        setLoading(true)
        getRegimes()
            .then(result => {
                if (result && Array.isArray(result)) {
                    const _regimes = [] // page === 0 ? [] : [...users]
                    for (const regime of result) {
                        if (
                            _regimes.find(i => i.id === regime.id) === undefined
                        ) {
                            _regimes.push(new Regime(regime))
                        }
                    }
                    setRegimes(_regimes)
                }
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        _getRegimes()
    }, [])

    const _onRefresh = () => {
        // setPage(0)
        setRefreshing(true)
        setRegimes([])
        _getRegimes()
    }

    const _closeEditing = () => {
        setRegimeEditing(undefined)
        setEditing(false)
        // setPage(0)
        setRegimes([])
        _getRegimes()
    }

    const _add = () => {
        setRegimeEditing(undefined)
        setEditing(true)
    }

    const _edit = (s: Regime) => {
        setRegimeEditing(s)
        setEditing(true)
    }

    const _fullRefresh = () => {
        setRegimeEditing(undefined)
        setEditing(false)
        setRegimes([])
        _getRegimes()
    }

    const _remove = (s: Regime) => {
        setRegimeRemoving(s)
    }

    const _doRemove = async () => {
        const id = regimeRemoving?.id || 0
        try {
            await removeRegime(id)
        } catch (e) {
            console.log('RegimeFragment _doRemove exception: ', e)
        }
        setRegimeRemoving(undefined)
        _fullRefresh()
    }

    const _isCloseToBottom = (
        event: NativeSyntheticEvent<NativeScrollEvent>,
    ) => {
        const { layoutMeasurement, contentOffset, contentSize } =
            event.nativeEvent

        const paddingToBottom = 20
        const close =
            layoutMeasurement.height + contentOffset.y >=
            contentSize.height - paddingToBottom
        if (close) {
            //     if (!updating && hasMoreItems) {
            //         dealsGet(false, false)
            //     }
        }
    }

    const _content = () => {
        return editing ? (
            <RegimeForm originalRegime={regimeEditing} onBack={_closeEditing} />
        ) : (
            <>
                {regimes.map(regime => {
                    if (
                        filter.trim().length === 0 ||
                        regime.name.toLowerCase().includes(filter.toLowerCase())
                    ) {
                        return (
                            <RegimeItem
                                key={regime.id}
                                regime={regime}
                                onSelected={s => _edit(s)}
                                onRemove={s => _remove(s)}
                            />
                        )
                    }
                })}
            </>
        )
    }

    return (
        <>
            <ScrollView
                style={styles.container}
                onScroll={_isCloseToBottom}
                scrollEventThrottle={400}
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={_onRefresh}
                    />
                }
            >
                <>
                    <ContentHeader
                        title="Regimes"
                        searchBar={!editing}
                        onSearch={term => {
                            setFilter(term)
                        }}
                    />
                    {_content()}
                </>
            </ScrollView>
            {regimes.length === 0 && !loading && !editing ? <NoItems /> : null}
            {!editing ? (
                <FabButton
                    icon={require('../../../../../assets/images/ic_plus.png')}
                    onPress={_add}
                />
            ) : null}
            <Modal isVisible={regimeRemoving !== undefined} title="Atenção">
                <Text style={styles.modalText}>
                    Tem certeza que deseja remover o item '
                    {regimeRemoving?.name}
                    '?
                </Text>
                <Button
                    primary
                    style={{ marginTop: 12, marginBottom: 10 }}
                    label="Sim"
                    onPress={_doRemove}
                />
                <Button
                    secondary
                    style={{ marginBottom: 10 }}
                    label="Cancelar"
                    onPress={() => setRegimeRemoving(undefined)}
                />
            </Modal>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: metrics.padding,
    },
    modalText: {
        fontSize: 16,
        marginTop: 8,
        marginBottom: 8,
    },
})

export default RegimeFragment
