import { StyleSheet } from 'react-native'
import colors from '../../styles/colors'
import { iconSizeNormal } from '../../styles/metrics'

const fabSize = 60

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        bottom: 230,
        right: 40,
        width: fabSize,
        height: fabSize,
        borderRadius: fabSize / 2,
        backgroundColor: colors.primary,
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        width: iconSizeNormal,
        height: iconSizeNormal,
        tintColor: '#fff',
    },
})

export default styles
