class ServiceType {
    public id: number
    public name: string

    constructor(json: any) {
        this.id = json.id
        this.name = json.name
    }
}

export default ServiceType
