import Constants from 'expo-constants'

const ENV = {
    dev: {
        // apiUrl: 'https://dalcon-backend-next-84rc839zf-bergmannsoft.vercel.app',
        // apiUrl: 'https://apicontrolsystem.dalcon.cnt.br',
        apiUrl: 'http://localhost:3000',
        filesUrl: 'https://filescontrolsystem.dalcon.cnt.br/',
        channelName: 'dev',
    },
    staging: {
        // apiUrl: 'https://dalcon-backend-next-84rc839zf-bergmannsoft.vercel.app',
        apiUrl: 'https://apicontrolsystem.dalcon.cnt.br',
        filesUrl: 'https://filescontrolsystem.dalcon.cnt.br/',
        channelName: 'staging',
    },
    prod: {
        // apiUrl: 'https://dalcon-backend-next-84rc839zf-bergmannsoft.vercel.app',
        apiUrl: 'https://apicontrolsystem.dalcon.cnt.br',
        filesUrl: 'https://filescontrolsystem.dalcon.cnt.br/',
        channelName: 'production',
    },
    store: {
        // apiUrl: 'https://dalcon-backend-next-84rc839zf-bergmannsoft.vercel.app',
        apiUrl: 'https://apicontrolsystem.dalcon.cnt.br',
        filesUrl: 'https://filescontrolsystem.dalcon.cnt.br/',
        channelName: '',
    },
}

const getEnvVars = (env = Constants.manifest?.releaseChannel) => {
    if (__DEV__) {
        return ENV.dev
    } else if (env === 'staging') {
        return ENV.staging
    } else if (env === 'prod') {
        return ENV.prod
    }
    return ENV.store
}

export default getEnvVars
