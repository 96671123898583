import React, { useState } from 'react'
import {
    Image,
    NativeScrollEvent,
    NativeSyntheticEvent,
    ScrollView,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'

import Button from '../../../../components/Button'
import CheckBox from '../../../../components/CheckBox'
import ContentHeader from '../../../../components/ContentHeader'
import Responsible from '../../../../models/responsible'
import { getResponsibles } from '../../../../services/api'
import { SystemRole } from '../../../../shared/consts'
import styles from './styles'

const ResponsiblesFragment: React.FC = () => {
    const [filter, setFilter] = useState('')
    const [searchCompany, setSearchCompany] = useState(true)

    const [users, setUsers] = useState<Responsible[]>([])

    const [loading, setLoading] = useState(false)

    const _getResponsibles = async () => {
        setLoading(true)
        try {
            if (!filter || !filter.trim().length) {
                return
            }
            const result = await getResponsibles(searchCompany, filter)
            if (result && Array.isArray(result.users)) {
                // const _users: User[] = []
                // for (const u of result) {
                //     _users.push(new User(u))
                // }
                setUsers(result.users)
            }
            // const _user = await Settings.getUser()
            // const result = await getRoles(_user?.companyId || 0)
            // if (result && Array.isArray(result)) {
            //     const _roles: Role[] = []
            //     for (const role of result) {
            //         if (_roles.find(i => i.id === role.id) === undefined) {
            //             _roles.push(new User(role))
            //         }
            //     }
            //     setRoles(_roles)
            // }
        } catch (e) {
            console.log('_getRoles exception: ', e)
        } finally {
            setLoading(false)
        }
    }

    const _isCloseToBottom = (
        event: NativeSyntheticEvent<NativeScrollEvent>,
    ) => {
        const { layoutMeasurement, contentOffset, contentSize } =
            event.nativeEvent

        const paddingToBottom = 20
        const close =
            layoutMeasurement.height + contentOffset.y >=
            contentSize.height - paddingToBottom
        if (close) {
            //     if (!updating && hasMoreItems) {
            //         dealsGet(false, false)
            //     }
        }
    }

    return (
        <>
            <ScrollView
                style={styles.container}
                onScroll={_isCloseToBottom}
                scrollEventThrottle={400}
            >
                <>
                    <ContentHeader
                        title="Responsáveis"
                        searchBar
                        onSearch={term => {
                            setFilter(term)
                        }}
                    />
                    <View style={styles.searchContainer}>
                        <View style={styles.searchCheckBox}>
                            <TouchableOpacity
                                onPress={() => {
                                    setSearchCompany(!searchCompany)
                                }}
                            >
                                <CheckBox checked={searchCompany} />
                            </TouchableOpacity>

                            <Text>Buscar pelo nome da empresa</Text>
                        </View>
                        <View style={styles.searchCheckBox}>
                            <TouchableOpacity
                                onPress={() => {
                                    setSearchCompany(!searchCompany)
                                }}
                            >
                                <CheckBox checked={searchCompany === false} />
                            </TouchableOpacity>
                            <Text>Buscar pelo nome do funcionário</Text>
                        </View>
                        <Button
                            primary
                            style={{
                                marginTop: 12,
                                marginBottom: 10,
                                alignSelf: 'left',
                            }}
                            label="Buscar"
                            onPress={_getResponsibles}
                        />
                    </View>
                    {!loading && !users.length && (
                        <Text style={{ marginTop: 22, textAlign: 'center' }}>
                            Sem itens para exibir. Faça ou refaça a busca.
                        </Text>
                    )}
                    {users.map(responsible => {
                        return responsible.companies.map(company => {
                            return (
                                <View
                                    key={`responsible-${company.id}`}
                                    style={{
                                        flexDirection: 'column',
                                        width: '100%',
                                    }}
                                >
                                    <View
                                        style={{
                                            flexDirection: 'row',
                                            width: '100%',
                                        }}
                                    >
                                        <View
                                            style={{
                                                flexDirection: 'column',
                                                width: '100%',
                                            }}
                                        >
                                            <Text style={[styles.name]}>
                                                {company.name}
                                            </Text>
                                            <Text style={[styles.cnpj]}>
                                                {company.cnpj || ''}
                                            </Text>
                                            <View>
                                                <Text style={styles.name}>
                                                    {responsible.user.name ||
                                                        '-'}
                                                </Text>
                                            </View>
                                        </View>
                                        {responsible.user.systemRole ===
                                            SystemRole.superadmin ||
                                        responsible.user.systemRole ===
                                            SystemRole.admin ||
                                        responsible.user.systemRole ===
                                            SystemRole.dalconEmployeeSuperAdmin ? (
                                            <Image
                                                style={[
                                                    styles.iconWarning,
                                                    responsible.user
                                                        .systemRole ===
                                                    SystemRole.dalconEmployeeSuperAdmin
                                                        ? styles.dalconEmployeeSuperAdmin
                                                        : null,
                                                ]}
                                                source={require('../../../../../assets/images/ic_admin.png')}
                                            />
                                        ) : null}
                                    </View>
                                    <View
                                        style={{
                                            width: '100%',
                                            height: 1,
                                            backgroundColor: '#121212',
                                            marginVertical: 16,
                                        }}
                                    />
                                </View>
                            )
                        })
                    })}
                </>
            </ScrollView>

            {/* <Modal isVisible={roleRemoving !== undefined} title="Atenção">
                <Text style={styles.modalText}>
                    Tem certeza que deseja remover o item '{roleRemoving?.name}
                    '?
                </Text>
                <Button
                    primary
                    style={{ marginTop: 12, marginBottom: 10 }}
                    label="Sim"
                    onPress={_doRemove}
                />
                <Button
                    secondary
                    style={{ marginBottom: 10 }}
                    label="Cancelar"
                    onPress={() => setRoleRemoving(undefined)}
                />
            </Modal> */}
        </>
    )
}

export default ResponsiblesFragment
