import PorteEmpresa from './porte-empresa'
import TipoSociedade from './tipo-sociedade'

class DocFormularioConstituicao {
    id: number = 0
    companyId: number = 0
    tipoSociedadeId: number = 0
    porteEmpresaId: number = 0
    documentId: number = 0
    tipoSociedade?: TipoSociedade
    porteEmpresa?: PorteEmpresa
    nomeFantasia: string = ''
    nomeEmpresarial1: string = ''
    nomeEmpresarial2: string = ''
    nomeEmpresarial3: string = ''
    sociosAssinam: string = ''
    capitalSocial: string = ''
    socioAdministrador: string = ''
    // nomeSocio1: string = ''
    // nomeSocio2: string = ''
    // nomeSocio3: string = ''
    // atividadePretendida1: string = ''
    // atividadePretendida2: string = ''
    // atividadePretendida3: string = ''
    // atividadePretendida4: string = ''
    socios: any = []
    situacaoSocios: any = {}
    telefonesEmpresa: any = []

    atividadesPretendidas: any = []
    // menorDe18Anos: boolean = false
    // participaComoSocioEmOutraEmpresa: boolean = false
    // extrangeiro: boolean = false
    // inscricaoEstadualCancelada: boolean = false
    // debitosEstaduais: boolean = false
    // aposentadoPorInvalidez: boolean = false

    // telefoneEmpresa1: string = ''
    // telefoneEmpresa2: string = ''
    // telefoneEmpresa3: string = ''
    valorHonorarios: string = ''
    valorConstituicao: string = ''
    quantidadeFuncionarios: string = ''
}

export default DocFormularioConstituicao
