import React, { useContext, useEffect, useState } from 'react'
import {
    NativeScrollEvent,
    NativeSyntheticEvent,
    RefreshControl,
    ScrollView,
    View,
} from 'react-native'
import {
    FilterType,
    FilterTypeDropdownItems,
} from '../../../../../shared/consts'

import Button from '../../../../../components/Button'
import ContentHeader from '../../../../../components/ContentHeader'
import Dropdown from '../../../../../components/Dropdown'
import FabButton from '../../../../../components/FabButton'
import IconButton from '../../../../../components/IconButton'
import Modal from '../../../../../components/Modal'
import NoItems from '../../../../../components/NoItems'
import PendencyItem from '../../../../../components/PendencyItem'
import Spinner from '../../../../../components/Spinner'
import { PendencyContext } from '../../../../../contexts/pendency-context'
import Pendency from '../../../../../models/pendency'
import User from '../../../../../models/user'
import Settings from '../../../../../services/settings'
import { datetimeToString } from '../../../../../shared/date'
import RequestForm from './RequestForm'
import styles from './styles'

const RequestList: React.FC = () => {
    const [companyMasterId, setCompanyMasterId] = useState<number | null>(null)
    const [user, setUser] = useState<User | null>(null)

    const [editing, setEditing] = useState(false)
    const [page, setPage] = useState(0)
    const [requestEditing, setRequestEditing] = useState<Pendency | undefined>(
        undefined,
    )

    const { pendencies, loading, refreshing, setRefreshing, fetchPendencies } =
        useContext(PendencyContext)
    // const [refreshing, setRefreshing] = useState(loading)

    const [showFilterModal, setShowFilterModal] = useState(false)
    const [filterName, setFilterName] = useState('')
    const [filterId, setFilterId] = useState(0)

    const [searchTerm, setSearchTerm] = useState<any>('')

    // const [waitPage, setWaitPage] = useState(false)

    console.log('PendencyContext loading', loading, 'refreshing', refreshing)

    useEffect(() => {
        ;(async () => {
            const id = await Settings.getCompanyMasterId()
            setCompanyMasterId(id)
            const u = await Settings.getUser()
            setUser(u)
            const f = await Settings.getFilter()
            setFilterId(f)
        })()
    }, [])

    const _isCloseToBottom = (
        event: NativeSyntheticEvent<NativeScrollEvent>,
    ) => {
        const { layoutMeasurement, contentOffset, contentSize } =
            event.nativeEvent

        const paddingToBottom = 20
        const close =
            layoutMeasurement.height + contentOffset.y >=
            contentSize.height - paddingToBottom
        if (close) {
            if (loading || refreshing) {
                return
            }
            const nextPage = page + 1
            setPage(nextPage)
            fetchPendencies(nextPage)
            //     if (!updating && hasMoreItems) {
            //         dealsGet(false, false)
            //     }
        }
    }

    const _fetchPendencies = () => {
        fetchPendencies(page)
    }

    const _onRefresh = () => {
        setRefreshing(true)
        setPage(0)
        _fetchPendencies()
    }

    const _add = () => {
        setEditing(true)
    }

    const _edit = (request: Pendency) => {
        setRequestEditing(request)
        setEditing(true)
    }

    const _cancelForm = () => {
        setRequestEditing(undefined)
        setEditing(false)
        _fetchPendencies()
    }

    const _setFilter = (id: number, name: string) => {
        setFilterId(id)
        setFilterName(name)
        Settings.setFilter(id)
    }

    const _applyFilter = () => {
        setShowFilterModal(false)
    }

    if (!companyMasterId) {
        return null
    }

    const filterDropdownItems = FilterTypeDropdownItems

    let _pendencies = pendencies
    if (filterId > 0) {
        _pendencies = pendencies.filter(pendency => {
            switch (filterId) {
                case FilterType.notStarted.id:
                    return (
                        pendency.dateStarted === undefined ||
                        pendency.dateStarted === null
                    )
                case FilterType.started.id:
                    return (
                        pendency.dateStarted &&
                        (pendency.dateCompleted === undefined ||
                            pendency.dateCompleted === null)
                    )
                case FilterType.finished.id:
                    return (
                        pendency.dateCompleted !== undefined &&
                        pendency.dateCompleted !== null
                    )
            }
        })
    }
    if (searchTerm.length > 0) {
        _pendencies = _pendencies.filter(pendency => {
            const _term = searchTerm.toLowerCase()
            if (
                pendency.role &&
                pendency.role.name.toLowerCase().indexOf(_term) >= 0
            ) {
                return true
            } else if (
                pendency.description &&
                pendency.description.toLowerCase().indexOf(_term) >= 0
            ) {
                return true
            } else if (
                pendency.userCreator &&
                pendency.userCreator.name.toLowerCase().indexOf(_term) >= 0
            ) {
                return true
            } else if (
                pendency.userExecutor &&
                pendency.userExecutor.name.toLowerCase().indexOf(_term) >= 0
            ) {
                return true
            } else if (
                datetimeToString(pendency.dateCreate).indexOf(_term) >= 0
            ) {
                return true
            } else if (pendency.id.toString().indexOf(_term) >= 0) {
                return true
            } else if (
                pendency.company &&
                pendency.company.name.toLowerCase().indexOf(_term) >= 0
            ) {
                return true
            } else {
                return false
            }
        })
    }

    return editing ? (
        <RequestForm
            request={requestEditing}
            setRequestEditing={setRequestEditing}
            onCancel={_cancelForm}
        />
    ) : (
        <>
            <ScrollView
                onScroll={_isCloseToBottom}
                scrollEventThrottle={400}
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={_onRefresh}
                    />
                }
            >
                <View style={styles.container}>
                    <View style={styles.header}>
                        <ContentHeader
                            title="Minhas solicitações"
                            searchBar
                            onSearch={term => setSearchTerm(term)}
                        />
                        {/* RELOAD */}
                        <IconButton
                            styles={styles.headerReloadButton}
                            source={require('../../../../../../assets/images/ic_reload.png')}
                            onPress={_onRefresh}
                        />
                        {/* FILTER */}
                        <IconButton
                            styles={styles.headerFilterButton}
                            source={require('../../../../../../assets/images/ic_filter.png')}
                            showBadge={filterId > 0}
                            onPress={() => setShowFilterModal(true)}
                        />
                    </View>

                    {_pendencies.map((pendency, i) => (
                        <PendencyItem
                            key={`${pendency.id}_pendency_${i}`}
                            pendency={pendency}
                            isCompanyMaster={
                                companyMasterId === user?.companyId
                            }
                            editPendency={p => _edit(p)}
                        />
                    ))}
                </View>
            </ScrollView>
            {pendencies.length === 0 && !loading ? <NoItems /> : null}
            <FabButton
                icon={require('../../../../../../assets/images/ic_plus.png')}
                onPress={_add}
            />
            <Modal isVisible={showFilterModal} title="Filtrar">
                <Dropdown
                    label="Filtrar por"
                    selectedLabel={filterName}
                    modalTitle="Escolha o filtro"
                    items={filterDropdownItems}
                    placeholder="Escolha o filtro"
                    onItemSelected={item => _setFilter(item.id, item.label)}
                />
                <Button
                    primary
                    style={{ marginTop: 12, marginBottom: 10 }}
                    label="OK"
                    onPress={_applyFilter}
                />
                <Button
                    secondary
                    style={{ marginBottom: 10 }}
                    label="Cancelar"
                    onPress={() => setShowFilterModal(false)}
                />
            </Modal>
            <Spinner visible={refreshing} overlayColor={'rgba(0, 0, 0, 0.5)'} />
        </>
    )
}

export default RequestList
