import React, { forwardRef, ForwardRefRenderFunction } from 'react'
import {
    Image,
    KeyboardTypeOptions,
    NativeSyntheticEvent,
    ReturnKeyTypeOptions,
    Text,
    TextInputFocusEventData,
    TextInputSubmitEditingEventData,
    View,
} from 'react-native'
import { TextInputMask, TextInputMaskTypeProp } from 'react-native-masked-text'
import styles from './styles'

interface Props {
    icon?: any
    label?: string
    placeholder?: string
    keyboardType?: KeyboardTypeOptions
    returnKeyType?: ReturnKeyTypeOptions
    autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters'
    secureTextEntry?: boolean
    nextRef?: any
    onSubmitEditing?: (
        e: NativeSyntheticEvent<TextInputSubmitEditingEventData>,
    ) => void
    onChangeText?: (text: string) => void
    onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void
    maskType?: TextInputMaskTypeProp
    style?: any
    inputStyle?: any
    multiline?: boolean
    numberOfLines?: number
    textAlignVertical?: 'auto' | 'top' | 'bottom' | 'center'
    value?: string
    editable?: boolean
    help?: string
}

const Input: ForwardRefRenderFunction<any, Props> = (
    {
        icon,
        label,
        placeholder,
        keyboardType,
        returnKeyType,
        autoCapitalize,
        secureTextEntry,
        nextRef,
        onSubmitEditing,
        onChangeText,
        onBlur,
        maskType,
        style,
        inputStyle,
        multiline,
        numberOfLines,
        textAlignVertical,
        value,
        editable,
        help,
    },
    ref,
) => {
    const type = maskType ? maskType : 'custom'
    let options = maskType
        ? undefined
        : {
              mask: '************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************',
          }
    if (maskType === 'datetime') {
        // @ts-ignore
        options = { format: 'DD/MM/YYYY' }
    }

    return (
        <View style={[styles.container, style]}>
            {icon ? <Image style={styles.icon} source={icon} /> : null}
            <View style={styles.inputContainer}>
                {label ? <Text style={styles.inputLabel}>{label}</Text> : null}
                <TextInputMask
                    ref={ref}
                    onSubmitEditing={e => {
                        if (nextRef) {
                            nextRef.current._inputElement.focus()
                        } else {
                            if (onSubmitEditing) {
                                onSubmitEditing(e)
                            }
                        }
                    }}
                    multiline={multiline}
                    numberOfLines={numberOfLines}
                    placeholder={placeholder}
                    editable={editable}
                    type={type}
                    options={options}
                    textAlignVertical={textAlignVertical}
                    keyboardType={keyboardType}
                    returnKeyType={returnKeyType}
                    autoCapitalize={autoCapitalize}
                    secureTextEntry={secureTextEntry}
                    textContentType="oneTimeCode"
                    style={[
                        styles.input,
                        editable === false ? styles.disabled : null,
                        icon ? { marginLeft: 8 } : {},
                        inputStyle,
                    ]}
                    onBlur={onBlur}
                    onChangeText={onChangeText}
                    value={value}
                />
                {help ? <Text style={styles.inputHelp}>{help}</Text> : null}
            </View>
        </View>
    )
}

export default forwardRef(Input)
