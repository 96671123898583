import {
    NativeScrollEvent,
    NativeSyntheticEvent,
    RefreshControl,
    ScrollView,
    Text,
} from 'react-native'
import React, { useEffect, useState } from 'react'
import { getRoles, removeRole } from '../../../../services/api'

import Button from '../../../../components/Button'
import ContentHeader from '../../../../components/ContentHeader'
import FabButton from '../../../../components/FabButton'
import Modal from '../../../../components/Modal'
import NoItems from '../../../../components/NoItems'
import Role from '../../../../models/role'
import RoleForm from './RoleForm'
import RoleItem from './RoleItem'
import Settings from '../../../../services/settings'
import styles from './styles'

const RolesFragment: React.FC = () => {
    const [filter, setFilter] = useState('')

    const [roles, setRoles] = useState<Role[]>([])

    const [roleEditing, setRoleEditing] = useState<Role | undefined>(undefined)
    const [roleRemoving, setRoleRemoving] = useState<Role | undefined>(
        undefined,
    )

    const [refreshing, setRefreshing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [editing, setEditing] = useState(false)

    const _getRoles = async () => {
        setLoading(true)
        try {
            const _user = await Settings.getUser()
            const result = await getRoles(_user?.companyId || 0)
            if (result && Array.isArray(result)) {
                const _roles: Role[] = []
                for (const role of result) {
                    if (_roles.find(i => i.id === role.id) === undefined) {
                        _roles.push(new Role(role))
                    }
                }
                setRoles(_roles)
            }
        } catch (e) {
            console.log('_getRoles exception: ', e)
        } finally {
            setLoading(false)
        }
        setRefreshing(false)
    }

    const _fullRefresh = () => {
        setRoleEditing(undefined)
        setEditing(false)
        setRoles([])
        _getRoles()
    }

    useEffect(() => {
        _getRoles()
    }, [])

    const _isCloseToBottom = (
        event: NativeSyntheticEvent<NativeScrollEvent>,
    ) => {
        const { layoutMeasurement, contentOffset, contentSize } =
            event.nativeEvent

        const paddingToBottom = 20
        const close =
            layoutMeasurement.height + contentOffset.y >=
            contentSize.height - paddingToBottom
        if (close) {
            //     if (!updating && hasMoreItems) {
            //         dealsGet(false, false)
            //     }
        }
    }

    const _onRefresh = () => {
        setRefreshing(true)
        _fullRefresh()
    }

    const _add = () => {
        setRoleEditing(undefined)
        setEditing(true)
    }

    const _edit = (r: Role) => {
        setRoleEditing(r)
        setEditing(true)
    }

    const _remove = (r: Role) => {
        setRoleRemoving(r)
    }

    const _doRemove = async () => {
        const id = roleRemoving?.id || 0
        try {
            await removeRole(id)
        } catch (e) {
            console.log('RolesFragment _doRemove exception: ', e)
        }
        setRoleRemoving(undefined)
        _fullRefresh()
    }

    const _closeEditing = () => {
        _fullRefresh()
    }

    return editing ? (
        <RoleForm originalRole={roleEditing} onBack={_closeEditing} />
    ) : (
        <>
            <ScrollView
                style={styles.container}
                onScroll={_isCloseToBottom}
                scrollEventThrottle={400}
                refreshControl={
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={_onRefresh}
                    />
                }
            >
                <>
                    <ContentHeader
                        title="Áreas de Atuação"
                        searchBar
                        onSearch={term => {
                            setFilter(term)
                        }}
                    />
                    {roles.map(role => {
                        if (
                            filter.trim().length === 0 ||
                            role.name
                                .toLowerCase()
                                .includes(filter.toLowerCase())
                        ) {
                            return (
                                <RoleItem
                                    key={role.id}
                                    role={role}
                                    onSelected={_edit}
                                    onRemove={_remove}
                                />
                            )
                        }
                        return null
                    })}
                </>
            </ScrollView>
            {roles.length === 0 && !loading ? <NoItems /> : null}
            <FabButton
                icon={require('../../../../../assets/images/ic_plus.png')}
                onPress={_add}
            />
            <Modal isVisible={roleRemoving !== undefined} title="Atenção">
                <Text style={styles.modalText}>
                    Tem certeza que deseja remover o item '{roleRemoving?.name}
                    '?
                </Text>
                <Button
                    primary
                    style={{ marginTop: 12, marginBottom: 10 }}
                    label="Sim"
                    onPress={_doRemove}
                />
                <Button
                    secondary
                    style={{ marginBottom: 10 }}
                    label="Cancelar"
                    onPress={() => setRoleRemoving(undefined)}
                />
            </Modal>
        </>
    )
}

export default RolesFragment
