import React, { createContext, ReactNode, useEffect, useState } from 'react'
import User from '../models/user'
import Settings from '../services/settings'

interface AuthContextData {
    token: string | null
    checkingToken: boolean
    onSignInSuccess: (result: any) => void
    logout: () => void
}

interface AuthProviderProps {
    children: ReactNode
}

export const AuthContext = createContext({} as AuthContextData)

export const AuthContextProvider = ({ children }: AuthProviderProps) => {
    const [checkingToken, setCheckingToken] = useState(true)
    const [token, setToken] = useState<string | null>(null)

    const onSignInSuccess = async (response: any) => {
        await Settings.setToken(response.token)
        await Settings.setCompanyMasterId(response.companyMasterId)
        await Settings.setUser(new User(response.user))
        setToken(response.token)
    }

    const logout = async () => {
        await Settings.clear()
        setToken(null)
    }

    useEffect(() => {
        ;(async () => {
            try {
                const _token = await Settings.getToken()
                setToken(_token)
            } catch (e) {
                console.error('getToken error: ', e)
            } finally {
                setCheckingToken(false)
            }
        })()
    }, [])

    const authContext = {
        token,
        checkingToken,
        onSignInSuccess,
        logout,
    }

    return (
        <AuthContext.Provider value={authContext}>
            {children}
        </AuthContext.Provider>
    )
}
