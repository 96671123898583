import { StyleSheet } from 'react-native'
import metrics from '../../../../styles/metrics'

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: metrics.padding,
    },
    modalText: {
        fontSize: 16,
        marginTop: 8,
        marginBottom: 8,
    },
})

export default styles
